import React from 'react'

import classnames from 'classnames'

export default ({ onDrop, newWellfieldLogs, canceled, fileName }) => {
  let errorsCount = 0, warningsCount = 0, done = false;
  newWellfieldLogs.forEach(({ finished, has_errors }) => {
    if (finished && has_errors) {
      errorsCount += 1
      done = finished
    }
    if (has_errors) {
      warningsCount += 1
    }
  })
  return <div className="dropzone dropzone__results">
    <div className="dropzone__container">
      <span className={classnames('dropzone__container-icon', {
        error: errorsCount && warningsCount && !canceled,
        warnings: !errorsCount && warningsCount && !canceled,
        success: !errorsCount && !warningsCount && done && !canceled,
        canceled: canceled
      })}></span>
      <span className='dropzone__logs-container'>
        {
          canceled ?
          <p className="dropzone__logs-result">
            Загрузка и проверка файла &laquo;{fileName}&raquo; прервана.<br/>
            Чтобы продолжить создание &mdash; замените файл.
          </p>:

        !!warningsCount &&
        <p className="dropzone__logs-result">
          Обнаружено {!!errorsCount &&
            <span><span className='dropzone__logs-result-error'>{errorsCount}</span> ошибки и</span>
          } {
            <span><span className='dropzone__logs-result-warnings'>{warningsCount}</span> предупреждений</span>
            }
        </p>
      }
      {
        !!errorsCount &&
        <p className="dropzone__logs-result">Месторождение не может быть создано.</p>
      }
      </span>
      {
        !errorsCount && !warningsCount && done &&
        <p className="dropzone__logs-result">Все файлы успешно проверены и загружены.</p>
      }
      {
        <label
          htmlFor="2xs"
          className='primary-button dropzone__logs-button'>
          <input
            id='2xs'
            type="file"
            onChange={e => onDrop(e.target.files)} />
          Заменить файл
        </label>
      }
    </div>
  </div>
}