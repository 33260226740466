import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {sourcesIds} from "core/state/selectors/settings";

export default () => {
    const sources = useSelector(sourcesIds);
    useEffect(() => {
        if (sources) {
            document.querySelectorAll('.facility.second-selected').forEach(el => {
                el.classList.remove('second-selected');
            });
            sources.forEach(id => {
                document.getElementsByClassName(`${id} facility`)[0].classList.add('second-selected');
            });
        } else {
            document.querySelectorAll('.facility.second-selected').forEach(el => {
                el.classList.remove('second-selected');
            });
        }
    }, [sources]);
};
