import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import './TopMenu.scss';
import ReactTooltip from 'react-tooltip';

import MenuWrapper from './MenuWrapper';
import UserContainer from './UserContainer';
import ButtonsFactory from './ButtonsFactory';


class TopMenuContainer extends Component {
    state = {
        buttonsType: '',
        menu: ''
    };
    showMenuHandler = menu => {
        if (menu === 'ruler') {
            this.props.clickType('SUCCESS', menu)
        } else {
            const value = this.state.menu === menu ? '' : menu;
            if (value) {
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }
            this.setState({
                menu: value
            })
        }
    };
    handleClickOutside = event => {
        if (this.topMenuRef && !this.topMenuRef.contains(event.target)) {
            this.setState({menu: false})
            ;[].map.call(document.getElementsByClassName('topMenu__item'), el => el.classList.remove('active'))
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    };
    handleClick = (type, elem) => {
        const {
            activeTab,
            clickType,
            getActiveTab,
            getClickType,
            setTaskSettings,
            changeTaskSettings,
            removeHiddenMetaclass,
            getClassesByMetaclass,
            metaclassesByTypeWithPalette
        } = this.props;
        activeTab('SUCCESS', type);
        if (type === 'createFacility') {
            const initialValue = {
                class_id: elem.id,
                palette: elem.palette,
            };
            clickType('SUCCESS', [type]);
            if (getActiveTab !== type) {
                setTaskSettings('SUCCESS', initialValue);
            } else {
                changeTaskSettings('SUCCESS', initialValue);
            }
            getClassesByMetaclass('SUCCESS', metaclassesByTypeWithPalette.facility);
            removeHiddenMetaclass('REQUEST', {type, id: elem.id});
        } else if (['createLinearObject', 'createArea'].includes(type)) {
            clickType('SUCCESS', [type]);
            const initialValue = {
                metaclass_id: elem.id,
                palette: elem.palette
            };
            if (getActiveTab !== type) {
                setTaskSettings('SUCCESS', initialValue);
            } else {
                changeTaskSettings('SUCCESS', initialValue);
            }
            removeHiddenMetaclass('REQUEST', {type, id: elem.id});
            this.props.setTransformSettings('SUCCESS', 'draw')
        } else if (getClickType !== type) {
            if (type === 'quarries') {
                clickType('SUCCESS', type)
            }
            if (type === 'hvl') {
                clickType('SUCCESS', type)
            }
            if (type === 'linearPipes') {
                clickType('SUCCESS', `${type}.${elem}`);
                changeTaskSettings('SUCCESS', {type: elem});
            }
            if (type === 'facility') {
                clickType('SUCCESS', type)
            }
        }
        this.setState({
            menu: false
        })
    };

    componentDidMount() {
        this.handleButtonsType(this.props.location.pathname);
        ReactTooltip.rebuild()
    }

    handleButtonsType = pathname => {
        if (pathname.includes('app') && !pathname.includes('new')) {
            this.setState({
                buttonsType: 'project'
            })
        } else if (!pathname.includes('all') && !pathname.includes('new') && (pathname.includes('projects') || pathname.includes('wellfields'))) {
            this.setState({
                buttonsType: 'startPageButtons',
                menu: false
            })
        } else if (pathname.includes('all')) {
            this.setState({
                buttonsType: 'user',
                menu: false
            })
        } else {
            this.setState({
                buttonsType: ''
            })
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname) {
            this.handleButtonsType(this.props.location.pathname);
        }
        if (Object.keys(prevProps.metaclassesByTypeWithPalette).length !== Object.keys(this.props.metaclassesByTypeWithPalette).length) {
            if (this.props.metaclassesByTypeWithPalette.linear) {
                for (let item of this.props.metaclassesByTypeWithPalette.linear) {
                    if (item.name.toLowerCase() === 'автодорога') {
                        this.setState({
                            roadMetaclass: item.id
                        });
                        break;
                    }
                }
            }
        }
    }

    render() {
        const {menu, buttonsType} = this.state;
        const {additionalTaskProps, avaliableTaskTypes, metaclassesByTypeWithPalette} = this.props;
        const pipesType = additionalTaskProps ? additionalTaskProps.pipes : [];
        return <div className="topMenu" ref={node => this.topMenuRef = node}>
            <ButtonsFactory type={buttonsType} showMenuHandler={this.showMenuHandler}/>
            {
                menu &&
                <MenuWrapper
                    type={menu}
                    pipesType={pipesType}
                    handleClick={this.handleClick}
                    metaclassesByType={metaclassesByTypeWithPalette}
                    avaliableTaskTypes={avaliableTaskTypes}/>
            }
            {buttonsType && <UserContainer setShowModal={this.props.setShowModal}/>}
        </div>
    }
}

TopMenuContainer.propTypes = {
    getClassesByMetaclass: PropTypes.func.isRequired,
    changeTaskSettings: PropTypes.func.isRequired,
    setTaskSettings: PropTypes.func.isRequired,
    clickType: PropTypes.func.isRequired
};

export default withRouter(TopMenuContainer);
