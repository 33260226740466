import React, { useEffect, useState } from 'react';
import titleConfig from './utils/titleConfig';

const TopRow = React.memo(({ type }) => {
  const [title, setTitle] = useState('');
  useEffect(() => {
    setTitle(titleConfig[type]);
  }, [type]);
  return (
    <div className='main-container__top-row'>
      <h2 className='main-title'>{title}</h2>
    </div>
  );
});

export default TopRow;
