import React, {Component} from 'react'
import classnames from 'classnames'

class HideNetwork extends Component {
    state = {hide: false}
    hideNetwork = id => {
        const elems = document.getElementsByClassName(`network-${id}`)
        Array.from(elems).forEach(elem => {
            elem.classList.toggle('hide')
        })
        this.setState({
            hide: !this.state.hide
        })
    }

    render() {
        const {hide} = this.state
        return (
            <div onClick={() => this.hideNetwork(this.props.network_id)}
                 className={classnames('field-row__showRow-icon', {hide})}></div>
        )
    }
}

export default HideNetwork;
