import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {facilities, getActiveTab} from 'core/state/selectors/settings';
import {getActiveTask} from 'core/state/selectors/projects';
import _get from "@babel/runtime/helpers/esm/get";

export default () => {
    const facilitiesArr = useSelector(facilities);
    const activeTab = useSelector(getActiveTab);
    const activeTask = useSelector(getActiveTask);
    useEffect(() => {
        if (facilitiesArr) {
            document.querySelectorAll('.first-selected').forEach(el => {
                el.classList.remove('first-selected');
            });
            facilitiesArr.forEach(id => {
                document.getElementsByClassName(`${id} facility`)[0].classList.add('first-selected');
            });
        } else {
            document.querySelectorAll('.first-selected').forEach(el => {
                el.classList.remove('first-selected');
            });
        }
    }, [facilitiesArr]);

    useEffect(() => {
        if (activeTab === 'taskInfo' && activeTask) {
            if (_get(activeTask, [0, 'props', 'facilities'])) {
                activeTask[0].props.facilities.forEach(id => {
                    document.getElementsByClassName(id)[0].classList.add('first-selected');
                });
            }
        } else {
            document.querySelectorAll('.first-selected').forEach(el => {
                el.classList.remove('first-selected');
            });
        }
    }, [activeTab, activeTask]);
}
