import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sinksIds } from 'core/state/selectors/settings';

export default () => {
  const sinks = useSelector(sinksIds);
  useEffect(() => {
    if (sinks) {
      document.querySelectorAll('.first-selected').forEach(el => {
        el.classList.remove('first-selected');
      });
      sinks.forEach(id => {
        document
          .getElementsByClassName(`${id} facility`)[0]
          .classList.add('first-selected');
      });
    } else {
      document.querySelectorAll('.first-selected').forEach(el => {
        el.classList.remove('first-selected');
      });
    }
  }, [sinks]);
};
