import React from "react"
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ToggleTitle = ({
  title,
  itemKey,
  showList,
  afterComp,
  beforeComp,
  titleClass,
  beforeProp,
  onMouseEnter,
  onMouseLeave,
  afterCompFunc,
  containerClass,
  showListHandler }) => {
  const onMouseEnterHandler = () => {
    if (containerClass && containerClass.includes('network')) {
      onMouseEnter({ id: itemKey, type: 'network' })
    }
  }
  const onMouseLeaveHandler = () => {
    if (containerClass && containerClass.includes('network')) {
      onMouseLeave({ id: itemKey, type: 'network' })
    }
  }
  return <div
    className={classnames('main-row-toggle', { hide: !showList }, containerClass)}>
    {beforeComp && beforeComp(beforeProp)}
    <div
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      className={classnames('toggle-title main-toggle-title', titleClass)} onClick={showListHandler}>
      <span className="arrow"></span>
      <span className="main-row-toggle__text">{title}</span>
    </div>
    {afterComp && afterComp(afterCompFunc)}
  </div>;
}

ToggleTitle.propTypes = {
  title: PropTypes.string.isRequired,
  itemKey: PropTypes.string,
  showList: PropTypes.bool.isRequired,
  afterComp: PropTypes.func,
  beforeComp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  afterCompFunc: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.array
  ]),
  containerClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  showListHandler: PropTypes.func.isRequired,

}

export default ToggleTitle;