import {
  MOVE_TO_MAP_CENTER_REQUEST,
  SET_MAP_TYPE_SUCCESS,
  SET_CENTER_SUCCESS,
  SET_ZOOM_SUCCESS,
  SET_VIEW_SUCCESS
} from '../constants/map';

import {
  CLEAR_STATE_SUCCESS
} from '../constants/projects';

const initialState = {
  mapType: 'Google',
  zoom: 12
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MOVE_TO_MAP_CENTER_REQUEST: {
      return {
        ...state,
        zoom: 12,
        setView: {
          ...state.mapCenter
        }
      }
    }
    case CLEAR_STATE_SUCCESS:
      const stateValue = Object.assign({}, state);
      delete stateValue.mapCenter;
      return stateValue;
    case SET_VIEW_SUCCESS:
      return {
        ...state,
        setView: action.payload
      };
    case SET_CENTER_SUCCESS:
      return {
        ...state,
        mapCenter: action.payload
      };
    case SET_MAP_TYPE_SUCCESS:
      return {
        ...state,
        mapType: action.payload
      };
    case SET_ZOOM_SUCCESS:
      const value = action.payload >= 18 ? 18 : action.payload <= 1 ? 1 : action.payload;
      return {
        ...state,
        zoom: value
      };
    default:
      return state
  }
}