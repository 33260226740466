export default type => {
  switch (type) {
    case 'high_voltage':
      return 'Расчет сети ВЛ';
    case 'roads':
      return 'Расчет сети автодорог';
    case 'quarries':
      return 'Расчет оптимального освоения карьеров';
    case 'facility_placement':
      return 'Оптимальное расположение ПО';
    case 'linear_cost':
      return 'Расчет стоимости ЛО';
    case 'oil_pipe':
      return 'Расчет сети трубопроводов ННП';
    case 'oil_gathering_line':
      return 'Расчет сети трубопроводов НСК';
    case 'high_pressure_water_pipe':
      return 'Расчет сети трубопроводов ВВД';
    case 'low_pressure_water_pipe':
      return 'Расчет сети трубопроводов ВНД';
    case 'high_pressure_gas_pipe':
      return 'Расчет сети трубопроводов ГВД';
    case 'low_pressure_gas_pipe':
      return 'Расчет сети трубопроводов ГНД';
    case 'gathering_centers':
      return 'Расчет центров сбора';
    default:
      return 'Задачи без группы';
  }
}
