import React, { Component } from 'react';
import { connect } from 'react-redux';
import { lockFacilities } from 'core/state/actions/groupsSettings';
import { getLockedFacilities } from 'core/state/selectors/groupsSettings';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

class LockRow extends Component {
  state = { hide: false };
  componentDidMount() {
    this.checkProps(this.props)
  }
  componentWillReceiveProps(nextProps) {
    this.checkProps(nextProps)
  }
  checkProps = props => {
    const { getLockedFacilities, item } = props
    if (getLockedFacilities.includes(item.id)) {
      this.setState({
        hide: true
      })
    }
  };
  lockRow = () => {
    const { item, lockFacilities } = this.props;
    ReactTooltip.rebuild();
    this.setState({
      hide: !this.state.hide
    }, () => lockFacilities('SUCCESS', item.id))
  };
  render() {
    const { hide } = this.state
    return (
      <span
        data-tip={
          hide ?
            'Запретить перемещение' :
            'Разрешить перемещение'
        }
        className="field-row__lock"
        data-for='mainTooltip'
      >
        <div className={classnames("field-row__lock-icon", { hide: hide })} onClick={this.lockRow}></div>
      </span>
    )
  }
}


export default connect(state => (
  {
    getLockedFacilities: getLockedFacilities(state)
  }
), { lockFacilities })(LockRow);