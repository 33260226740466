import {connect} from 'react-redux';
import {downloadProject, clearState} from 'core/state/actions/projects';
import {setRemoveProps, openRemoveAlert} from 'core/state/actions/settings';
import {lastProjects, lastWellfields} from 'core/state/selectors/common';

import ProjectsFirstPageContainer from './ProjectsFirstPageContainer';

const mapStateToProps = state => ({
    projects: lastProjects(state),
    wellfields: lastWellfields(state)
});

const mapDispatchToProps = {
    clearState,
    setRemoveProps,
    openRemoveAlert,
    downloadProject
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsFirstPageContainer);