import React, { Component } from 'react';
import { connect } from 'react-redux';
import CheckBox from '../CheckBox';
import CommonFields from '../CommonFields';
import PipeFields from './PipeFields';
import CommonField from '../../CommonField';
import { additionalTaskPropsPipes } from 'core/state/selectors/wellfield';
import { changeTaskSettings, activeTab } from 'core/state/actions/settings';
import { getActiveTab } from 'core/state/selectors/settings';
import { getFacilitiesFromProjectByTaskType } from 'core/state/actions/projects';
import BottomRow from './BottomRow';
import config from './config';
import _isEqual from 'lodash.isequal';

const coefs = [
  { coef: 'corridor_coeff_ad', label: 'АД' },
  { coef: 'corridor_coeff_vl', label: 'ВЛ' },
  { coef: 'corridor_coeff_nsk', label: 'НСК' },
  { coef: 'corridor_coeff_nnp', label: 'ННП' },
  { coef: 'corridor_coeff_vnd', label: 'ВНД' },
  { coef: 'corridor_coeff_vvd', label: 'ВВД' },
  { coef: 'corridor_coeff_gnd', label: 'ГНД' },
  { coef: 'corridor_coeff_gvd', label: 'ГВД' },
];

const units = {
  gas_rel_density: '',
  flow_temperature: 'deg',
  pressure_min: 'атм',
  pressure_max: 'атм',
  water_density: 'кг/м3',
  oil_density: 'т/м3',
  mu_oil_20: 'МПа*с',
  mu_oil_50: 'МПа*с',
};

const initialDefaultValues = {
  corridor_coeff_ad: 0,
  corridor_coeff_vl: 0,
  corridor_coeff_nsk: 0,
  corridor_coeff_nnp: 0,
  corridor_coeff_vnd: 0,
  corridor_coeff_vvd: 0,
  corridor_coeff_gnd: 0,
  corridor_coeff_gvd: 0,
};

class Pipes extends Component {
  state = {
    propsNames: {
      oil_density: 'Плотность нефти',
      water_density: 'Плотность воды',
      pressure_max: 'Максимальное давление',
      pressure_min: 'Минимальное давление',
      flow_temperature: 'Температура потока',
      mu_oil_50: 'Вязкость нефти при 50 \u00BA',
      mu_oil_20: 'Вязкость нефти при 20 \u00BA',
      gas_rel_density: 'Относительная плотность газа',
    },
    options: [],
    defaultValues: initialDefaultValues,
    taskInfo: { props: {} },
  };

  componentDidMount() {
    this.setPropsToState(this.props);
  }

  componentWillUnmount() {
    this.props.changeTaskSettings('SUCCESS', { props: {} });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setPropsToState(this.props, prevProps);
  }

  setPropsToState = (props, oldProps = {}) => {
    const { info, taskInfo, getActiveTab, additionalTaskPropsPipes } = props;
    if (!info) {
      if (getActiveTab !== oldProps.getActiveTab) {
        this.props.changeTaskSettings('SUCCESS', { props: {} });
      }
    }
    if (
      this.state.defaultValues.type !== getActiveTab &&
      getActiveTab !== 'taskInfo'
    ) {
      if (!info) {
        this.props.getFacilitiesFromProjectByTaskType('REQUEST', getActiveTab);
      }
      let defaultValues = Object.assign({}, initialDefaultValues);
      if (config[getActiveTab]) {
        defaultValues = {
          ...defaultValues,
          ...config[getActiveTab],
        };
      }
      this.setState(() => ({
        defaultValues: {
          ...defaultValues,
          type: getActiveTab,
        },
      }));
    }
    if (
      !_isEqual(additionalTaskPropsPipes, oldProps.additionalTaskPropsPipes)
    ) {
      this.setState(() => ({
        options: additionalTaskPropsPipes.map(({ id: value, name: label }) => ({
          value,
          label,
        })),
      }));
    }
    if (info && !_isEqual(taskInfo, oldProps.taskInfo)) {
      this.setState(() => ({
        taskInfo,
        defaultValues: {
          type: taskInfo.type,
          ...taskInfo.props,
        },
      }));
    }
  };
  pipesTypeHandler = value => {
    if (value !== this.props.getActiveTab) {
      this.props.activeTab('SUCCESS', value);
      this.props.getFacilitiesFromProjectByTaskType('REQUEST', value);
    }
  };

  render() {
    const { info, errors = [] } = this.props;
    const { defaultValues, propsNames, taskInfo } = this.state;
    return (
      <div>
        <CommonFields
          info={info}
          errors={errors}
          taskInfo={taskInfo}
          showAmountOfSolutions
        />
        <div className='create-task-main-container'>
          <div className='create-task-row'>
            <CommonField
              addedProps={{
                disabled: info,
                type: 'option',
                placeholder: '',
                propName: 'type',
                classContainer: 'wide',
                label: 'Тип трубопровода',
                options: this.state.options,
                defaultValue: defaultValues.type,
                onChangeHandler: this.pipesTypeHandler,
              }}
            />
          </div>
          <PipeFields
            units={units}
            disabled={info}
            propsNames={propsNames}
            defaultValues={defaultValues}
          />
          <div className='coefs-row'>
            <h2 className='coefs-row__title'>
              Коэффициенты прокладки в коридоре
            </h2>
            <div>
              {coefs.map(({ coef, label }, key) => (
                <CommonField
                  key={key}
                  addedProps={{
                    propName: `props.${coef}`,
                    placeholder: '',
                    type: 'number',
                    classContainer: 'quarter',
                    defaultValue: defaultValues[coef],
                    label,
                    disabled: info,
                  }}
                />
              ))}
            </div>
          </div>
          <div className='create-task-toggle-row'>
            <div className='create-task-toggle-row__title'>
              <CheckBox
                id='2jxaxf2'
                disabled={info}
                defaultValue={defaultValues.use_existing_pipes}
                propName='props.use_existing_pipes'
                label='Использовать существующие трубы'
              />
            </div>
          </div>
          {!info && <BottomRow />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  getActiveTab: getActiveTab(state),
  additionalTaskPropsPipes: additionalTaskPropsPipes(state),
});

const mapDispatchToProps = {
  activeTab,
  changeTaskSettings,
  getFacilitiesFromProjectByTaskType,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pipes);
