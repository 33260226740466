import React, { Component } from 'react';
import DataFields from 'components/Common/DataFields';
import { connect } from 'react-redux';

import { changeTaskSettings } from 'core/state/actions/settings';

class CommonField extends Component {
  state = { value: '' };

  onBlurHandler = ({ target }) => {
    const { valueAsNumber, type } = target;
    if (type === 'number') {
      this.setState(
        {
          value: valueAsNumber,
        },
        () => {
          if (typeof this.props.addedProps.onChangeHandler === 'function') {
            this.props.addedProps.onChangeHandler(valueAsNumber, type);
          }
        }
      );
    }
  };

  onChangeHandler = (value, type, valueAsNumber, option, name) => {
    this.setState(
      {
        value: value,
      },
      () => {
        if (typeof this.props.addedProps.onChangeHandler === 'function') {
          this.props.addedProps.onChangeHandler(value, type);
        }
        if (['props.length', 'props.width'].includes(name)) {
          this.props.changeTaskSettings('SUCCESS', { [name]: valueAsNumber });
        }
      }
    );
  };

  setPropsToState = props => {
    const { addedProps } = props;
    if (addedProps.defaultValue !== undefined) {
      this.setState({
        value: addedProps.round
          ? addedProps.defaultValue.toFixed(2)
          : addedProps.defaultValue,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.addedProps.defaultValue !== this.props.addedProps.defaultValue
    ) {
      this.setPropsToState(this.props);
    }
  }

  componentDidMount() {
    this.setPropsToState(this.props);
  }

  render() {
    const { addedProps, error } = this.props;
    const { propName: name, ...props } = addedProps;
    return (
      <DataFields
        {...props}
        name={name}
        error={error}
        value={this.state.value}
        onBlurHandler={this.onBlurHandler}
        onChangeHandler={({ value, type, valueAsNumber }, option) =>
          this.onChangeHandler(value, type, valueAsNumber, option, name)
        }
      />
    );
  }
}

const mapDispatchToProps = {
  changeTaskSettings,
};

export default connect(null, mapDispatchToProps)(CommonField);
