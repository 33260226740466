import { connect } from 'react-redux';
import {
  activeTab,
  setCardInfo,
  setRemoveProps,
  openRemoveAlert,
} from 'core/state/actions/settings';
import { getSortedAreasObjects } from 'core/state/selectors/objects';
import {
  sortClassesByMetaclasses,
  sortMetaclassesByClasses,
} from 'core/state/selectors/wellfield';
import { hideAreas, hideAreasGroup } from 'core/state/actions/groupsSettings';

import AreasListContainer from './AreasListContainer';

const mapDispatchToProps = {
  hideAreas,
  activeTab,
  setCardInfo,
  setRemoveProps,
  openRemoveAlert,
  hideAreasGroup,
};

const mapStateToProps = state => ({
  sortedAreas: getSortedAreasObjects(state),
  sortMetaclassesByClasses: sortMetaclassesByClasses(state),
  sortClassesByMetaclasses: sortClassesByMetaclasses(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AreasListContainer);
