import React, {Component} from 'react';

import './style.scss';
import TopRow from './TopRow';
import BottomRow from './BottomRow';

class LeftMenu extends Component {
    static TopRow = TopRow;
    static BottomRow = BottomRow;

    render() {
        const children = React.Children.map(this.props.children, child => React.cloneElement(child));
        return (
            <div className="left-sidebar">
                {children}
            </div>
        )
    }
}

export default LeftMenu;
