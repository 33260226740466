import {combineReducers} from 'redux';
import mapReducer from '../core/state/reducers/map';
import authReducer from '../core/state/reducers/auth';
import commonReducer from '../core/state/reducers/common';
import objectsReducer from '../core/state/reducers/objects';
import projectReducer from '../core/state/reducers/project';
import settingsReducer from '../core/state/reducers/settings';
import wellfieldReducer from '../core/state/reducers/wellfield';
import groupsSettingsReducer from '../core/state/reducers/groupsSettings'

export default combineReducers({
    map: mapReducer,
    auth: authReducer,
    common: commonReducer,
    project: projectReducer,
    objects: objectsReducer,
    wellfield: wellfieldReducer,
    groupsSettings: groupsSettingsReducer,
    settings: settingsReducer
});
