import React from "react";

export default ({inaccessibleTasksArr, allFields}) => {
    return <div className='inaccessible-tasks__container'>
        <h2 className='inaccessible-tasks__title'>Задачи по расчету:</h2>
        <ul className='inaccessible-tasks__list'>
            {
                inaccessibleTasksArr
                    .map((key, id) => {
                        return <li
                            key={id}
                            className='inaccessible-tasks__row'
                        >
                            {allFields[key]}
                        </li>
                    })
            }
        </ul>
        <h2 className='inaccessible-tasks__title'>не доступны на выбранной схеме обустройства /
            данном месторождении.</h2>
    </div>
}
