import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import {getZoom} from 'core/state/selectors/map';
import {setZoom} from 'core/state/actions/map';
import PropTypes from 'prop-types';

import RightMenuComponent from './RightMenuComponent';

const RightMenu = props => {
    const [state, setState] = useState({
        hideElem: false,
        hide3d: false
    });
    const setZoom = val => {
        props.setZoom('SUCCESS', props.zoom + val)
    };
    const changeLocation = () => {
        const {match: {params: {id}}, history} = props;
        history.push(`/app/cesium/${id}`);
    };
    useEffect(() => {
        const checkRoute = location => {
            if ((location.pathname.includes('projects') && !location.pathname.includes('all')) || location.pathname.includes('wellfields')) {
                setState(prev => ({...prev, hideElem: true}))
            } else {
                setState(prev => ({...prev, hideElem: false}))
            }
            if (location.pathname.includes('projects') && location.pathname.includes('all')) {
                setState(prev => ({...prev, hide3d: true}))
            } else {
                setState(prev => ({...prev, hide3d: false}))
            }
        };
        checkRoute(props.location);
    }, [props.location]);
    if (state.hideElem) return null;
    return <RightMenuComponent
        setZoom={setZoom}
        hide3d={state.hide3d}
        changeLocation={changeLocation}
    />
};

RightMenu.propTypes = {
    setZoom: PropTypes.func.isRequired,
    zoom: PropTypes.number.isRequired
};

const mapDispatchToProps = {setZoom};
const mapStateToProps = state => ({
    zoom: getZoom(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RightMenu));