import React from 'react';
import {connect} from 'react-redux';
import {drawAreaObject, newProjectArea} from 'core/state/selectors/settings';
import AreaCreationToolsContainer from './AreaCreationToolsContainer';

const AreaCreationTools = ({drawAreaObject, newProjectArea, splashRef}) => {
    if (!drawAreaObject && !newProjectArea) return null;
    return <AreaCreationToolsContainer newProjectArea={newProjectArea} splashRef={splashRef}/>
};

const mapStateToProps = state => ({
    drawAreaObject: drawAreaObject(state),
    newProjectArea: newProjectArea(state)
});

export default connect(mapStateToProps)(AreaCreationTools);