import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getDemsByWellfield} from 'core/state/selectors/wellfield';
import {setTaskSettings} from 'core/state/actions/settings';
import PropTypes from 'prop-types';
import CommonField from '../CommonField';
import _isEqual from 'lodash.isequal';

export const commonFieldsNames = [
    'name',
    'dem_id',
    'priority',
    'amount_of_solutions',
    'graph_edge_length_m',
];

class CommonFields extends Component {
    state = {
        name: '',
        dem_id: '',
        priority: 4,
        amount_of_solutions: 1,
        graph_edge_length_m: 100,
        options: {
            priority: [
                {value: 0, label: 'Низкий'},
                {value: 4, label: 'Средний'},
                {value: 9, label: 'Высокий'}
            ],
            amount_of_solutions: [
                {value: 1, label: '1'},
                {value: 2, label: '2'},
                {value: 3, label: '3'},
                {value: 4, label: '4'},
                {value: 5, label: '5'}
            ],
            dem_id: []
        }
    };

    componentDidMount() {
        this.setProps(this.props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.info !== prevProps.info ||
            !_isEqual(prevProps.taskInfo, this.props.taskInfo) ||
            !_isEqual(prevProps.dems, this.props.dems)) {
            this.setProps(this.props);
        }
    }

    setProps = props => {
        const {dems, info, taskInfo} = props;
        if (dems && dems instanceof Array) {
            const dem_id = [];
            for (let item of dems) {
                dem_id.push({
                    value: item.id, label: item.type
                })
            }
            this.setState(() => ({
                options: {
                    ...this.state.options,
                    dem_id
                }
            }))
        }
        if (info) {
            this.setState(() => ({
                name: taskInfo.name,
                dem_id: taskInfo.props.dem_id,
                priority: taskInfo.props.priority,
                amount_of_solutions: taskInfo.props.amount_of_solutions,
                graph_edge_length_m: taskInfo.props.graph_edge_length_m
            }))
        }
    };

    onChangeHandler = (value, type) => {
        this.setState({
            [type]: value
        })
    };

    render() {
        const data = [
            {
                propName: 'name',
                placeholder: '',
                required: true,
                defaultValue: this.state.name,
                classContainer: 'wide',
                label: 'Название задачи',
                disabled: this.props.info
            },
            [{
                propName: 'priority',
                type: 'option',
                placeholder: '',
                required: true,
                defaultValue: this.state.priority,
                classContainer: 'half',
                label: 'Приоритет',
                options: this.state.options.priority,
                disabled: this.props.info
            },
                {
                    propName: 'props.amount_of_solutions',
                    type: 'option',
                    placeholder: '',
                    defaultValue: this.state.amount_of_solutions,
                    classContainer: 'half',
                    label: 'Количество решений',
                    options: this.state.options.amount_of_solutions,
                    disabled: this.props.info
                }],
            {
                dem: 'м',
                required: true,
                type: 'number',
                defaultValue: this.state.graph_edge_length_m,
                propName: 'props.graph_edge_length_m',
                placeholder: '',
                classContainer: 'graph-edge-length half',
                label: 'Шаг сетки',
                disabled: this.props.info
            },
            {
                required: true,
                propName: 'props.dem_id',
                type: 'option',
                placeholder: '',
                classContainer: 'wide',
                defaultValue: this.state.dem_id,
                label: 'Цифровая модель рельефа',
                options: this.state.options.dem_id,
                disabled: this.props.info
            }
        ];
        const {showAmountOfSolutions, errors = [], info} = this.props;
        return (
            <div className="create-task-container create-task-container_common-fields">
                {data.map((addedProps, key) => {
                    if (addedProps instanceof Array) {
                        return <div key={key} className="create-task-row">{
                            addedProps.map((props, id) => {
                                if (props.propName.includes('amount_of_solutions')) {
                                    if (showAmountOfSolutions) {
                                        return <CommonField
                                            key={key + id}
                                            addedProps={props}
                                            error={errors.includes(props.propName)}
                                        />
                                    } else {
                                        return null
                                    }
                                }
                                return <CommonField
                                    key={key}
                                    addedProps={props}
                                    error={errors.includes(props.propName)}
                                />
                            })}
                        </div>
                    }
                    if (!(info && addedProps.propName === 'name')) {
                        return <div key={key} className="create-task-row">
                            <CommonField
                                addedProps={addedProps}
                                error={errors.includes(addedProps.propName)}
                            />
                        </div>
                    }
                    return null
                })}
            </div>
        )
    }
};

CommonFields.propTypes = {
    showAmountOfSolutions: PropTypes.bool,
    dems: PropTypes.array
};

const mapStateToProps = state => ({
    dems: getDemsByWellfield(state)
});

const mapDispatchToProps = {
    setTaskSettings
};


export default connect(mapStateToProps, mapDispatchToProps)(CommonFields);
