import groupBy from 'utils/groupBy';
import toObject from 'utils/toObject';
import coloringObj from 'utils/coloringObj';
import _mapValues from 'lodash.mapvalues';
import sortByTitle from 'utils/sortByTitle';
import _get from 'lodash.get';
import {
  sortClassesByMetaclasses,
  classesByWellfield,
} from 'core/state/selectors/wellfield';
import { projectPalette } from 'core/state/selectors/projects';

import { createSelector } from 'reselect';
import { getActiveTab } from './settings';
import { taskObjects } from './projects';

export const getLinears = createSelector(
  ({ objects: { linears } }) => linears,
  linears => linears
);
export const getAreasFromState = createSelector(
  ({ objects: { areas } }) => areas,
  areas => areas
);
export const facilitiesByLayout = createSelector(
  ({ wellfield: { facilitiesByLayout } }) => facilitiesByLayout,
  facilitiesByLayout => facilitiesByLayout.data
);
export const getFacilities = createSelector(
  ({ objects: { facilities } }) => facilities,
  facilities => facilities
);
export const getSortedFacilities = createSelector(
  [getFacilities, projectPalette, classesByWellfield],
  (facilities, projectPalette, classesByWellfield) => {
    if (facilities) {
      const sortedObjects = _mapValues(
        groupBy(
          facilities.filter(({ type }) =>
            ['existing', 'calculated'].includes(type)
          ),
          'class_id'
        ),
        (el, key) => {
          if (el.length) {
            let beforeProp = coloringObj(projectPalette[key]).fillColor;
            const title = _get(classesByWellfield, [key, 'name']) || '';
            return {
              title,
              beforeProp,
              rowClass: 'row-toggle-facility',
              values: el.sort(sortByTitle('title')),
            };
          }
        }
      );
      const obj = Object.keys(sortedObjects)
        .map(key => [key, sortedObjects[key]])
        .sort(sortByTitle('title'))
        .reduce((acc = {}, el) => {
          acc[el[0]] = el[1];
          return acc;
        }, {});
      return obj;
    } else {
      return null;
    }
  }
);
export const getGroupsSettings = type =>
  createSelector(
    ({ groupsSettings }) => groupsSettings[type],
    items => items
  );

export const facilitiesBy = type =>
  createSelector(getFacilities, items => groupBy(items, type));

export const getFacilityById = facilitiesBy('id');
export const getFacilitiesByType = facilitiesBy('type');
export const getFacilityByClassId = facilitiesBy('class_id');

export const getAreasByMetaclassId = createSelector(getAreasFromState, items =>
  groupBy(items, 'metaclass_id')
);

export const getSortedAreasObjects = createSelector(
  [getAreasFromState, projectPalette, sortClassesByMetaclasses],
  (areas, projectPalette, sortClassesByMetaclasses) => {
    const sortedObjects = _mapValues(
      groupBy(areas, 'metaclass_id'),
      (el, key) => ({
        beforeProp:
          coloringObj(
            projectPalette[
              _get(sortClassesByMetaclasses, [key, 'valuesArray', 0])
            ]
          ).color || null,
        title: _get(sortClassesByMetaclasses, [key, 'title']),
        values: el.sort(sortByTitle('title')),
      })
    );
    const obj = Object.keys(sortedObjects)
      .map(key => [key, sortedObjects[key]])
      .sort(sortByTitle('title'))
      .reduce((acc = {}, el) => {
        acc[el[0]] = el[1];
        return acc;
      }, {});
    return obj;
  }
);

export const getLinearsId = createSelector(getLinears, linears =>
  toObject(linears, 'id')
);

export const getLinearsByMetaclassId = createSelector(getLinears, items =>
  groupBy(items, 'metaclass_id')
);

export const getVisibleFacilities = createSelector(
  [
    getFacilities,
    getGroupsSettings('hideFacilities'),
    getGroupsSettings('hideFacilitiesGroup'),
    facilitiesByLayout,
  ],
  (facilities, hideFacilities, hideFacilitiesGroup, facilitiesByLayout) => {
    if (!facilities && !facilitiesByLayout) return [];
    if (facilitiesByLayout)
      return facilitiesByLayout.map(el => ({
        ...el,
        className: 'new-project-facility',
      }));
    return facilities.filter(
      ({ id, class_id }) =>
        !hideFacilities.includes(id) && !hideFacilitiesGroup.includes(class_id)
    );
  }
);

export const connectionPoints = createSelector(
  getVisibleFacilities,
  facilities => {
    return facilities
      .filter(
        ({ connection_points }) => connection_points && connection_points.length
      )
      .map(({ connection_points, type }) => {
        return {
          type,
          facility_id: connection_points[0].facility_id,
          connection_points,
        };
      });
  }
);

export const taskFacilities = createSelector(
  [taskObjects, getVisibleFacilities, getActiveTab],
  (taskObjects, facilities, activeTab) => {
    if (
      activeTab !== 'taskInfo' ||
      taskObjects.type === 'linear' ||
      !taskObjects.data
    )
      return false;
    const object = toObject(facilities, 'id');
    return {
      type: taskObjects.type,
      data: taskObjects.data
        .filter(el => {
          return object[el] && object[el].type === 'calculated_tmp';
        })
        .map(id => object[id]),
    };
  }
);

export const getVisibleAreas = createSelector(
  [
    getAreasFromState,
    getGroupsSettings('hideAreas'),
    getGroupsSettings('hideAreasGroup'),
  ],
  (areas, hideAreas, hideAreasGroup) => {
    if (!areas) return [];
    return areas.filter(
      ({ id, metaclass_id }) =>
        !hideAreas.includes(id) && !hideAreasGroup.includes(metaclass_id)
    );
  }
);

export const getVisibleLinears = createSelector(
  [
    getLinears,
    getGroupsSettings('hideLinears'),
    getGroupsSettings('hideLinearsMetaclass'),
    getGroupsSettings('hideLinearsByMetaclassAndType'),
    getGroupsSettings('hideLinearsByMetaclassAndNetwork'),
  ],
  (
    linears,
    hideLinears,
    hideLinearsMetaclass,
    hideLinearsByMetaclassAndType,
    hideLinearsByMetaclassAndNetwork
  ) => {
    if (!linears) return [];
    const arr = linears.filter(
      ({ id, networks_ids, metaclass_id, type, name }) => {
        const linearCond = !hideLinears.includes(id);
        const linearMetaclass = !hideLinearsMetaclass.includes(metaclass_id);
        let metaclassAndType = true;
        let metaclassAndNetwork = true;
        if (hideLinearsByMetaclassAndType[metaclass_id]) {
          const { types } = hideLinearsByMetaclassAndType[metaclass_id];
          metaclassAndType = !types.includes(type);
        }
        if (hideLinearsByMetaclassAndNetwork[metaclass_id]) {
          const { types, networks } = hideLinearsByMetaclassAndNetwork[
            metaclass_id
          ];
          metaclassAndNetwork =
            !types.includes(type) ||
            !networks_ids.some(network => networks.includes(network));
        }
        return (
          linearCond &&
          linearMetaclass &&
          metaclassAndType &&
          metaclassAndNetwork
        );
      }
    );
    return toObject(arr, 'id');
  }
);
