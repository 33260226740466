import React, {Component} from 'react';
import {connect} from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {wellfields} from 'core/state/selectors/common';
import PreviewCol from '../Projects/ProjectsFirstPage/previewCol';
import ProjectsCol from '../Projects/ProjectsFirstPage/projectsCol';
import {showModal as setShowModal} from 'core/state/actions/settings';
import {setRemoveProps, openRemoveAlert} from 'core/state/actions/settings';
import {removeWellfield, downloadWellfield} from 'core/state/actions/wellfield';

import _values from 'lodash.values';

dayjs.extend(utc);

class Wellfields extends Component {
    state = {
        projects: []
    };
    setDate = ({_created}) => dayjs.utc(_created).format('DD.MM.YYYY H:mm');

    componentWillReceiveProps(nextProps) {
        this.setProjects(nextProps)
    }

    componentDidMount() {
        this.setProjects(this.props)
    }

    setProjects = props => {
        const {wellfields} = props;
        if (wellfields) {
            if (wellfields) {
                const projectsArray = _values(wellfields);
                if (wellfields) {
                    projectsArray.sort((a, b) => {
                        const cond = dayjs(a._created).isBefore(dayjs(b._created), 'second');
                        if (cond) return 1
                        else return -1
                    });
                    this.setState({
                        projects: projectsArray
                    })
                }
            }
        }
    };
    createNewWellfield = () => {
        this.props.history.push('/wellfields/new')
    };
    removeHandler = ({id, name}) => {
        const {setRemoveProps, openRemoveAlert} = this.props;
        setRemoveProps('SUCCESS', {
            id,
            removeType: 'wellfield',
            name: name || 'Без названия',
            title: 'месторождения',
            questionTitle: 'месторождение'
        });
        openRemoveAlert('SUCCESS', true)
    };
    closeRemoveAlert = () => {
        this.setState({
            nameForRemove: '',
            showAlert: false,
            idForRemove: ''
        })
    };
    downloadHandler = ({id, name}) => {
        this.props.downloadWellfield('REQUEST', {id, name})
    };

    render() {
        return <div className="projects">
            <div className="projects__container">
                <PreviewCol
                    wellfield
                    createNew={this.createNewWellfield}/>
                <ProjectsCol
                    wellfield
                    handleClick={() => {
                    }}
                    setDate={this.setDate}
                    setWellField={() => {
                    }}
                    remove={this.removeHandler}
                    projects={this.state.projects}
                    download={this.downloadHandler}/>
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
    wellfields: wellfields(state)
});
export default connect(mapStateToProps, {
    removeWellfield,
    downloadWellfield,
    setRemoveProps,
    openRemoveAlert,
    setShowModal
})(Wellfields);
