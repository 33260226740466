import React from 'react';
import CommonField from "../../CommonField";

const RoadWidth = React.memo(({error, road_width, disabled}) => {
    return <CommonField
        error={error}
        addedProps={{
            dem: 'м',
            required: true,
            propName: 'props.road_width',
            placeholder: '',
            type: 'number',
            defaultValue: road_width,
            classContainer: 'half',
            label: 'Ширина проезжей части',
            disabled
        }}
    />
});

export default RoadWidth;
