import React from 'react';
import MapType from './MapType';
import PropTypes from 'prop-types';
import ZoomButton from './ZoomButton';
import ThreeDButton from './ThreeDButton';
import MoveToSection from './MoveToSection';
import './rightMenu.scss';

const RightMenuComponent = ({setZoom, changeLocation, hide3d}) => {
    return <div className="rightMenu">
        <div className="right-menu__handlers">
            <div className="rightMenuZoom">
                <ZoomButton type='in' setZoom={setZoom}/>
                <ZoomButton type='out' setZoom={setZoom}/>
            </div>
            <MapType/>
            {
                !hide3d && <ThreeDButton
                    changeLocation={changeLocation}
                />
            }
            <MoveToSection/>
        </div>
    </div>
};

RightMenuComponent.propTypes = {
    setZoom: PropTypes.func.isRequired,
    changeLocation: PropTypes.func.isRequired
};

export default RightMenuComponent;