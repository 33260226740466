import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import classnames from 'classnames';
import {mapCenter} from 'core/state/selectors/map';
import {moveToMapCenter} from 'core/state/actions/map';

class MoveToSection extends Component {
    state = {show: true};

    static getDerivedStateFromProps(nextProps, prevState) {
        const {match} = nextProps;
        const show = !match.url.includes('new');
        if (show !== prevState.show) {
            return {
                show
            }
        }
        return null;
    }

    moveToMapCenter = () => this.props.moveToMapCenter();

    render() {
        const {show} = this.state;
        if (!show) return null;
        return (
            <div
                data-place="left"
                data-for='mainTooltip'
                data-tip='К центру проекта'
                onClick={this.moveToMapCenter}
                className={classnames('rightMenuIcon', {
                    disable: !this.props.mapCenter
                })}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M7.3 1V2.449C4.766 2.771 2.771 4.766 2.449 7.3H1V8.7H2.449C2.771 11.234 4.766 13.229 7.3 13.551V15H8.7V13.551C11.234 13.229 13.229 11.234 13.551 8.7H15V7.3H13.551C13.229 4.766 11.234 2.771 8.7 2.449V1H7.3ZM7.3 3.856V5.2H8.7V3.863C10.45 4.15 11.85 5.55 12.144 7.3H10.8V8.7H12.137C11.85 10.45 10.45 11.85 8.7 12.144V10.8H7.3V12.137C5.55 11.85 4.15 10.45 3.856 8.7H5.2V7.3H3.863C4.15 5.55 5.55 4.15 7.3 3.856ZM7.125 8C7.125 7.51675 7.51675 7.125 8 7.125C8.48325 7.125 8.875 7.51675 8.875 8C8.875 8.48325 8.48325 8.875 8 8.875C7.51675 8.875 7.125 8.48325 7.125 8Z"
                          fill="white"/>
                </svg>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    mapCenter: mapCenter(state)
});

const mapDispatchToProps = {
    moveToMapCenter
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MoveToSection));
