import React, { Component } from 'react'

import ToggleContainer from 'components/Common/ToggleContainer'
import TitleBefore from './TitleBefore'
import TitleAfter from './TitleAfter'
import ShowRows from './ShowRow'
import LockRow from './LockRow'
class ListWrapperItem extends Component {
  active = () => { return '' };
  chooseRow = item => {
    if (typeof this.props.chooseRow === 'function') {
      this.props.chooseRow(item)
    }
  };
  showList = (...args) => {
    this.props.showList(...args)
  };
  showRow = item => {
    this.props.showRow(item)
  };
  render() {
    const {
      type,
      objects,
      itemKey,
      lockRow,
      setView,
      openTask,
      updated,
      removeItem,
      onMouseEnter,
      onMouseLeave,
      removeMetaclass,
      removeMetaclassByType
    } = this.props;
    const listBefore = (item, bgColor) => <React.Fragment>
      <ShowRows type={type} item={item} showRow={this.showRow} />
      <span className="colorLine">
        <span className="colorLineColor" style={{ backgroundColor: bgColor }}></span>
      </span>
    </React.Fragment>;
    const listAfter = item => {
      let tooltipType = '';
      if (item.facility_area) {
        tooltipType = 'объект'
      }
      else if (item.geom && item.geom.type === "LineString") {
        tooltipType = 'объект'
      }
      else {
        tooltipType = 'область'
      }
      return <span className="common-component-handlers">
        <span
          data-tip={`Показать ${tooltipType} на карте`}
          data-for='mainTooltip'
          className="set-view"
          onClick={() => setView(item)}>
        </span>
        {item.itemType !== 'network' && <span className="field-row__remove" onClick={() => removeItem(item)}></span>}
        {typeof lockRow === 'function' && <LockRow item={item} />}
      </span>;
    };

    let titleProps = Object.assign({}, objects[itemKey]);
    delete titleProps['subLayer'];
    delete titleProps['data'];
    titleProps = {
      ...titleProps,
      onMouseEnter,
      onMouseLeave,
      itemKey,
      afterComp: ([func, subLayerKey]) => <TitleAfter
        remove={func}
        openTask={openTask}
        subLayerKey={subLayerKey}
      />,
      beforeComp: (bgColor, subLayerKey, subLayerParent) => <TitleBefore
        type={type}
        bgColor={bgColor}
        itemKey={itemKey}
        showList={this.showList}
        subLayerKey={subLayerKey}
        object={objects[itemKey]}
        subLayerParent={subLayerParent}
      />,
      titleClass: 'd-inline align-middle',
      afterCompFunc: [() => removeMetaclass(itemKey)],
      subAfterCompFunc: (type, subLayerParent) => removeMetaclassByType(itemKey, type, subLayerParent)
    };
    return <ToggleContainer
      updated={updated}
      titleProps={titleProps}
      listProps={{
        onMouseEnter,
        onMouseLeave,
        active: this.active,
        afterComp: listAfter,
        beforeComp: listBefore,
        chooseRow: this.chooseRow,
        data: objects[itemKey].values,
        subLayer: objects[itemKey].subLayer,
        rowClass: objects[itemKey].rowClass,
        beforeProp: objects[itemKey].beforeProp,
        containerClass: 'relative field-text-row'
      }} />
  }
}

export default ListWrapperItem;