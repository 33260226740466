import {taskConstants} from 'utils/constants'

export default (type) => {
    const {ROADS, HVL, QUARRIES, LINEAR_COST, FACILITY, PIPES, GATHERING_CENTERS} = taskConstants;
    if (type === LINEAR_COST) {
        return ['linear_id']
    }
    if (type === FACILITY) {
        return ['facility_id']
    }
    if (type === ROADS) {
        return ['facilities']
    }
    if (type === HVL) {
        return ['sources', 'consumers']
    }
    if (type === QUARRIES) {
        return ['quarries_ids']
    }
    if (type === GATHERING_CENTERS) {
        return ['facilities_ids']
    }
    if (PIPES.includes(type)) {
        return ['sinks', 'sources']
    }
    return []
}
