import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HideNetwork from '../AllLinearResults/HideNetwork';
import coloringObj from 'utils/coloringObj';

import Button from 'components/Common/Button';
import DataFields from 'components/Common/DataFields';

const AllLinearResults = ({ taskResult, sendNetwork }) => {
  const [selectedNetworkId, setSelectedNetworkId] = useState(null);
  const onChangeHandler = ({ value }) => {
    setSelectedNetworkId(value);
  };
  const acceptResult = () => {
    if (selectedNetworkId !== undefined) {
      sendNetwork('REQUEST', {
        index: selectedNetworkId.id + 1,
        id: selectedNetworkId.task_result_id,
        name: taskResult[selectedNetworkId.id].task_name,
      });
    }
  };
  if (!taskResult) return null;
  const options = taskResult.map((el, id) => {
    return {
      value: { task_result_id: el.id, id },
      label: id + 1,
    };
  });
  return (
    <div>
      <table className='result-table'>
        <thead>
          <tr className='result-table-top result-table-top__linear-cost'>
            <th className='small'></th>
            <th
              className='result-table__color-line'
              style={{ backgroundColor: '#8495AC' }}
            ></th>
            <th className='small'>№</th>
            <th>Протяженность, км</th>
            <th>Стоимость строительства, тыс. руб.</th>
            <th></th>
          </tr>
        </thead>
        <tbody className='result-table__body'>
          {taskResult &&
            taskResult.map((item, id) => {
              return (
                <tr key={id}>
                  <td className='small'>
                    <HideNetwork network_id={item.props.network_id} />
                  </td>
                  <td
                    className='result-table__color-line'
                    style={{
                      backgroundColor: coloringObj(`tmpColor${id}`).color,
                    }}
                  ></td>
                  <td className='small'>{id + 1}</td>
                  <td>{(item.props.total_length / 1000).toFixed(2) * 1}</td>
                  <td>{(item.props.total_cost / 1000).toFixed(2) * 1}</td>
                  <td></td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className='linear-task-container__buttom-row'>
        <span className='task-complete__label'>
          Выберите вариант, который хотите перенести в проект:
        </span>
        <DataFields
          type='option'
          placeholder=''
          options={options}
          onChangeHandler={onChangeHandler}
        />
        <Button className='task-complete__button' onClick={acceptResult}>
          Перенести
        </Button>
      </div>
    </div>
  );
};

AllLinearResults.propTypes = {
  taskResult: PropTypes.array,
  sendNetwork: PropTypes.func.isRequired,
};

export default AllLinearResults;
