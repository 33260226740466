import React, {useEffect} from 'react';

export default ({options, chooseItem, node, hideValues}) => {
    const handleClickOutside = event => {
        if (node && node.current && !node.current.contains(event.target)) {
            document.removeEventListener('mousedown', handleClickOutside);
            hideValues();
        }
    };
    useEffect(()=> (
        document.addEventListener('mousedown', handleClickOutside)
    ), []);
    return <div className='custom-select-values'>
        {options.map(({label, value}, key) => {
           return <span
               key={key}
               className='custom-select-value'
               onClick={() => chooseItem({label, value})}
           >
               {label}
           </span>
        })}
    </div>
};