import React, {Component} from 'react';

import CheckBox from '../CheckBox';
import CommonField from '../../CommonField';

import classnames from 'classnames';

export default class ToggleField extends Component {
    state = {checked: false};
    onChangeHandler = (value) => {
        this.setState({checked: value})
    };

    render() {
        const {data, info} = this.props;
        const {title, rows} = data;
        const {checked} = this.state;
        return (
            <>
                <div className="create-task-toggle-row__title">
                    <CheckBox disabled={info} onChange={this.onChangeHandler} {...title} />
                </div>
                {
                    !!rows && <div className={classnames('create-task-main-container', {
                        hide: !checked
                    })}>
                        <div className='create-task-toggle__line'/>
                        {rows.map((addedProps, key) => {
                                if (addedProps.type === 'checkbox') {
                                    return <div
                                        key={key}
                                        className="create-task-row">
                                        <CheckBox
                                            disabled={info}
                                            {...addedProps}
                                        />
                                    </div>
                                } else {
                                    return <div key={key} className="create-task-row">
                                        <CommonField
                                            addedProps={addedProps}
                                        />
                                    </div>
                                }
                            }
                        )}
                    </div>
                }
            </>
        )
    }
}
