import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setDefaultProps,
  changeTaskSettings,
} from 'core/state/actions/settings';
import {
  setQuarriesIds,
  getFacilitiesFromProjectByTaskType,
} from 'core/state/actions/projects';
import {
  networks,
  taskResult,
  projectPalette,
} from 'core/state/selectors/projects';
import BottomRow from './BottomRow';
import CommonFields from '../CommonFields';
import CommonField from '../../CommonField';
import { getFacilities } from '../../../../core/state/selectors/objects';
import _get from 'lodash.get';
import _isEqual from 'lodash.isequal';

class Quarries extends Component {
  state = {
    options: [],
    defaultValues: {},
    taskInfo: { props: {} },
  };

  componentDidMount() {
    this.setPropsToState(this.props);
    this.props.changeTaskSettings('SUCCESS', {
      type: 'quarries',
    });
    this.props.setDefaultProps('SUCCESS', {
      quarries_ids: [],
    });
    const { info } = this.props;
    if (!info) {
      this.props.getFacilitiesFromProjectByTaskType('REQUEST', 'quarries');
    }
  }

  componentWillUnmount() {
    this.props.changeTaskSettings('SUCCESS', { props: {} });
  }

  componentDidUpdate(prevProps) {
    this.setPropsToState(this.props, prevProps);
  }

  setPropsToState = (props, prevProps = {}) => {
    const { networks, info, taskInfo } = props;
    if (!_isEqual(prevProps.networks, networks)) {
      this.setState(() => {
        return {
          options: Object.keys(networks).map(key => ({
            value: networks[key][0].id,
            label: networks[key][0].name,
          })),
        };
      });
    }
    if (info && !_isEqual(prevProps.taskInfo, taskInfo)) {
      this.setState(() => ({
        taskInfo,
        defaultValues: {
          ...taskInfo.props,
        },
      }));
    }
    if (info && !_isEqual(props.taskResult, prevProps.taskResult)) {
      const networkId = _get(props.taskResult, [0, 'props', 'network_id']);
      if (networkId) {
        const taskQuarries = props.getFacilities.filter(({ props }) => {
          if (props && props.network_ids) {
            return props.network_ids.includes(networkId);
          }
          return false;
        });
        if (taskQuarries.length) {
          props.setQuarriesIds(
            'SUCCESS',
            taskQuarries
              .filter(
                ({ class_id }) => props.projectPalette[class_id] === 'quarry'
              )
              .map(({ id }) => id)
          );
        }
      }
    }
  };

  onChangeNetworkHandler = network_id => {
    this.props.changeTaskSettings('SUCCESS', {
      'props.network_id': network_id,
    });
  };

  render() {
    const { info, errors = [] } = this.props;
    const { defaultValues, taskInfo } = this.state;
    return (
      <div>
        <CommonFields info={info} errors={errors} taskInfo={taskInfo} />
        <div className='create-task-main-container'>
          <div className='create-task-row'>
            <CommonField
              error={errors.includes('props.network_id')}
              addedProps={{
                propName: 'props.network_id',
                placeholder: '',
                required: true,
                type: 'option',
                disabled: info,
                classContainer: 'wide',
                label: 'Дорожная сеть',
                onChangeHandler: this.onChangeNetworkHandler,
                defaultValue: defaultValues.network_id,
                options: this.state.options,
              }}
            />
          </div>
        </div>
        {!info && <BottomRow />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projectPalette: projectPalette(state),
  getFacilities: getFacilities(state),
  taskResult: taskResult(state),
  networks: networks(state),
});

const mapDispatchToProps = {
  setQuarriesIds,
  setDefaultProps,
  changeTaskSettings,
  getFacilitiesFromProjectByTaskType,
};

export default connect(mapStateToProps, mapDispatchToProps)(Quarries);
