import React, {Component} from "react";
import {connect} from 'react-redux';
import {wellfields} from 'core/state/selectors/common';
import {setView} from 'core/state/actions/map';
import {getProjectName, getProjectId} from 'core/state/selectors/projects';
import {getNewAreaPoints} from 'core/state/selectors/settings';
import {layouts} from 'core/state/selectors/wellfield';
import {clickType, removeClickType, setTaskSettings} from "core/state/actions/settings";
import {sendProject, getProjectPalette} from 'core/state/actions/projects';
import {getLayouts, getFacilitiesByLayout, setPrimaryFacilities} from 'core/state/actions/wellfield';
import {withRouter} from 'react-router-dom';
import CreateNewProjectContainer from './CreateNewProjectContainer';
import _get from 'lodash.get';

class CreateNewProjectRedux extends Component {
    state = {projectName: '', wellfield: null};
    createNewProject = () => {
        const {getNewAreaPoints} = this.props;
        const {wellfield, projectName, schemeOptions} = this.state;
        const obj = {
            name: projectName,
            wellfield_id: wellfield,
            layout_id: schemeOptions,
            project_area: JSON.stringify({
                type: "Polygon",
                coordinates: [getNewAreaPoints]
            })
        };
        if (getNewAreaPoints && projectName && wellfield) {
            this.props.sendProject('REQUEST', obj);
        } else {
            alert('заполните все поля')
        }
    };
    onChangeHandler = (target, value) => {
        const val = target;
        if (value === 'wellfield' && val !== this.state.wellfield) {
            const wellfields = this.props.wellfields;
            const coordinates = _get(wellfields, [val, 'geom', 'coordinates']);
            if (coordinates) {
                const [lng, lat] = coordinates;
                this.props.setView('SUCCESS', {lat, lng});
            }
            this.props.setTaskSettings('SUCCESS', {props: {}});
            this.props.getLayouts('REQUEST', val);
            this.props.getProjectPalette('REQUEST', val);
        }
        if (value === 'schemeOptions' && val !== this.state.schemeOptions) {
            this.props.getFacilitiesByLayout('REQUEST', val);
        }
        this.setState({[value]: val});
    };

    handleCancel = () => {
        this.props.setPrimaryFacilities('SUCCESS', []);
        this.props.getFacilitiesByLayout('SUCCESS', []);
        this.props.history.goBack()
    };

    componentDidMount() {
        this.props.clickType('SUCCESS', ['createArea', 'newProjectArea'])
    };

    componentWillReceiveProps(nextProps) {
        const {getProjectId} = nextProps;
        if (getProjectId && getProjectId !== this.props.getProjectId) {
            this.props.history.push(`/app/${getProjectId}`)
        }
    };

    render() {
        return <CreateNewProjectContainer
            cancel={this.handleCancel}
            layouts={this.props.layouts}
            wellfields={this.props.wellfields}
            onChangeHandler={this.onChangeHandler}
            createNewProject={this.createNewProject}
            getNewAreaPoints={this.props.getNewAreaPoints}/>
    }
}

const mapDispatchToProps = {
    setView,
    clickType,
    getLayouts,
    sendProject,
    setTaskSettings,
    removeClickType,
    getProjectPalette,
    setPrimaryFacilities,
    getFacilitiesByLayout
};

const mapStateToProps = state => ({
    layouts: layouts(state),
    wellfields: wellfields(state),
    getProjectId: getProjectId(state),
    projectName: getProjectName(state),
    getNewAreaPoints: getNewAreaPoints(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewProjectRedux));
