import React, { Component } from 'react'
import classnames from 'classnames'

export default class Log extends Component {
  clickHandler = () => {
    this.textArea.select()
    document.execCommand('copy')
  }
  render() {
    const { date, message, debug_info, finished, has_errors, percentage, task_id } = this.props
    const progressClass = {
      done: finished && !has_errors,
      fail: has_errors
    }
    let debugInfo = !debug_info ? 'Нет информации' : debug_info
    let textForCopy = `task_id: ${task_id} \ndebugInfo: ${debugInfo}`
    return <div
      className="task-complete__logs-row">
      <div className="task-complete__logs__left">
        <span className="task-complete__date">[{date}]</span>
        <span className="task-complete__name">{message}</span>
      </div>
      <textarea
        className="hidden-textarea"
        ref={ref => this.textArea = ref}
        value={textForCopy}
      />
      <span onClick={this.clickHandler} className={classnames("task-complete__progress", progressClass)}>{percentage} %</span>
    </div>
  }
}
