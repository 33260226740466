/*/////////////////
**    AREA      **
////////////////*/
export const GET_AREAS = 'GET_AREAS';
export const REMOVE_AREAS = 'REMOVE_AREAS';
export const GET_AREA_BY_ID = 'GET_AREA_BY_ID';
export const GET_AREAS_REQUEST = 'GET_AREAS_REQUEST';
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS';
export const REMOVE_AREAS_REQUEST = 'REMOVE_AREAS_REQUEST';
export const REMOVE_AREAS_SUCCESS = 'REMOVE_AREAS_SUCCESS';

/*/////////////////
**    LINEAR     **
////////////////*/
export const GET_LINEARS = 'GET_LINEARS';
export const GET_LINEAR_BY_ID = 'GET_LINEAR_BY_ID';
export const REMOVE_LINEARS = 'REMOVE_LINEARS';
export const GET_LINEARS_REQUEST = 'GET_LINEARS_REQUEST';
export const GET_LINEARS_SUCCESS = 'GET_LINEARS_SUCCESS';
export const REMOVE_LINEARS_REQUEST = 'REMOVE_LINEARS_REQUEST';
export const REMOVE_LINEARS_SUCCESS = 'REMOVE_LINEARS_SUCCESS';
export const GET_LINEAR_BY_ID_SUCCESS = 'GET_LINEAR_BY_ID_SUCCESS';

/*/////////////////
**    FACILITY   **
////////////////*/
export const GET_FACILITIES = 'GET_FACILITIES';
export const REMOVE_FACILITY = 'REMOVE_FACILITY';
export const GET_FACILITIES_BY_ID = 'GET_FACILITIES_BY_ID';
export const SET_FACILITY_BY_ID = 'SET_FACILITY_BY_ID';
export const GET_FACILITIES_REQUEST = 'GET_FACILITIES_REQUEST';
export const GET_AREA_BY_ID_SUCCESS = 'GET_AREA_BY_ID_SUCCESS';
export const GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS';
export const REMOVE_FACILITY_REQUEST = 'REMOVE_FACILITY_REQUEST';
export const REMOVE_FACILITY_SUCCESS = 'REMOVE_FACILITY_SUCCESS';
export const SET_FACILITY_BY_ID_SUCCESS = 'SET_FACILITY_BY_ID_SUCCESS';
export const GET_FACILITIES_BY_ID_SUCCESS = 'GET_FACILITIES_BY_ID_SUCCESS';
export const GET_FACILITIES_BY_ID_REQUEST = 'GET_FACILITIES_BY_ID_REQUEST';

/*/////////////////
**    COMMON   **
////////////////*/

export const SELECT_ALL = 'SELECT_ALL';
export const SELECT_ALL_REQUEST = 'SELECT_ALL_REQUEST';
