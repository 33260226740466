import React, {createRef} from 'react';
import {Map, ScaleControl} from "react-leaflet";
import Ruler from "./Ruler";
import ElementsGroup from "./ElementsGroup";
import CreationTools from "./CreationTools";
import TileLayerComponent from "./TileLayerComponent";
import SplashPoly from "./SplashPoly/SplashPolyRedux";
import ReactLeafletAdjust from "./ReactLeafletAdjust";
import HandleActiveObjects from "./HandleActiveObjects";

import 'leaflet/dist/leaflet.css';
import './style.scss';


const ReactLeafletMap = () => {
    const layerRef = createRef();
    const splashRef = createRef();
    return <div id="mapUI">
        <Map id='map' zoom={14} center={[67.86, 75.31]}>
            <Ruler/>
            <ReactLeafletAdjust/>
            <TileLayerComponent/>
            <HandleActiveObjects/>
            <ElementsGroup ref={layerRef}/>
            <SplashPoly splashRef={splashRef}/>
            <CreationTools layerRef={layerRef} splashRef={splashRef}/>
            <ScaleControl/>
        </Map>
    </div>;
};

export default ReactLeafletMap;
