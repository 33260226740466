import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Common/Button';

const FacilityTaskResultView = ({taskResult, setNewPoPosition}) => {
    if (!taskResult || !taskResult[0].props) return null;
    return <div>
        <table className='result-table'>
            <thead>
            <tr className='result-table-top result-table-top__linear-cost'>
                <th>Позиция</th>
                <th>Суммарная стоимость, тыс. руб.</th>
            </tr>
            </thead>
            <tbody className='result-table__body'>
            <tr>
                <td className='result-table__initial-position'>Начальная</td>
                <td>{(taskResult[0].props.old_total_cost / 1000).toFixed(2) * 1}</td>
            </tr>
            <tr>
                <td className='result-table__optimized-position'>Оптимальная</td>
                <td>{(taskResult[0].props.total_cost / 1000).toFixed(2) * 1}</td>
            </tr>
            </tbody>
        </table>
        <div className='result-table__accept-results'>
            <h2 className="result-table__accept-results-title">Разместить</h2>
            <Button onClick={setNewPoPosition}>Оптимальная позиция</Button>
        </div>
    </div>
};

FacilityTaskResultView.propTypes = {
    taskData: PropTypes.object,
    setNewPoPosition: PropTypes.func.isRequired
};

export default FacilityTaskResultView;
