import groupBy from 'utils/groupBy'
import {createSelector} from "reselect";

export const getAdditionalProps = state => {
    const data = {};
    Object.keys(state.wellfield.classesProps).forEach(classKey => {
        const metaclass = state.wellfield.sortMetaclassesByClasses[classKey];
        const metaclassProps = state.wellfield.metaclassesProps[metaclass];
        const classesPropsByName = groupBy(state.wellfield.classesProps[classKey], 'name');
        const metaclassPropsByName = groupBy(metaclassProps, 'name');
        data[classKey] = {...classesPropsByName};
        Object.keys(metaclassPropsByName).forEach(key => {
            if (!data[classKey][key]) {
                data[classKey][key] = metaclassPropsByName[key]
            }
        });
        data[classKey] = {
            ...Object.keys(data[classKey]).map(key => data[classKey][key][0])
        }
    });
    return data;
};

export const layouts = state => state.wellfield.layouts;
export const connectionTypes = state => state.wellfield.connectionTypes;
export const getDemsByWellfield = state => state.wellfield.demsByWellfield;
export const getMetaclassesProps = state => state.wellfield.metaclassesProps;

export const additionalTaskProps = state => state.wellfield.additionalTaskProps;
export const additionalTaskPropsRoads = state => state.wellfield.additionalTaskProps.roads;
export const additionalTaskPropsPipes = state => {
    let enabled_tasks = [];
    if (state.project.avaliableTaskTypes[0]) {
        enabled_tasks = state.project.avaliableTaskTypes[0].enabled_tasks;
    }
    return state.wellfield.additionalTaskProps.pipes.filter(el => enabled_tasks.includes(el.id))
};

export const getClassesByMetaclass = state => state.wellfield.classesByMetaclass;
export const classesByMetaclasses = state => state.wellfield.classesByMetaclasses;

export const sortClassesByMetaclasses = state => state.wellfield.sortClassesByMetaclass;
export const classesByWellfield = state => state.wellfield.classesByWellfield;
export const getMetaclassesByWellfield = state => state.wellfield.metaclassesByWellfield;
export const metaclassesByType = createSelector([getMetaclassesByWellfield], getMetaclassesByWellfield => {
    return groupBy(getMetaclassesByWellfield, 'type');
});

export const getMetaclassesSortedById = state => {
    return groupBy(state.wellfield.metaclassesByWellfield, 'id')
};

export const sortMetaclassesByClasses = state => state.wellfield.sortMetaclassesByClasses;
