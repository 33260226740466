import React from 'react';
import CommonField from '../../CommonField'
import DemsComponent from "./DemsComponent";
import _get from 'lodash.get';
import {useDispatch} from "react-redux";
import {getFacilitiesFromProjectByTaskType} from 'core/state/actions/projects';
import {clickType} from 'core/state/actions/settings';
import BottomRow from "./BottomRow";
import fields from './fields';

export default React.memo(({info, taskInfo, errors = []}) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (!info) {
            dispatch(getFacilitiesFromProjectByTaskType('REQUEST', 'gathering_centers'));
            dispatch(clickType('SUCCESS', ["chooseGatheringCenters"]));
        }
    }, [info]);
    return <div className="d-flex flex-column justify-between flex-1">
        <div className="create-task-container">
            {fields.map((addedProps, index) => {
                let defaultValue = addedProps.defaultValue;
                if (taskInfo) {
                    if (addedProps === 'dem') {
                        defaultValue = taskInfo.props.dem_id;
                    } else {
                        defaultValue = _get(taskInfo, addedProps.propName);
                    }
                }
                return <div key={index} className="create-task-row">
                    {
                        addedProps === 'dem' ?
                            <DemsComponent
                                error={errors.includes('props.dem_id')}
                                disabled={info} defaultValue={defaultValue}/> :
                            <CommonField
                                error={errors.includes(addedProps.propName)}
                                addedProps={
                                    {
                                        ...addedProps,
                                        disabled: info,
                                        defaultValue
                                    }
                                }/>
                    }
                </div>
            })}
        </div>
        <BottomRow info={info}/>
    </div>
});
