import React from 'react'

const SearchInput = ({ value = '', onChange, placeholder }) =>
  <div className="search-input-container">
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="search-input" />
    <span className="search-logo"></span>
  </div>;

export default SearchInput;