import React from 'react';
import LinearHandlersComponent from './LinearHandlersComponent';

export default ({draw, magnet, repaint, draggable, createLinearObject, setTransformSettings}) => {
    const handleTransform = type => {
        if (type === 'magnet') {
            setTransformSettings('SUCCESS', ['draw', type]);
        } else {
            setTransformSettings('SUCCESS', type);
        }
    };
    if (!createLinearObject) return null;
    return <LinearHandlersComponent
        draw={draw}
        magnet={magnet}
        repaint={repaint}
        draggable={draggable}
        handleTransform={handleTransform}
    />
};