import React, {Component} from "react"
import {connect} from 'react-redux'
import HandlersButton from './HandlersButton'
import {setTransformSettings} from 'core/state/actions/settings'
import {getClickType, transformSettings} from 'core/state/selectors/settings'

class FacilityHandlers extends Component {
    onClickHandler = type => {
        this.props.setTransformSettings('SUCCESS', type)
    };

    render() {
        const {createFacility, transformSettings} = this.props;
        if (createFacility) {
            return <div className="handlers">
                <HandlersButton
                    type="drag"
                    title="Перемещение"
                    active={transformSettings.draggable}
                    clickType='draggable'
                    onClick={this.onClickHandler}
                />
                <HandlersButton
                    type="rotate"
                    title="Поворот"
                    active={transformSettings.transform.rotation}
                    clickType='transform.rotation'
                    onClick={this.onClickHandler}/>
                <HandlersButton
                    type="connection"
                    title="Точки подключения"
                    onClick={() => {
                    }}
                />
            </div>
        }
        return null
    }
}

const mapDispatchToProps = {
    setTransformSettings
};

const mapStateToProps = state => ({
    createFacility: getClickType(state).createFacility,
    transformSettings: transformSettings(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(FacilityHandlers);