import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { downloadProject, getPreviewProjectProps } from 'core/state/actions/projects';
import { setRemoveProps, openRemoveAlert } from 'core/state/actions/settings';
import { setMapType } from "../../../core/state/actions/map";
import { projects, wellfields } from 'core/state/selectors/common';

import SearchInput from 'components/Common/SearchInput';
import LeftSidebar from 'components/LeftSidebar';
import OpenProjectsComponent from './OpenProjectsComponent';
import WithProjectsProps from 'components/HOC/WithProjectsProps';
import Button from 'components/Common/Button';
import _values from 'lodash.values';
import _get from 'lodash.get';
import groupBy from 'utils/groupBy';

const OpenProjects = props => {
  const [searchValue, setSearchValue] = useState('');
  const [projectId, setProjectId] = useState(null);
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    handleProjects(searchValue)
  }, [props]);
  useEffect(() => {
    handleProjects(searchValue)
  }, [searchValue]);

  const handleProjects = searchValue => {
    const { projects, wellfields } = props;
    if (projects && wellfields) {
      let dataByWellfield = groupBy(_values(projects)
          .filter(({name}) => name
              .toLowerCase()
              .includes(searchValue)),
          'wellfield_id');
      let data = Object.keys(dataByWellfield)
          .map(key => ({
            title: _get(wellfields, [key,'name']),
            data: dataByWellfield[key]
          }));
      setProjects(data);
    }
  };
  const choseProject = ({id}) => {
    setProjectId(id);
    props.getPreviewProjectProps('REQUEST', id);
  };
  const handleClick = () => {
    const { changeLocation } = props;
    if (projectId !== null) {
      changeLocation(`/app/${projectId}`)
    }
    props.setMapType('SUCCESS', 'withoutMap');
  };
  const activeRow = ({id}) => {
    return id === projectId ? 'active' : '';
  };
  const cancelHandler = () => {
    props.setMapType('SUCCESS', 'Google');
    props.changeLocation('/projects')
  };
  const removeHandler = ({id, wellfieldName: wellfield, name = 'Без названия'}) => {
    props.setRemoveProps('SUCCESS', {
      id,
      name,
      wellfield,
      removeType: 'project',
      title: 'проекта',
      questionTitle: 'проект'
    });
    props.openRemoveAlert('SUCCESS', true)
  };
  const download = ({ id, name }) => {
    props.downloadProject('REQUEST', { id, name })
  };
  const onSearchHandler = ({ target: {value} }) => {
    setSearchValue(value);
  };
  return <LeftSidebar>
      <div className="left-sidebar__main-container">
        <div className="left-sidebar__top-fields">
          <LeftSidebar.TopRow>
            <h2 className="main-title">Открытие проекта</h2>
            <div onClick={cancelHandler} className="new-project__close">
              <div className="new-project__close-line close"></div>
            </div>
          </LeftSidebar.TopRow>
          <div className="left-sidebar__row">
            <SearchInput
              placeholder='Поиск проекта…'
              value={searchValue}
              onChange={onSearchHandler} />
          </div>
          <div className="left-sidebar__projects">
            <OpenProjectsComponent
              projects={projects}
              openList={!!searchValue}
              projectId={projectId}
              download={download}
              activeRow={activeRow}
              remove={removeHandler}
              handleClick={handleClick}
              choseProject={choseProject}
              activeText={searchValue}
              cancelHandler={cancelHandler} />
          </div>
        </div>
        <LeftSidebar.BottomRow>
          <Button onClick={handleClick}>Открыть</Button>
          <Button secondary onClick={cancelHandler}>Отмена</Button>
        </LeftSidebar.BottomRow>
      </div>
    </LeftSidebar>
};

const mapStateToProps = state => ({
  projects: projects(state),
  wellfields: wellfields(state)
});

const mapDispatchToProps = {
  setRemoveProps,
  openRemoveAlert,
  downloadProject,
  getPreviewProjectProps,
  setMapType
};

export default connect(mapStateToProps, mapDispatchToProps)(WithProjectsProps(OpenProjects));