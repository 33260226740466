import React from 'react';

import List from '../List';
import InfoCard from '../InfoCard';
import CreateCard from '../CreateCard';

const FieldsFactory = ({type}) => {
    if ([
        'infoCard',
        'objectInfoCard',
        'linearInfoCard',
        'areaInfoCard',
        'taskInfo'
    ].includes(type)) {
        return <InfoCard/>
    } else if (['tasks', 'areas', 'linear', 'objects'].includes(type)) {
        return <List type={type}/>;
    } else {
        return <CreateCard/>
    }
};

export default FieldsFactory;
