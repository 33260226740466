import React from 'react';
import {LineChart, Line} from 'recharts';
import CustomizedActiveDot from '../CustomizedActiveDot';

export default ({width, height, plotData, chartStyles, children}) => {
    return <LineChart height={height} width={width} data={plotData} margin={chartStyles}>
        {children}
        <Line
            dot={false}
            dataKey="y"
            type="monotone"
            stroke="#77F4E8"
            activeDot={<CustomizedActiveDot strokeWidth={1.8} r={4.5}/>}
        />
    </LineChart>
};