import React from 'react';
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import Button from 'components/Common/Button';
import {taskConstants} from 'utils/constants';
import {getActiveTab} from 'core/state/selectors/settings';

const BottomRow = ({firstButtonHandler, secondButtonHandler}) => {
    const type = useSelector(getActiveTab);
    const firstButtonTitle = () => {
        const {ROADS, HVL, QUARRIES, LINEAR_COST, FACILITY, PIPES, GATHERING_CENTERS} = taskConstants;
        if (PIPES.includes(type)) {
            return 'Запустить'
        }
        switch (type) {
            case HVL:
            case ROADS:
            case FACILITY:
            case QUARRIES:
            case LINEAR_COST:
            case GATHERING_CENTERS:
                return 'Запустить';
            case 'createArea':
            case 'createFacility':
            case 'createLinearObject':
                return 'Создать';
            default:
                return 'Сохранить';
        }
    };
    const secondButtonTitle = () => {
        if (type === 'taskInfo') {
            return 'Удалить'
        }
        return 'Отмена'
    };
    return (
        <>
            <Button onClick={firstButtonHandler || (() => {
            })} type='submit'>
                {firstButtonTitle()}
            </Button>
            <Button secondary onClick={secondButtonHandler}>
                {secondButtonTitle()}
            </Button>
        </>
    )
};
BottomRow.propTypes = {
    secondButtonHandler: PropTypes.func.isRequired
};

export default BottomRow;
