import {connect} from 'react-redux';
import {auth} from 'core/state/selectors/auth';
import {login, setAuth} from 'core/state/actions/auth';

import LoginContainer from './LoginContainer';

const mapDispatchToProps = {login, setAuth};

const mapStateToProps = state => ({
    auth: auth(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
