import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import TopMenuView from "./TopMenuView";
import _get from 'lodash.get';

class TopMenu extends Component {
    componentDidMount() {
        document.addEventListener('mousedown', this.props.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.props.handleClickOutside);
    }

    openProject = () => {
        this.props.history.push('/projects/all');
    };
    createProject = () => {
        this.props.clearState();
        this.props.history.replace('/app/new');
    };
    saveProject = () => {
        this.props.setSuccessLogs('SUCCESS', 'Project is not saved successfully');
    };
    openFirstPage = () => {
        this.props.setMapType('SUCCESS', 'Google');
        this.props.history.push('/projects');
    };
    showModal = () => {
        this.props.showModal('SUCCESS', {type: 'AboutProjectModal', option: null});
        this.props.optionHandler();
    };
    removeProjectHandler = () => {
        const {wellfieldId, openRemoveAlert, setRemoveProps, projectId, projectName, wellfields} = this.props;
        let wellfield = _get(wellfields, [wellfieldId, 'name']) || 'нет названия';
        setRemoveProps('SUCCESS', {
            id: projectId,
            wellfield,
            removeType: 'project',
            name: projectName || 'Без названия',
            title: 'проекта',
            questionTitle: 'проект'
        });
        openRemoveAlert('SUCCESS', true)
    };
    downloadProjectHandler = () => {
        const {downloadProject, projectId: id, projectName: name} = this.props;
        downloadProject('REQUEST', {id, name});
    };

    render() {
        return <TopMenuView
            showModal={this.showModal}
            saveProject={this.saveProject}
            openProject={this.openProject}
            openFirstPage={this.openFirstPage}
            createProject={this.createProject}
            removeProjectHandler={this.removeProjectHandler}
            downloadProjectHandler={this.downloadProjectHandler}
        />
    }
}

export default withRouter(TopMenu);
