import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MainPageView from './MainPageView';

class MainPageContainer extends Component {
    checkState = props => {
        if (!props.projectName) {
            const {match, getProjectById} = props;
            const {id} = match.params;
            getProjectById('REQUEST', id);
        }
        if (localStorage.getItem('settings')) {
            const settings = JSON.parse(localStorage.getItem('settings'));
            const id = props.match.params.id;
            if (settings[id]) {
                props.saveSettings('SUCCESS', settings[id]);
            }
        }
    };

    componentWillUnmount() {
        const {activeTab, clearState, getCommonInfo, saveSettings, showLoader} = this.props;
        saveSettings('REQUEST', this.props.match.params.id);
        activeTab('SUCCESS', 'objects');
        clearState('SUCCESS', false);
        showLoader('SUCCESS', false);
        getCommonInfo();
    }

    componentDidMount() {
        const {saveSettings, match} = this.props;
        this.checkState(this.props);
        window.addEventListener("beforeunload", function () {
            saveSettings('REQUEST', match.params.id)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.projectName !== this.props.projectName) {
            this.checkState(this.props);
        }
    }

    render() {
        return <MainPageView/>;
    }
}

MainPageContainer.propTypes = {
    projectName: PropTypes.string,
    clearState: PropTypes.func.isRequired,
    getCommonInfo: PropTypes.func.isRequired,
    getProjectById: PropTypes.func.isRequired
};

export default MainPageContainer;
