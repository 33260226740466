import React from "react";
import taskConfig from "../utils/tasksConfig";
import ListFactory from "./ListFactory";
import InaccessibleTasks from "./InaccessibleTasks";
import ActiveTasks from "./ActiveTasks";
import _get from "lodash.get";

export default ({type, taskClickHandler, avaliableTaskTypes, metaclassesByType, handleClick, pipesTypeArr}) => {
    const disabledTasks = _get(avaliableTaskTypes, [0, 'disabled_tasks']) || {};
    const inaccessibleTasks = _get(avaliableTaskTypes, [0, 'inaccessible_tasks']) || {};
    let inaccessibleTasksArr = Object.keys(inaccessibleTasks);
    let configFields = taskConfig.slice();
    let pipesType = pipesTypeArr.slice();
    const allFields = {
        pipes: 'Расчет сети трубопроводов',
        ...taskConfig.reduce((acc = {}, {title, type}) => {
            acc[type] = title;
            return acc
        }, {}),
        ...pipesTypeArr.reduce((acc = {}, {id: type, name: title}) => {
            acc[type] = title;
            return acc
        }, {})
    };
    if (inaccessibleTasks) {
        configFields = configFields
            .filter(({type}) => !Object.keys(inaccessibleTasks).includes(type));
        pipesType = pipesType
            .filter(({id}) => !Object.keys(inaccessibleTasks).includes(id));
        if (!pipesType.length) {
            inaccessibleTasksArr = inaccessibleTasksArr
                .filter(el => !(el.includes('pipe') || el.includes('oil_gathering_line')));
            inaccessibleTasksArr.push('pipes');
        }
    }
    if (type === 'objects') {
        return ListFactory(metaclassesByType, handleClick, 'Новый объект:', 'facility', 'createFacility', 'fillColor');
    }
    if (type === 'areas') {
        return ListFactory(metaclassesByType, handleClick, 'Новая область:', 'area', 'createArea');
    }
    if (type === 'linear') {
        return ListFactory(metaclassesByType, handleClick, 'Новый линейный объект:', 'linear', 'createLinearObject');
    }
    if (type === 'tasks') {
        return <div>
            <h2 className="topMenuContainerTitle">Новая задача:</h2>
            <ActiveTasks
                pipesType={pipesType}
                configFields={configFields}
                disabledTasks={disabledTasks}
                taskClickHandler={taskClickHandler}
                avaliableTaskTypes={avaliableTaskTypes}
            />
            {
                !!inaccessibleTasksArr.length &&
                <InaccessibleTasks
                    allFields={allFields}
                    inaccessibleTasksArr={inaccessibleTasksArr
                    }/>
            }
        </div>;
    }
    return null;
};
