import {
    GET_AREAS_SUCCESS,
    GET_LINEARS_SUCCESS,
    REMOVE_AREAS_SUCCESS,
    GET_AREA_BY_ID_SUCCESS,
    REMOVE_LINEARS_SUCCESS,
    GET_FACILITIES_SUCCESS,
    REMOVE_FACILITY_SUCCESS,
    GET_LINEAR_BY_ID_SUCCESS,
    SET_FACILITY_BY_ID_SUCCESS,
    GET_FACILITIES_BY_ID_SUCCESS
} from '../constants/objects';

import {CLEAR_STATE_SUCCESS} from '../constants/projects';

const initialState = {
    areas: null,
    linears: null,
    facilities: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_STATE_SUCCESS: {
            return initialState
        }
        case SET_FACILITY_BY_ID_SUCCESS: {
            const arr = state.facilities.slice();
            const {id, data} = action.payload;
            for (let i in arr) {
                if (arr[i].id === id) {
                    arr[i] = {
                        ...arr[i],
                        ...data
                    };
                    break;
                }
            }
            return {
                ...state,
                facilities: arr
            }
        }
        case GET_FACILITIES_BY_ID_SUCCESS:
            const arr = [...state.facilities, action.payload.data[0]];
            return {
                ...state,
                facilities: arr
            };
        case REMOVE_FACILITY_SUCCESS:
            const elemId = action.payload;
            const facilityArray = state.facilities.slice();
            for (let i in facilityArray) {
                if (facilityArray[i].id === elemId) {
                    facilityArray.splice(i, 1);
                    break
                }
            }
            return {
                ...state,
                facilities: facilityArray
            };
        case GET_FACILITIES_SUCCESS:
            return {
                ...state,
                facilities: action.payload
            };
        case GET_AREA_BY_ID_SUCCESS:
            return {
                ...state,
                areas: [
                    ...state.areas,
                    ...action.payload.data
                ]
            };
        case REMOVE_AREAS_SUCCESS:
            const areasElemId = action.payload;
            const areasArray = state.areas.slice();
            for (let i in areasArray) {
                if (areasArray[i].id === areasElemId) {
                    areasArray.splice(i, 1);
                    break
                }
            }
            return {
                ...state,
                areas: [
                    ...areasArray
                ]
            };
        case GET_AREAS_SUCCESS:
            return {
                ...state,
                areas: action.payload
            };
        case GET_LINEAR_BY_ID_SUCCESS:
            return {
                ...state,
                linears: [
                    ...state.linears,
                    action.payload
                ]
            };
        case REMOVE_LINEARS_SUCCESS:
            const linearElemId = action.payload;
            const linearsArray = state.linears.slice();
            for (let i in linearsArray) {
                if (linearsArray[i].id === linearElemId) {
                    linearsArray.splice(i, 1);
                    break
                }
            }
            return {
                ...state,
                linears: linearsArray
            };
        case GET_LINEARS_SUCCESS:
            return {
                ...state,
                linears: action.payload
            };
        default:
            return state
    }
}
