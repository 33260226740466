import React, { useState, useEffect } from 'react';
import { withLeaflet } from 'react-leaflet';
import coloringObj from 'utils/coloringObj';
import _get from 'lodash.get';
import LinearView from './LinearView';

const LinearContainer = ({
  linearTaskObjects,
  setCardInfo,
  leaflet,
  changeTaskSettings,
  activeTab,
  taskQuarries,
  projectPalette,
  chooseLinearObject,
  getVisibleLinears,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (getVisibleLinears) {
      const data = [];
      Object.keys(getVisibleLinears).forEach(key => {
        const item = getVisibleLinears[key];
        let styleId;
        let thirdCond = false;
        let fourthCond = !taskQuarries ? true : !taskQuarries.length;
        if (item.type !== 'calculated_tmp') {
          thirdCond = true;
        }
        if (item.type === 'calculated_tmp') {
          if (linearTaskObjects && !taskQuarries && linearTaskObjects.length) {
            if (linearTaskObjects.some(el => item.networks_ids.includes(el))) {
              styleId = `tmpColor${linearTaskObjects.indexOf(
                item.networks_ids[0]
              )}`;
              thirdCond = true;
              fourthCond = true;
            }
          }
          if (linearTaskObjects && taskQuarries) {
            for (let indx = 0; indx < item.networks_ids.length; indx++) {
              const id = item.networks_ids[indx];
              if (linearTaskObjects.includes(id)) {
                thirdCond = true;
                if (item.props && taskQuarries) {
                  if (item.props.attached_quarry_id) {
                    if (
                      taskQuarries.indexOf(item.props.attached_quarry_id) !== -1
                    ) {
                      styleId = `tmpColor${taskQuarries.indexOf(
                        item.props.attached_quarry_id
                      )}`;
                      fourthCond = true;
                    }
                  }
                }
              }
            }
          }
        }
        if (thirdCond && fourthCond) {
          const diameter = item.props.diameter;
          const thickness = item.props.thickness;
          const cost = item.props.cost;
          const length = item.props.length;
          let style = Object.assign(
            {},
            coloringObj(projectPalette[item.class_id])[item.type]
          );
          if (styleId && item.type === 'calculated_tmp') {
            style = coloringObj(styleId);
          }
          const color = style ? style.color : 'hotpink';
          let geoObj = {
            properties: {
              color,
              style,
              id: item.id,
              type: item.type,
              name: item.name,
              class_id: item.class_id,
              networks_ids: item.networks_ids,
              styleId: styleId || item.class_id,
              objectType: projectPalette[item.class_id],
            },
          };

          if (cost) geoObj['properties']['cost'] = cost;

          if (length) geoObj['properties']['length'] = length;

          if (diameter) geoObj['properties']['diameter'] = diameter;

          if (thickness) geoObj['properties']['thickness'] = thickness;

          if (item.linear_area && item.objectType !== 'forest_corridor') {
            data.push(Object.assign(geoObj, item.linear_area));
          } else {
            data.push(Object.assign(geoObj, item.geom));
          }
        }
      });
      setData(data.filter(el => el));
    }
  }, [getVisibleLinears, taskQuarries, linearTaskObjects]);
  const openCardHandler = itemId => {
    const item = getVisibleLinears[itemId];
    setCardInfo('SUCCESS', {
      type: 'linear',
      data: item,
    });
    activeTab('SUCCESS', 'infoCard');
    leaflet.map.closePopup();
  };
  const onClickHandler = e => {
    if (chooseLinearObject) {
      const linear_id = _get(e, 'target.options.data.properties.id');
      changeTaskSettings('SUCCESS', {
        props: {
          linear_id,
        },
      });
    }
  };
  if (!data.length) return <></>;
  return (
    <>
      {data.map((el, index) => {
        return (
          <LinearView
            el={el}
            index={index}
            key={el.properties.id + index}
            onClickHandler={onClickHandler}
            openCardHandler={openCardHandler}
          />
        );
      })}
    </>
  );
};

export default withLeaflet(LinearContainer);
