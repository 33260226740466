import React from 'react'
import ProjectButtons from './ProjectButtons'
import StartPageButtons from './StartPageButtons'

export default React.memo(({type, ...props}) => {
    if (type === 'project') {
        return <ProjectButtons {...props}/>;
    }
    if (type === 'startPageButtons') {
        return <StartPageButtons/>;
    }
    return null;
});
