import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {quarriesIds} from "core/state/selectors/settings";

export default () => {
    const quarries = useSelector(quarriesIds);
    useEffect(() => {
        if (quarries) {
            document.querySelectorAll('.facility.second-selected').forEach(el => {
                const className = el.getAttribute('class');
                const flag = quarries.some(function (element) {
                    return className.includes(element);
                });
                if (!flag) {
                    el.classList.remove('second-selected')
                }
            });
            quarries.forEach(id => {
                const elem = document.getElementsByClassName(`facility ${id}`)[0];
                if (!elem.classList.contains('second-selected')) {
                    elem.classList.add('second-selected');
                }
            })
        } else {
            document.querySelectorAll('.second-selected').forEach(el => {
                el.classList.remove('second-selected')
            })
        }
    }, [quarries]);
};
