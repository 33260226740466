import React, { Component } from "react";
import classNames from "classnames";
import { selectAll } from "core/state/actions/objects";
import { connect } from "react-redux";
import config from "./utils/config";
import { changeTaskSettings } from "core/state/actions/settings";

class Menu extends Component {
  state = {
    showSelectAllButton: false,
  };

  componentDidMount() {
    this.handleProps({}, this.props);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    this.handleProps(prevProps, this.props);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleProps = (prevProps, nextProps) => {
    const { taskType, activeId } = nextProps;
    if (prevProps.activeId !== activeId || prevProps.taskType !== taskType) {
      const showSelectAllButton =
        taskType !== "linear_cost" &&
        !(
          activeId === 0 &&
          [
            "oil_pipe",
            "oil_gathering_line",
            "facility_placement",
            "low_pressure_gas_pipe",
            "high_pressure_gas_pipe",
            "low_pressure_water_pipe",
          ].includes(taskType)
        ) &&
        !(activeId === 1 && ["high_pressure_water_pipe"].includes(taskType));
      this.setState({ showSelectAllButton });
    }
  };

  handleClickOutside = event => {
    if (this.menuRef && !this.menuRef.contains(event.target)) {
      document.removeEventListener("mousedown", this.handleClickOutside);
      this.props.closeMenu();
    }
  };

  selectAllHandler = () => {
    const { selectAll, taskType, activeId } = this.props;
    selectAll("REQUEST", config(taskType)[activeId].name);
  };

  removeObjects = () => {
    const { taskType, activeId, changeTaskSettings } = this.props;
    const propName = config(taskType)[activeId].name;
    if (propName) {
      changeTaskSettings("SUCCESS", {
        [`props.${propName}`]: "",
      });
    }
  };

  render() {
    const { activeId } = this.props;
    const { showSelectAllButton } = this.state;
    return (
      <ul
        ref={n => (this.menuRef = n)}
        className={classNames("choose-objects__menu", `active-${activeId}`)}
      >
        {showSelectAllButton && (
          <li className='choose-objects__all' onClick={this.selectAllHandler}>
            Выбрать все
          </li>
        )}
        <li onClick={this.removeObjects}>Очистить выбор</li>
        <li className='choose-objects__menu-triangle-container'>
          <span className='choose-objects__menu-triangle' />
        </li>
      </ul>
    );
  }
}

const mapDispatchToProps = {
  selectAll,
  changeTaskSettings,
};

export default connect(null, mapDispatchToProps)(Menu);
