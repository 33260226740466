import React from 'react';
import {useDispatch} from "react-redux";
import Button from "components/Common/Button";
import {activeTab, changeTaskSettings} from 'core/state/actions/settings';

export default ({data, index}) => {
    const dispatch = useDispatch();
    const onClickHandler = () => {
        const {gathering_centers_ids, facilities_ids} = data;
        const sinks = gathering_centers_ids[index];
        const sources = facilities_ids[index];
        dispatch(changeTaskSettings('SUCCESS', {'props.sinks': [sinks]}));
        dispatch(changeTaskSettings('SUCCESS', {'props.sources': sources}));
        dispatch(activeTab('SUCCESS', 'oil_gathering_line'));
    };
    return <div className="gathering-centers__row-button">
        <Button onClick={onClickHandler}>
            Рассчитать трубопровод НСК
        </Button>
    </div>
};
