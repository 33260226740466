import React from 'react';
import { connect } from 'react-redux';

import { taskFacilities } from 'core/state/selectors/objects';
import { projectPalette } from 'core/state/selectors/projects';
import FacilityView from './FacilityView';
import center from '@turf/center';
import WithIcons from 'components/HOC/WithIcons';
import coloringObj from '../../../../../utils/coloringObj';
import _get from 'lodash.get';

const TaskFacilities = ({ taskFacilities, projectPalette, setIcon }) => {
  if (!taskFacilities || !taskFacilities.data) return null;
  return (
    <>
      {taskFacilities.data.map(
        ({ facility_area, id, class_id, name }, index) => {
          let style = {
            color: '#6B4EFF',
            fillColor: '#6B4EFF',
            fillOpacity: 1,
            weight: 2,
          };
          let className = 'optimized-facility';
          if (taskFacilities.type === 'gatheringCenters') {
            style = coloringObj(_get(projectPalette, [class_id]));
            className = `facility-with-tmp-color tmpColor${index}`;
          }
          const icon = projectPalette ? setIcon(projectPalette[class_id]) : '';
          const [lng, lat] = center(facility_area).geometry.coordinates;
          const position = { lat, lng };
          const data = {
            ...facility_area,
            properties: { id },
          };
          return (
            <FacilityView
              id={id}
              key={id}
              icon={icon}
              data={data}
              name={name}
              style={style}
              position={position}
              className={className}
              facility_area={facility_area}
            />
          );
        }
      )}
    </>
  );
};

const mapStateToProps = state => ({
  taskFacilities: taskFacilities(state),
  projectPalette: projectPalette(state),
});

export default connect(mapStateToProps)(WithIcons(TaskFacilities));
