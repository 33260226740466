import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setNewWellfieldLogs } from 'core/state/actions/common'
import { newWellfieldTaskId } from 'core/state/selectors/common'

import WebSocketComponent from '../WebSocketContainer'

class NewWellfieldSocket extends Component {
  onMessageHandler = data => {
    this.props.setNewWellfieldLogs('SUCCESS',
      data.sort((a, b) => a.percentage - b.percentage))
  }
  render() {
    const { newWellfieldTaskId } = this.props
    if (!newWellfieldTaskId) return null
    return <>
      <WebSocketComponent
        url={`/tasks/${newWellfieldTaskId}/task_traces`}
        onMessageHandler={data => this.onMessageHandler(data)}
      />
    </>
  }
}

const mapStateToProps = state => ({
  newWellfieldTaskId: newWellfieldTaskId(state)
})

const mapDispatchToProps = { setNewWellfieldLogs }

export default connect(mapStateToProps, mapDispatchToProps)(NewWellfieldSocket)