import { connect } from 'react-redux';
import { taskId } from 'core/state/selectors/projects';
import { showTaskResults } from 'core/state/actions/settings';
import { cardInfo } from 'core/state/selectors/settings';
import {
  oppenedTasks,
  getLinearsToggle,
} from 'core/state/selectors/groupsSettings';
import { removeOpenedTasks } from 'core/state/actions/groupsSettings';

import SwitchResultsContainer from './SwitchResultsContainer';

const mapStateToProps = state => ({
  taskId: taskId(state),
  cardInfo: cardInfo(state),
  oppenedTasks: oppenedTasks(state),
  getLinearsToggle: getLinearsToggle(state),
});

const mapDispatchToProps = {
  showTaskResults,
  removeOpenedTasks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchResultsContainer);
