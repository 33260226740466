export  default disabled => {
    let config = {
        indicatorSeparator: styles => ({
            ...styles,
            display: 'none'
        }),
        input: styles => ({
            ...styles,
            fontSize: '14px',
            cursor: 'pointer',
            color: '#fff'
        }),
        option: styles => ({
            ...styles,
            cursor: 'pointer',
            color: '#fff',
            backgroundColor: '#202a38'
        }),
        singleValue: styles => ({
            ...styles,
            color: 'white',
        }),
        container: styles => ({
            ...styles,
            borderRadius: '2px',
            height: '32px',
            fontSize: '14px',
            backgroundColor: '#202a38'
        }),
        valueContainer: styles => ({
            ...styles,
            height: '32px'
        }),
        menuList: styles => ({
            ...styles,
            backgroundColor: '#202a38'
        }),
        placeholder: styles => ({
            ...styles,
            color: 'rgba(207,216,221, 0.5)',
            fontSize: '14px'
        }),
        indicatorsContainer: styles => ({
            ...styles,
            backgroundColor: '#202a38'
        })
    }
    if (!!disabled) {
        config.placeholder = styles => ({
            ...styles,
            color: '#cfd8dd',
            fontSize: '14px'
        })
    }
    return config
}