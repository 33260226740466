import React, {Component} from 'react';
import classnames from 'classnames';
import StartPageButton from "./StartPageButton";
import {withRouter} from 'react-router-dom';

class StartPageButtons extends Component {
    state = {activeButton: 'active-projects'};

    componentWillReceiveProps(nextProps) {
        this.handleProps(nextProps)
    }

    componentDidMount() {
        this.handleProps(this.props);
    }

    handleProps = props => {
        const {location} = props;
        const {pathname} = location;
        if (pathname.includes('wellfields')) {
            if (this.state.activeButton !== 'active-wellfields') {
                this.setState({
                    activeButton: 'active-wellfields'
                })
            }
        } else {
            if (this.state.activeButton !== 'active-projects') {
                this.setState({
                    activeButton: 'active-projects'
                })
            }
        }
    };
    onClickHandler = activeButton => {
        if (activeButton !== this.state.activeButton) {
            this.setState({
                activeButton
            }, () => {
                if (activeButton === 'active-projects') {
                    this.props.history.push('/projects')
                }
                if (activeButton === 'active-wellfields') {
                    this.props.history.push('/wellfields')
                }
            })
        }
    };

    render() {
        const {activeButton} = this.state;
        return <div className={classnames("topMenu__opts start-page", activeButton)}>
            <div className="start-page__bg"/>
            <StartPageButton
                onClickHandler={this.onClickHandler}
                type='active-projects'
                title='Проекты'
            />
            <StartPageButton
                title='Месторождения'
                type='active-wellfields'
                onClickHandler={this.onClickHandler}
            />
        </div>;
    }
}

export default withRouter(StartPageButtons);