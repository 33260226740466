import React, {useEffect, useState} from 'react';
import _get from 'lodash.get';
import {CartesianGrid, Tooltip, XAxis, YAxis} from "recharts";
import tickFormatterHandler from "./tickFormatterHandler";
import CustomizedTooltip from "./CustomizedTooltip";

export default ({data, type, unit}) => Component => {
    const [width, setWidth] = useState(0);
    const [plotData, setPlotData] = useState([]);
    useEffect(() => {
        setWidth(window.innerWidth - 474);
        const resizeHandler = ({target}) => {
            setWidth(target.window.innerWidth - 474);
        };
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, []);
    useEffect(() => {
        const dataProps = _get(data, ['props', 'profile', type]);
        let plotData = [];
        if (dataProps) {
            plotData = dataProps.values.map((el, index) => {
                return {
                    y: el,
                    x: dataProps.args[index]
                };
            })
        }
        setPlotData(plotData)
    }, [data, type]);
    const chartStyles = {
        top: 22,
        left: -21,
        right: 16,
        bottom: 3
    };
    const tickStyle = {
        fill: '#fff',
        fontSize: 10,
        stroke: '#fff',
        fontFamily: 'Lato'
    };
    return <Component height={265} width={width} plotData={plotData} chartStyles={chartStyles}>
        <CartesianGrid strokeWidth="1" stroke="#404A57"/>
        <Tooltip cursor={false} content={<CustomizedTooltip unit={unit}/>}/>
        <XAxis dataKey='x' tickLine={false} tick={tickStyle} tickFormatter={tickFormatterHandler}/>
        <YAxis tickLine={false} tick={tickStyle} tickFormatter={tickFormatterHandler}/>
    </Component>
};
