import React from 'react';
import classnames from 'classnames';

export default ({ toggleHandler, children, handler, currentHandler }) => {
  return <button
    onClick={() => toggleHandler(handler)}
    className={classnames('task-complete__toggle-button', {
      active: currentHandler === handler
    })}>
    {children}
  </button>
};