import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Select, {components} from 'react-select';
import classnames from 'classnames';
import setDem from './utils/setDem';
import colourStyles from './utils/colourStyles';
import CustomSelect from './CustomSelect';


const DataFields = ({
                        dem,
                        type,
                        name = '',
                        value,
                        error,
                        label,
                        content,
                        options,
                        required,
                        disabled,
                        placeholder,
                        onBlurHandler,
                        onChangeHandler,
                        classContainer = ''
                    }) => {
    const DropdownIndicator = props =>
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <div className="dropDownIndicator"/>
            </components.DropdownIndicator>
        );
    const Input = props =>
        components.Input && (
            <components.Input {...props} className="custom-input"/>
        );
    const setValueToSelect = val => {
        if (val === null) return null;
        if (val !== undefined && options) {
            for (let item in options) {
                if (options[item].value === val) {
                    return options[item]
                }
            }
        }
    };
    let err = error;
    if (error && required && (value === 0 || value)) {
        err = false
    }
    return (
        <>
            <div className={classnames('DataFieldsRow', classContainer, {error: err})}>
                {!!label && <label className="input-label">
                    {label}
                    {required && ' *'}
                </label>
                }
                {type === 'dropdowm' && <CustomSelect options={options}/>}
                {
                    type === 'option'
                        ? <div className={classnames("select-container", {disabled})}>
                            <div className="data-fields-error-line"/>
                            <Select
                                name={name}
                                value={setValueToSelect(value)}
                                components={{DropdownIndicator, Input}}
                                styles={colourStyles(disabled)}
                                placeholder={placeholder}
                                isDisabled={!!disabled}
                                menuPlacement="auto"
                                options={options}
                                onChange={e => onChangeHandler(e, type)}/>
                        </div>
                        : <>
                            <div className="DataFieldsContainer">
                                <div className="data-fields-error-line"/>
                                {!!(dem && value) &&
                                <div className="valueWithDem">
                                    <span className="valueWithDemValue">{value}</span>
                                    <span className="valueWithDemDem">{setDem(dem)}</span>
                                </div>
                                }
                                <input
                                    name={name}
                                    onBlur={onBlurHandler}
                                    disabled={!!disabled}
                                    className={classnames('DataFields', {required})}
                                    type={type === 'float_' ? 'number' : type}
                                    placeholder={placeholder}
                                    autoComplete='off'
                                    value={value === null ? '' : value}
                                    onChange={({target}) => onChangeHandler(target)}/>
                            </div>
                        </>
                }

            </div>
            {
                content &&
                <span className='create-task-row-content'>
                    {content}
                </span>
            }
        </>
    )
};

DataFields.propTypes = {
    dem: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    /** Options for select [{ value: '1', label: '1 }] */
    options: PropTypes.array,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChangeHandler: PropTypes.func.isRequired,
    classContainer: PropTypes.string
};

export default DataFields;
