import React from "react";
import setAuthToken from 'utils/setAuthToken'
import LogoutModalView from "./LogoutModalView";

const LogoutModalContainer = ({ setAuth, setShowModal, modalOption }) => {
    const logoutHandler = () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('settings');
        setAuthToken(false);
        setAuth('SUCCESS', false);
        setShowModal('SUCCESS', {type: '', option: null})
    };
    const closeHandler = () => {
        setShowModal('SUCCESS', {type: '', option: null})
    };
    return <LogoutModalView
        closeHandler={closeHandler}
        logoutHandler={logoutHandler}
        modalOption={modalOption} />
}

export default LogoutModalContainer;