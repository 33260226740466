import React from 'react';
import {withLeaflet} from "react-leaflet";
import {useSelector} from "react-redux";
import PopupContainer from './PopupContainer';
import {showObjectPopups} from 'core/state/selectors/settings';

const PopupRedux = props => {
    const show = useSelector(showObjectPopups);
    if (!show) {
        props.leaflet.popupContainer.unbindPopup();
        return null;
    }
    return <PopupContainer {...props}/>;
};

export default withLeaflet(PopupRedux);
