import React from 'react';
import { GeoJSON } from 'react-leaflet';
import classnames from 'classnames';
import Popup from '../../Popup';

export default ({ el, index, onClickHandler, openCardHandler }) => {
  return (
    <GeoJSON
      data={el}
      setZIndex='99999'
      onClick={onClickHandler}
      style={el.properties.style}
      key={
        el.properties.id + index + el.properties.type + el.properties.styleId
      }
      className={classnames('linear', [
        el.properties.id,
        el.properties.type,
        el.properties.styleId,
        el.properties.objectType,
        el.properties.networks_ids
          ? el.properties.networks_ids.map(network => `network-${network} `)
          : '',
      ])}
    >
      <Popup
        objectType='linear'
        properties={el.properties}
        openCardHandler={openCardHandler}
      />
    </GeoJSON>
  );
};
