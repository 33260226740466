import {connect} from 'react-redux';
import {lastActiveTab} from 'core/state/selectors/settings';
import {
    activeTab,
    setRemoveProps,
    openRemoveAlert,
    removeClickType,
    setTaskSettings,
    changeTaskSettings,
} from 'core/state/actions/settings';
import {getFacilitiesFromProjectByTaskType} from 'core/state/actions/projects'

import MainContainerBottomContainer from './MainContainerBottomContainer';

const mapStateToProps = state => ({
    lastActiveTab: lastActiveTab(state)
});

const mapDispathToProps = {
    activeTab,
    setRemoveProps,
    openRemoveAlert,
    setTaskSettings,
    removeClickType,
    changeTaskSettings,
    getFacilitiesFromProjectByTaskType
};

export default connect(mapStateToProps, mapDispathToProps)(MainContainerBottomContainer);
