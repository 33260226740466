import React from 'react';

import classnames from 'classnames';
import FieldsFactory from './FieldsFactory';

const MainContainer = ({type}) => {
    if (!type) return null;
    return (
        <div className={classnames('leftSideBar__mainContainer', type)}>
            <FieldsFactory type={type}/>
        </div>
    )
};

export default MainContainer;
