import React, { useState, useEffect } from 'react';
import ListWrapper from '../../MainContainer/common/ListWrapper';
import WithCommonObjectsFunc from 'components/HOC/WithCommonObjectsFunc';
import _get from 'lodash.get';

const AreasListContainer = props => {
  const [objects, setObjects] = useState({});
  useEffect(() => {
    const { sortedAreas } = props;
    if (sortedAreas) {
      setObjects(sortedAreas);
    }
  }, [props]);

  const hideAreas = ({ id }) => {
    props.hideAreas('SUCCESS', id);
  };
  const showList = id => {
    props.hideAreasGroup('SUCCESS', id);
  };

  const removeItem = ({ name, id, class_id }) => {
    const {
      setRemoveProps,
      openRemoveAlert,
      sortClassesByMetaclasses,
      sortMetaclassesByClasses,
    } = props;
    const { title: objectType } = _get(
      sortClassesByMetaclasses,
      sortMetaclassesByClasses[class_id]
    );
    setRemoveProps('SUCCESS', {
      id,
      name: name || 'Без названия',
      objectType,
      title: 'области',
      removeType: 'area',
      typeTitle: 'области',
      questionTitle: 'область',
    });
    openRemoveAlert('SUCCESS', true);
  };
  const removeMetaclass = id => {
    const { setRemoveProps, openRemoveAlert, sortClassesByMetaclasses } = props;
    const { title: objectType } = _get(sortClassesByMetaclasses, id);
    setRemoveProps('SUCCESS', {
      id,
      objectType,
      typeTitle: 'области',
      removeType: 'metaclass',
      title: 'группы областей',
      questionTitle: 'группу областей',
    });
    openRemoveAlert('SUCCESS', true);
  };
  const chooseRow = item => {
    props.setCardInfo('SUCCESS', {
      type: 'area',
      data: item,
    });
    props.activeTab('SUCCESS', 'areaInfoCard');
  };
  if (!props.sortedAreas)
    return <h2 className='preload-text'>Идёт загрузка...</h2>;
  if (!Object.keys(objects).length) {
    return <h2 className='preload-text'>Список областей пуст</h2>;
  }
  return (
    <ListWrapper
      type={props.type}
      showRow={hideAreas}
      showList={showList}
      chooseRow={chooseRow}
      updated={props.updated}
      removeItem={removeItem}
      objects={objects}
      removeMetaclass={removeMetaclass}
    />
  );
};

export default WithCommonObjectsFunc(AreasListContainer);
