import { connect } from 'react-redux';
import { getSortedFacilities } from 'core/state/selectors/objects';

import {
  activeTab,
  setCardInfo,
  setRemoveProps,
  openRemoveAlert,
} from 'core/state/actions/settings';

import {
  hideFacilities,
  hideFacilitiesGroup,
} from 'core/state/actions/groupsSettings';

import ObjectsListContainer from './ObjectsListContainer';

const mapStateToProps = state => ({
  sortedFacilities: getSortedFacilities(state),
});

const mapDispathToProps = {
  activeTab,
  setCardInfo,
  hideFacilities,
  setRemoveProps,
  openRemoveAlert,
  hideFacilitiesGroup,
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(ObjectsListContainer);
