export default (initialArr = [], payload) => {
    let targetArr;
    if (typeof payload === 'string') {
        targetArr = [payload]
    } else {
        targetArr = [...payload]
    }
    const arr = initialArr.slice();
    targetArr.forEach(elem => {
        const index = arr.indexOf(elem);
        if (index === -1) {
            arr.push(elem);
        } else {
            arr.splice(index, 1);
        }
    });
    return arr
};