import React from 'react'
import LeftMenu from '../LeftMenu';
import TaskResultsContainer from '../TaskResultsContainer';

const MainPageView = () => {
    return <div className="main-page">
        <LeftMenu/>
        <TaskResultsContainer/>
    </div>
};

export default MainPageView;
