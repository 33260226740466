import { connect } from 'react-redux';
import {
  taskType,
  activeTab,
  setRemoveProps,
  openRemoveAlert,
  setTaskFacilities,
} from 'core/state/actions/settings';
import {
  setTaskId,
  repeatTask,
  getTaskResult,
  setQuarriesIds,
  removeTaskLogs,
} from 'core/state/actions/projects';
import { taskInfo, taskLogs } from 'core/state/selectors/projects';
import { cardInfo } from 'core/state/selectors/settings';
import { classesByWellfield } from 'core/state/selectors/wellfield';

import InfoCardContainer from './InfoCardContainer';

const mapDispatchToProps = {
  taskType,
  activeTab,
  setTaskId,
  repeatTask,
  getTaskResult,
  removeTaskLogs,
  setQuarriesIds,
  setRemoveProps,
  openRemoveAlert,
  setTaskFacilities,
};

const mapStateToProps = state => ({
  cardInfo: cardInfo(state),
  taskInfo: taskInfo(state),
  taskLogs: taskLogs(state),
  classesByWellfield: classesByWellfield(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoCardContainer);
