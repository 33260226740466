import React, {Component} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {sortClassesByMetaclasses} from 'core/state/selectors/wellfield';
import {
    getHideAreas,
    getHideLinears,
    hideAreasGroup,
    getHidedFacilities,
    hideFacilitiesGroup,
    hideLinearsMetaclass,
    hideLinearsByMetaclassAndType,
    hideLinearsByMetaclassAndNetwork
} from 'core/state/selectors/groupsSettings';

import ReactTooltip from 'react-tooltip';

class ShowRow extends Component {
    state = {active: true};

    componentDidMount() {
        const {type, subLayerParent, subLayerKey} = this.props;
        if (type === 'areas') {
            const {item} = this.props;
            if (!subLayerParent && !subLayerKey) {
                if (typeof item === 'string') {
                    if (this.props.hideAreasGroup.includes(item)) {
                        this.setState({
                            active: false
                        });
                        this.elem.parentNode.parentNode.classList.toggle('hide-row')
                    }
                } else {
                    const {id} = this.props.item;
                    if (this.props.getHideAreas.includes(id)) {
                        this.setState({
                            active: false
                        })
                    }
                }
            }
        }
        if (type === 'objects') {
            const {item} = this.props;
            if (!subLayerParent && !subLayerKey) {
                if (typeof item === 'string') {
                    if (this.props.hideFacilitiesGroup.includes(item)) {
                        this.setState({
                            active: false
                        });
                        this.elem.parentNode.parentNode.classList.toggle('hide-row')
                    }
                } else {
                    const {id} = this.props.item;
                    if (this.props.getHidedFacilities.includes(id)) {
                        this.setState({
                            active: false
                        })
                    }
                }
            }
        }
        if (type === 'linear') {
            const {item} = this.props;
            if (!subLayerParent && !subLayerParent && subLayerKey) {
                const {hideLinearsByMetaclassAndType} = this.props;
                if (hideLinearsByMetaclassAndType[item]) {
                    if (hideLinearsByMetaclassAndType[item].types.includes(subLayerKey)) {
                        this.setState({
                            active: false
                        });
                        this.elem.parentNode.parentNode.classList.toggle('hide-row')
                    }
                }
            } else if (!subLayerParent && !subLayerKey) {
                if (typeof item === 'string') {
                    if (this.props.hideLinearsMetaclass.includes(item)) {
                        this.setState({
                            active: false
                        });
                        this.elem.parentNode.parentNode.classList.toggle('hide-row')
                    }
                } else {
                    const {id} = this.props.item;
                    if (this.props.getHideLinears.includes(id)) {
                        this.setState({
                            active: false
                        })
                    }
                }
            } else {
                const {hideLinearsByMetaclassAndNetwork} = this.props;
                if (hideLinearsByMetaclassAndNetwork[item]) {
                    if (hideLinearsByMetaclassAndNetwork[item].networks.includes(subLayerKey)) {
                        this.setState({
                            active: false
                        });
                        this.elem.parentNode.parentNode.classList.toggle('hide-row')
                    }
                }
            }
        }
    }

    static getDerivedStateFromProps() {
        ReactTooltip.rebuild();
        return null;
    }

    onClickHandler = () => {
        const {item, showRow} = this.props;
        this.elem.parentNode.parentNode.classList.toggle('hide-row');
        this.setState({
            active: !this.state.active
        }, () => {
            showRow(item)

        })
    };

    render() {
        const {active} = this.state;
        return (
            <span
                data-tip={active ? 'Скрыть' : 'Показать'}
                data-for='mainTooltip'
                onClick={this.onClickHandler}
                ref={node => this.elem = node}
                className={classnames('showRow', {hide: !active})}>
        <div className="field-row__showRow-icon"/>
            </span>
        )
    }
}

const mapStateToProps = state => ({
    getHideAreas: getHideAreas(state),
    getHideLinears: getHideLinears(state),
    hideAreasGroup: hideAreasGroup(state),
    getHidedFacilities: getHidedFacilities(state),
    hideFacilitiesGroup: hideFacilitiesGroup(state),
    hideLinearsMetaclass: hideLinearsMetaclass(state),
    hideLinearsByMetaclassAndType: hideLinearsByMetaclassAndType(state),
    hideLinearsByMetaclassAndNetwork: hideLinearsByMetaclassAndNetwork(state),
    sortClassesByMetaclasses: sortClassesByMetaclasses(state)
});

export default connect(mapStateToProps)(ShowRow);
