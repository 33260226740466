import React, {Component} from "react";

import classnames from 'classnames';

import LeftSidebar from 'components/LeftSidebar';

class MainContainerTopContainer extends Component {
    state = {};
    cancelHandler = () => {
        const {type} = this.props;
        let option = '';
        if (type === 'createArea') {
            option = 'areas';
        } else if (type === 'createFacility') {
            option = 'objects';
        } else if (type === 'createLinearObject') {
            option = 'linear';
        } else {
            option = 'tasks';
        }
        this.props.removeClickType('SUCCESS');
        this.props.changeTaskSettings('SUCCESS', {});
        this.setState({type: option}, () => this.optionHandler(option));
    };
    optionHandler = type => {
        this.props.activeTab('SUCCESS', type);
    };

    componentDidMount() {
        this.setTitle(this.props);
    };

    componentWillReceiveProps(nextProps) {
        this.setTitle(nextProps)
    };

    setTitle = props => {
        let title = '';
        let {type} = props;
        let oldType = '';
        switch (type) {
            case 'createFacility':
            case 'createLinearObject':
                title = 'Создание объекта';
                break;
            case 'createArea':
                title = 'Создание области';
                break;
            case 'taskInfo':
                const {taskInfo, taskLogs} = props;
                let taskIcon = '';
                if (taskLogs && taskInfo) {
                    const data = taskLogs[taskInfo.id];
                    if (data && data[0]) {
                        const {finished, has_errors} = data[0];
                        taskIcon = <span className={classnames('icon main-title__icon', {
                                done: finished && !has_errors,
                                process: !finished && !has_errors,
                                error: has_errors
                            }
                        )}/>
                    }
                }
                title = <span>{taskInfo.name} {taskIcon}</span>;
                break;
            default:
                title = 'Создание задачи';
                break;
        }
        this.setState({
            title,
            oldType
        })
    };

    render() {
        const {title} = this.state;
        return (
            <LeftSidebar.TopRow>
                <h2 className="main-title">{title}</h2>
                <div onClick={this.cancelHandler} className="new-project__close">
                    <div className="new-project__close-line close"/>
                </div>
            </LeftSidebar.TopRow>
        )
    }
}

export default MainContainerTopContainer;
