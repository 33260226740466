import React from 'react';
import { connect } from 'react-redux';
import { showModal as setShowModal } from 'core/state/actions/settings';
import { setAuth } from 'core/state/actions/auth'
import LogoutModalContainer from './LogoutModalContainer';
import { modalOption } from 'core/state/selectors/settings';

const LogoutModalRedux = props => {
    return <LogoutModalContainer {...props}/>;
};

const mapDipatchToProps = {
    setAuth,
    setShowModal
};

const mapStateToProps = state => ({
    modalOption: modalOption(state)
});

export default connect(mapStateToProps, mapDipatchToProps)(LogoutModalRedux);

