import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    getAdditionalProps,
    getDemsByWellfield,
    classesByMetaclasses,
    getMetaclassesByWellfield
} from 'core/state/selectors/wellfield';
import {getTaskSettings, lineCoords} from 'core/state/selectors/settings';
import {clickType, setCardInfo, setTaskSettings, changeTaskSettings} from 'core/state/actions/settings';
import _get from 'lodash.get';

import CheckBox from '../../TaskData/CheckBox';
import classnames from 'classnames';

import CommonField from '../../CommonField';
import groupBy from 'utils/groupBy';

class Linear extends Component {
    state = {
        checked: false,
        graph_edge_length_m: 100,
        options: {
            priority: [
                {value: 0, label: 'Низкий'},
                {value: 4, label: 'Средний'},
                {value: 9, label: 'Высокий'}
            ],
            classes: []
        },
        types: []
    };
    handlerProps = props => {
        const {
            dems,
            taskInfo,
            getTaskSettings,
            classesByMetaclasses,
            getMetaclassesByWellfield
        } = props;
        const {linear: linearMetaclasses} = groupBy(getMetaclassesByWellfield, 'type');
        const types = [];
        let {metaclass_id} = getTaskSettings;
        linearMetaclasses.forEach(({name: label, id: value, palette}) => {
            types[value] = {label, palette};
        });
        const relief = [];
        const classes = [];
        let data = {};
        if (taskInfo) {
            data = taskInfo;
            metaclass_id = taskInfo.metaclass_id;
        }
        let stateOptions = {
            ...data,
            metaclass_id,
            types,
            options: {
                ...this.state.options
            }
        };
        if (dems) {
            for (let item of dems) {
                relief.push({
                    value: item.id, label: item.type
                })
            }
            stateOptions = {
                ...stateOptions,
                options: {
                    ...stateOptions.options,
                    relief
                }
            }
        }
        if (classesByMetaclasses[metaclass_id]) {
            for (let item of classesByMetaclasses[metaclass_id]) {
                classes.push({
                    value: item.id,
                    label: item.name
                })
            }
            stateOptions = {
                ...stateOptions,
                options: {
                    ...stateOptions.options,
                    classes
                }
            };
        }
        this.setState(stateOptions)
    };

    componentWillUnmount() {
        this.props.setCardInfo('SUCCESS', {})
    }

    componentDidMount() {
        this.handlerProps(this.props);
        if (!this.props.info) {
            this.props.clickType('SUCCESS', ['createLinearObject'])
        }
    }

    componentWillReceiveProps(nextProps) {
        this.handlerProps(nextProps)
    }

    onChangeHandler = (value, type) => {
        this.setState(() => {
            return {[type]: value}
        });
        if (type === 'type') {
            const {palette} = this.state.types[value];
            this.props.changeTaskSettings('SUCCESS', {
                metaclass_id: value,
                palette
            });
            this.setState({
                class_id: ''
            });
        }
        if (type === 'class_id') {
            const obj = this.props.getAdditionalProps[value] || {};
            const stateObj = {};
            Object.keys(obj)
                .forEach(key => {
                    stateObj[obj[key].name] = obj[key].value
                });
            this.setState(() => {
                return {
                    ...stateObj
                }
            })
        }
    };

    render() {
        const {getTaskSettings} = this.props;
        const data = [
            {
                propName: 'name',
                placeholder: '',
                required: true,
                defaultValue: this.state.name,
                classContainer: 'wide',
                label: 'Название объекта',
                disabled: this.props.info
            },
            {
                propName: 'metaclass_id',
                type: 'option',
                placeholder: '',
                required: true,
                defaultValue: this.state.metaclass_id,
                classContainer: 'wide',
                label: 'Тип линейного объекта',
                onChangeHandler: value => this.onChangeHandler(value, 'type'),
                options: Object.keys(this.state.types)
                    .map(key => ({value: key, label: this.state.types[key].label})),
                disabled: this.props.info
            },
            {
                propName: 'class_id',
                type: 'option',
                placeholder: '',
                required: true,
                defaultValue: this.state.class_id,
                classContainer: 'wide',
                label: 'Класс',
                onChangeHandler: value => this.onChangeHandler(value, 'class_id'),
                options: this.state.options.classes,
                disabled: this.props.info
            }
        ];
        const calculateProps = [
            {
                propName: 'props.dem_id',
                type: 'option',
                placeholder: '',
                required: true,
                defaultValue: this.state.relief,
                classContainer: 'wide',
                label: 'Цифровая модель рельефа',
                options: this.state.options.relief,
                disabled: this.props.info
            },
            {
                propName: 'priority',
                type: 'option',
                placeholder: '',
                required: true,
                defaultValue: this.state.priority,
                classContainer: 'half',
                label: 'Приоритет',
                options: this.state.options.priority,
                disabled: this.props.info
            },
            {
                dem: 'м',
                propName: 'props.graph_edge_length_m',
                placeholder: '',
                required: true,
                defaultValue: this.state.graph_edge_length_m,
                classContainer: "graph-edge-length half graph-edge-length__linear",
                label: 'Шаг сетки',
                disabled: this.props.info
            }
        ];
        const addedprops = [];
        const {checked} = this.state;
        const {info, errors = [], getAdditionalProps} = this.props;
        let calculateRow = true;
        if (_get(getAdditionalProps, this.state.class_id)) {
            const obj = getAdditionalProps[this.state.class_id];
            Object.keys(obj)
                .forEach(key => {
                    addedprops.push({
                        propName: `props.${obj[key].name}`,
                        placeholder: '',
                        required: false,
                        defaultValue: this.state.props ? this.state.props[obj[key].name] : this.state[obj[key].name],
                        classContainer: 'half',
                        label: obj[key].description,
                        disabled: info
                    })
                });
        }
        if (_get(this.state.types, [this.state.metaclass_id, 'palette'])) {
            calculateRow = !['river', 'forest_corridor']
                .includes(_get(this.state.types, [this.state.metaclass_id, 'palette']));
        } else {
            calculateRow = false;
        }
        return (
            <div className="create-task-container">
                {
                    data
                        .filter(elem => !(info && elem.propName === 'name'))
                        .map((elem, key) => {
                            return <div key={key} className="create-task-row">
                                <CommonField
                                    addedProps={elem}
                                    error={errors.includes(elem.propName)}
                                />
                            </div>
                        })
                }
                {
                    addedprops.map((elem, key) => {
                        return <div key={key} className="create-task-row">
                            <CommonField
                                addedProps={elem}
                                error={errors.includes(elem.propName)}
                            />
                        </div>
                    })
                }
                {
                    (!info && calculateRow) && <div className="create-task-toggle-row">
                        <div className="create-task-toggle-row__title">
                            <CheckBox
                                id='213sx'
                                disabled={info}
                                propName='props.calculate'
                                label='Рассчитать стоимость'
                                onChange={value => this.onChangeHandler(value, 'checked')}
                            />
                        </div>
                        {
                            <div className={classnames('create-task-main-container', {
                                hide: !checked
                            })}>
                                <div className='create-task-toggle__line'/>
                                {calculateProps.map((addedProps, key) => {
                                    return <div key={key} className="create-task-row">
                                        <CommonField
                                            error={errors.includes(addedProps.propName)}
                                            addedProps={addedProps}
                                        />
                                    </div>
                                })}
                            </div>
                        }
                    </div>
                }
                {
                    !getTaskSettings.geom && !info && <div className="alert-row">
                        <h2 className="alert-row__title">Нарисуйте объект на карте</h2>
                    </div>
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    lineCoords: lineCoords(state),
    dems: getDemsByWellfield(state),
    getTaskSettings: getTaskSettings(state),
    getAdditionalProps: getAdditionalProps(state),
    classesByMetaclasses: classesByMetaclasses(state),
    getMetaclassesByWellfield: getMetaclassesByWellfield(state)
});

const mapDispatchToProps = {
    clickType,
    setCardInfo,
    setTaskSettings,
    changeTaskSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(Linear);
