export const taskConstants = {
  ROADS: 'roads',
  HVL: 'high_voltage',
  QUARRIES: 'quarries',
  LINEAR_COST: 'linear_cost',
  GATHERING_CENTERS: 'gathering_centers',
  FACILITY: 'facility_placement',
  PIPES: [
    'oil_pipe',
    'oil_gathering_line',
    'high_pressure_water_pipe',
    'low_pressure_water_pipe',
    'high_pressure_gas_pipe',
    'low_pressure_gas_pipe'
  ]
};
