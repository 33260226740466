import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    getClassesByMetaclass,
    classesByWellfield,
    getMetaclassesByWellfield
} from 'core/state/selectors/wellfield';
import {projectPalette} from "core/state/selectors/projects";
import {getTaskSettings} from 'core/state/selectors/settings';
import {changeTaskSettings} from 'core/state/actions/settings';
import CommonField from '../../CommonField';
import _get from 'lodash.get';

class CommonFields extends Component {
    state = {
        name: '',
        options: {
            classes: []
        },
        types: [
            {
                value: 'existing',
                label: 'Существующий'
            },
            {
                value: 'calculated',
                label: 'Рассчитанный'
            },
            {
                value: 'projected',
                label: 'Запроектированный'
            }
        ]
    };

    componentDidMount() {
        const {
            data,
            getTaskSettings,
            getClassesByMetaclass,
            classesByWellfield
        } = this.props;
        const class_id = getTaskSettings.class_id || data.class_id;
        let classes = [];
        if (getClassesByMetaclass) {
            for (let item of getClassesByMetaclass) {
                classes.push({
                    value: item.id,
                    label: item.name
                })
            }
        } else {
            classes = [
                {
                    value: _get(classesByWellfield, [class_id, 'id']),
                    label: _get(classesByWellfield, [class_id, 'name'])
                }
            ]
        }
        let object = {
            class_id,
            options: {
                classes
            }
        };
        if (data) {
            let currentObject = document.getElementsByClassName(`${data.id} for-hover`)[0];
            if (currentObject && !currentObject.classList.contains('active')) {
                currentObject.classList.add('active')
            }
            object = {
                ...object,
                ...data,
                lat: data.center_point.coordinates[1],
                lng: data.center_point.coordinates[0],
            }
        }
        this.setState(object)
    }

    onChangeHandler = value => {
        const palette = this.props.projectPalette[value];
        this.props.changeTaskSettings('SUCCESS', {
            class_id: value,
            palette
        })
    };

    componentWillReceiveProps(nextProps) {
        const {getTaskSettings: {center_point}} = nextProps;
        if(center_point) {
            const {coordinates} = center_point;
            this.setState({lat: coordinates[0], lng: coordinates[1]})
        }
    }

    render() {
        const {errors = []} = this.props;
        const data = [
            {
                propName: 'name',
                placeholder: '',
                required: true,
                defaultValue: this.state.name,
                classContainer: 'wide',
                label: 'Название объекта',
                disabled: this.props.info
            },
            {
                propName: 'class_id',
                type: 'option',
                placeholder: '',
                required: true,
                defaultValue: this.state.class_id,
                classContainer: 'wide',
                label: 'Класс',
                onChangeHandler: this.onChangeHandler,
                options: this.state.options.classes,
                disabled: this.props.info
            },
            {
                propName: 'type',
                type: 'option',
                placeholder: '',
                required: true,
                defaultValue: this.state.type,
                classContainer: 'wide',
                label: 'Тип',
                options: this.state.types,
                disabled: this.props.info
            },
            [
                {
                    propName: 'lat',
                    placeholder: '',
                    required: true,
                    defaultValue: this.state.lat,
                    classContainer: 'half',
                    label: '',
                    disabled: this.props.info
                },
                {
                    propName: 'lng',
                    placeholder: '',
                    required: true,
                    defaultValue: this.state.lng,
                    classContainer: 'half',
                    label: '',
                    disabled: this.props.info
                }
            ]
        ];
        return (
            <div className="create-task-container">
                {
                    data.map((addedProps, key) => {
                        if (addedProps instanceof Array) {
                            let defaultValue = '';
                            if (addedProps[0].defaultValue) {
                                defaultValue = `${addedProps[0].defaultValue.toFixed(2)}, ${addedProps[1].defaultValue.toFixed(2)}`;
                            }
                            return <div key={key} className="create-task-row">
                                <h2 className='input-label'>Координаты центра</h2>
                                <div>
                                    <CommonField
                                        addedProps={{
                                            ...addedProps[0],
                                            defaultValue
                                        }}
                                        error={errors.includes(addedProps.propName)}
                                    />
                                </div>
                            </div>
                        }
                        return <div key={key} className="create-task-row">
                            <CommonField
                                addedProps={addedProps}
                                error={errors.includes(addedProps.propName)}
                            />
                        </div>
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    getMetaclassesByWellfield: getMetaclassesByWellfield(state),
    getClassesByMetaclass: getClassesByMetaclass(state),
    classesByWellfield: classesByWellfield(state),
    getTaskSettings: getTaskSettings(state),
    projectPalette: projectPalette(state)
});

const mapDispatchToProps = {
    changeTaskSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonFields);