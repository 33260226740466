import React from 'react';
import { withLeaflet } from 'react-leaflet';
import _get from 'lodash.get';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';
import coloringObj from 'utils/coloringObj';
import '../connectionPointsStyle.scss';

class ConnectionPoints extends React.PureComponent {
  state = { points: null, show: false };
  handleProps = props => {
    const {
      connectionPoints,
      connectionTypes,
      sortClassesByMetaclasses,
      projectPalette,
    } = props;
    if (
      connectionPoints &&
      connectionTypes &&
      sortClassesByMetaclasses &&
      projectPalette
    ) {
      const points = connectionPoints.map(el => {
        return el.connection_points.map(item => {
          const classId = _get(sortClassesByMetaclasses, [
            connectionTypes[item.connection_type_id].linear_metaclass_id,
            'valuesArray',
            0,
          ]);
          return {
            ...item,
            type: el.type,
            palette: projectPalette[classId],
            connectionType: {
              ...connectionTypes[item.connection_type_id],
              classId,
            },
          };
        });
      });
      const arr = points.flat().filter(({ type }) => {
        return type !== 'calculated_tmp';
      });
      this.setState({ points: arr });
    }
  };
  showHandler = () => {
    if (this.props.leaflet.map.getZoom() > 12) {
      if (!this.state.show) {
        this.setState({ show: true });
      }
    } else if (this.state.show) {
      this.setState({ show: false });
    }
  };

  componentDidMount() {
    this.handleProps(this.props);
    this.showHandler();
    this.props.leaflet.map.on('zoomend', this.showHandler);
  }

  componentWillUnmount() {
    this.props.leaflet.map.off('zoomend', this.showHandler);
  }

  componentWillReceiveProps(nextProps) {
    this.handleProps(nextProps);
  }

  render() {
    const { points, show } = this.state;
    if (!points || !show || !points.length) return null;
    return (
      <>
        {points.map(el => {
          const [lng, lat] = el.geom.coordinates;
          const customIcon = divIcon({
            className: 'connection-point',
            iconSize: [6, 4],
            iconAnchor: [3, 2],
            html: `<div style="background-color: ${
              coloringObj(el.palette).color
            }" class="${el.palette}" />`,
          });
          return (
            <Marker
              key={el.id}
              icon={customIcon}
              position={{ lat, lng }}
              options={{
                type: el.connectionType.type,
                metaclassId: el.connectionType.linear_metaclass_id,
              }}
            />
          );
        })}
      </>
    );
  }
}

export default withLeaflet(ConnectionPoints);
