import React, {useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import MenuComponent from './MenuComponent';

export default props => {
    useEffect(() => ReactTooltip.rebuild(), []);
    const taskClickHandler = (type, pipeType) => props.handleClick(type, pipeType);
    const {type, pipesType, metaclassesByType, avaliableTaskTypes, handleClick} = props;
    return (
        <div className={`topMenuContainer ${type}`}>
            <MenuComponent
                type={type}
                pipesTypeArr={pipesType}
                handleClick={handleClick}
                taskClickHandler={taskClickHandler}
                metaclassesByType={metaclassesByType}
                avaliableTaskTypes={avaliableTaskTypes}
            />
        </div>
    )
};
