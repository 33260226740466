import React, { useState, useEffect } from 'react';
import WithIcons from 'components/HOC/WithIcons';
import coloringObj from 'utils/coloringObj';
import _get from 'lodash.get';
import center from '@turf/center';
import FacilityView from './FacilityView';

const FacilitiesContainer = ({
  showRuler,
  setIcon,
  singleObj,
  taskObjects,
  chooseSinks,
  taskQuarries,
  chooseSources,
  addTaskPoints,
  chooseQuarries,
  projectPalette,
  chooseFacility,
  chooseConsumers,
  changeTaskSettings,
  getVisibleFacilities,
  chooseSingleFacility,
  chooseGatheringCenters,
}) => {
  const [facilities, setFacilities] = useState([]);
  const onClickHandler = e => {
    let taskPoints;
    if (chooseSinks) {
      const id = _get(e, 'target.options.data.properties.id');
      if (singleObj) {
        changeTaskSettings('SUCCESS', {
          'props.sinks': [id],
        });
      } else {
        taskPoints = { sinks: id };
      }
    }
    if (chooseSources) {
      const id = _get(e, 'target.options.data.properties.id');
      if (singleObj) {
        changeTaskSettings('SUCCESS', {
          'props.sources': [id],
        });
      } else {
        taskPoints = { sources: id };
      }
    }
    if (chooseConsumers) {
      const id = _get(e, 'target.options.data.properties.id');
      if (singleObj) {
        changeTaskSettings('SUCCESS', {
          'props.consumers': [id],
        });
      } else {
        taskPoints = { consumers: id };
      }
    }
    if (chooseFacility) {
      const id = _get(e, 'target.options.data.properties.id');
      taskPoints = { facilities: id };
    }
    if (chooseGatheringCenters) {
      const id = _get(e, 'target.options.data.properties.id');
      taskPoints = { facilities_ids: id };
    }
    if (chooseSingleFacility) {
      const facility_id = _get(e, 'target.options.data.properties.id');
      changeTaskSettings('SUCCESS', {
        'props.facility_id': facility_id,
      });
    }
    if (chooseQuarries) {
      const { id, palette } = _get(e, 'target.options.data.properties');
      if (palette === 'quarry') {
        taskPoints = { quarries_ids: id };
      }
    }
    if (taskPoints) {
      addTaskPoints('SUCCESS', taskPoints);
    }
  };
  useEffect(() => {
    let arr = [];
    if (taskQuarries && taskObjects) {
      arr = getVisibleFacilities.filter(({ props }) => {
        if (props && props.network_ids) {
          return props.network_ids.some(el => taskObjects.data.includes(el));
        }
        return false;
      });
    } else {
      arr = getVisibleFacilities.filter(el => el.type !== 'calculated_tmp');
    }
    setFacilities(arr);
  }, [getVisibleFacilities, taskQuarries, taskObjects]);
  if (!facilities.length) return null;
  return (
    <>
      {facilities.map(
        ({ facility_area, class_id, id, name, className, type }, key) => {
          if (!facility_area) return null;
          const palette = projectPalette[class_id];
          const [lng, lat] = center(facility_area).geometry.coordinates;
          const icon = projectPalette ? setIcon(projectPalette[class_id]) : '';
          let style = coloringObj(_get(projectPalette, [class_id]));
          let tmpColor = '';
          const position = { lat, lng };
          const data = {
            ...facility_area,
            properties: { id, palette },
          };
          if (taskQuarries) {
            if (taskQuarries.indexOf(id) !== -1) {
              tmpColor = `tmpColor${taskQuarries.indexOf(id)}`;
              style = coloringObj(tmpColor);
            } else if (taskObjects) {
              style.color = '#77F4E8';
              style.fillColor = '#77F4E8';
            }
          }
          return (
            <FacilityView
              id={id}
              data={data}
              name={name}
              icon={icon}
              style={style}
              type={type}
              key={id || key}
              palette={palette}
              class_id={class_id}
              position={position}
              className={className}
              showRuler={showRuler}
              facility_area={facility_area}
              onClickHandler={onClickHandler}
            />
          );
        }
      )}
    </>
  );
};

export default WithIcons(FacilitiesContainer);
