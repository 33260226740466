import React from 'react';
import LinearCost from '../LinearCost';
import Profile from './Profile';
import Costs from './Costs';
import GraphContainer from './GraphContainer';

export default ({taskResult, currentHandler}) => {
    if (currentHandler === 'cost') {
        return <LinearCost taskResult={taskResult} currentHandler={currentHandler}/>
    }
    if (currentHandler === 'profile') {
        return GraphContainer({data: taskResult, type:'relief', unit: 'м'})(Profile);
    }
    if (currentHandler === 'distribution') {
        return GraphContainer({data: taskResult, type:'costs'})(Costs);
    }
    return null;
}
