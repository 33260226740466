import React from 'react';
import {connect} from 'react-redux';
import {getTaskSettings} from 'core/state/selectors/settings';

const BottomRow = ({taskFacilities, taskQuarries}) => {
    if(taskFacilities && taskQuarries) return null;
    return <div className="alert-row">
        <h2 className="alert-row__title"> Выберите на карте
            {!taskFacilities &&
            <>
                <span className="alert-row__title-bold"> «Объект»</span> для оптимизации
            </>
            }
            {(!taskFacilities && !taskQuarries) && <> и </>}
            {!taskQuarries &&
            <>
                <span className="alert-row__title-bold"> «Карьер(ы)»</span> для возки стройматерилов
            </>
            }
        </h2>
    </div>
};

const mapStateToProps = state => ({
    taskFacilities: getTaskSettings(state).props.facility_id,
    taskQuarries: getTaskSettings(state).props.quarries_ids
});

export default connect(mapStateToProps)(BottomRow);
