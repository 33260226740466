import React from 'react';
import {withLeaflet, Polygon} from "react-leaflet";
import createFacilityArea from './utils/createFacilityArea';
import Point from '../Points/Point';

class DrawMarker extends React.PureComponent {
    state = {
        position: null,
        markerArea: [],
    };
    clickHandler = e => {
        const {lat, lng} = e.latlng;
        const {width, length} = this.props;
        const markerArea = createFacilityArea(lng, lat, width, length, 0);
        if (typeof this.props.markerPositionHandler === 'function') {
            this.props.markerPositionHandler({position: e.latlng, markerArea});
        }
        this.setState({position: e.latlng, markerArea})
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.width && nextProps.length && this.state.position) {
            const {lat, lng} = this.state.position;
            const {width, length} = nextProps;
            const markerArea = createFacilityArea(lng, lat, width, length, 0);
            this.setState({markerArea})
        }
    }

    componentWillUnmount() {
        if (typeof this.props.markerPositionHandler === 'function' && this.state.position) {
            this.props.markerPositionHandler({
                position: this.state.position,
                markerArea: this.state.markerArea
            });
        }
        this.props.leaflet.map.off('click', this.clickHandler);
    };

    componentDidMount() {
        this.props.leaflet.map.on('click', this.clickHandler);
    };

    render() {
        const {position, markerArea} = this.state;
        const {markerProps = {}, polygonStyle} = this.props;
        if (!position) return null;
        const {color, fillColor, fillOpacity} = polygonStyle;
        return <>
            <Point
                {...markerProps}
                transform={true}
                draggable={false}
                zIndexOffset={9999}
                position={position}
            />
            {
                markerArea.length && <Polygon
                    transform={true}
                    positions={markerArea}
                    color={color}
                    fillColor={fillColor}
                    fillOpacity={fillOpacity}
                />
            }
        </>
    };
}

export default withLeaflet(DrawMarker);
