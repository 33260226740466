import React from 'react';

import TopRow from './TopRow';
import Factory from './Factory';
import ScrollBar from 'components/Common/ScrollBar';

const List = props => {
  const Component = Factory(props);
  if (!Component) return null;
  return (
    <>
      <TopRow type={props.type} />
      <ScrollBar>
        <Component />
      </ScrollBar>
    </>
  );
};

export default List;
