import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {getActiveTab} from 'core/state/selectors/settings';
import classnames from 'classnames';

import Handlers from './Handlers';
import MainContainer from './MainContainer';

const ExpandedMenu = React.memo(() => {
    const type = useSelector(getActiveTab, shallowEqual);
    return <div className={classnames("left-sidebar__bottom", {full: !!type}, type)}>
        <Handlers/>
        <MainContainer type={type}/>
    </div>
});

export default ExpandedMenu;
