import React, { Component } from 'react'
import { showLoader } from 'core/state/selectors/settings'
import { connect } from 'react-redux'

class Spinner extends Component {
  render() {
    if (!this.props.showLoader) return null
    return <div className="main-page__spinner">
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
  }
}

export default connect(state => ({ showLoader: showLoader(state) }))(Spinner);