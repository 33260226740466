import {useEffect} from 'react';
import {linearId} from "core/state/selectors/settings";
import {useSelector} from "react-redux";

export default () => {
    const linear = useSelector(linearId);
    useEffect(() => {
        const removeActiveClasses = () => {
            document
                .querySelectorAll('.linear.active')
                .forEach(el => el.classList.remove('active'));
        };
        if (linear) {
            const elem = document.getElementsByClassName(linear)[0];
            if (elem) {
                if (!elem.classList.contains('active')) {
                    removeActiveClasses();
                    elem.classList.add('active');
                }
            }
        } else {
            removeActiveClasses();
        }
    }, [linear])
};
