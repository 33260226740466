import {connect} from 'react-redux';
import {wellfields} from 'core/state/selectors/common';
import {getProjectName, getProjectId, wellfieldId} from 'core/state/selectors/projects';
import {clearState, downloadProject} from 'core/state/actions/projects';
import {setSuccessLogs} from 'core/state/actions/common';
import {setRemoveProps, openRemoveAlert, showModal} from 'core/state/actions/settings';
import { setMapType } from 'core/state/actions/map';
import TopMenuContainer from './TopMenuContainer';

const mapStateToProps = state => ({
    wellfields: wellfields(state),
    projectId: getProjectId(state),
    wellfieldId: wellfieldId(state),
    projectName: getProjectName(state)
});

const mapDispatchToProps = {
    showModal,
    clearState,
    setSuccessLogs,
    setRemoveProps,
    openRemoveAlert,
    downloadProject,
    setMapType
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuContainer);
