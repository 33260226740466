import React from "react";
import Button from 'components/Common/Button'

const LogoutModalView = ({ closeHandler, logoutHandler, modalOption }) => (
    <React.Fragment>
        <div className="splash-screen" />
        <div className='remove-alert card'>
            <div className="main-container__top-row">
                <h2 className="main-title">
                    {modalOption.title}
                </h2>
                <div className="new-project__close" onClick={closeHandler}>
                    <div className="new-project__close-line close"/>
                </div>
            </div>
            <div className="card-main">
                <h2 className="remove-alert__question-title">
                    {modalOption.questionTitle}{modalOption.questionTitle ? '?' : ''}
                </h2>
            </div>
            <div className="card-bottom">
                <Button
                    onClick={logoutHandler}
                    className="remove-alert__button">
                    {'Да'}
                </Button>
                <Button
                    secondary
                    onClick={closeHandler}>
                    {'Нет'}
                </Button>
            </div>
        </div>
    </React.Fragment>
);

export default LogoutModalView;