import {connect} from 'react-redux';
import {getClickType} from 'core/state/selectors/settings';
import {getClassesByMetaclass} from 'core/state/actions/wellfield';
import {
    clickType,
    activeTab,
    setTaskSettings,
    setDataForSending,
    changeTaskSettings,
    setTransformSettings
} from 'core/state/actions/settings';
import {removeHiddenMetaclass} from 'core/state/actions/groupsSettings';
import {additionalTaskProps} from "core/state/selectors/wellfield";
import {getActiveTab} from "core/state/selectors/settings";
import {avaliableTaskTypes, metaclassesByTypeWithPalette} from 'core/state/selectors/projects';
import {showModal as setShowModal} from 'core/state/actions/settings';
import TopMenuContainer from './TopMenuContainer';

const mapStateToProps = state => ({
    getClickType: getClickType(state),
    getActiveTab: getActiveTab(state),
    avaliableTaskTypes: avaliableTaskTypes(state),
    additionalTaskProps: additionalTaskProps(state),
    metaclassesByTypeWithPalette: metaclassesByTypeWithPalette(state),
});

const mapDispatchToProps = {
    clickType,
    activeTab,
    setShowModal,
    setTaskSettings,
    setDataForSending,
    changeTaskSettings,
    setTransformSettings,
    getClassesByMetaclass,
    removeHiddenMetaclass
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuContainer);
