import React from 'react';
import {useSelector} from 'react-redux';
import {showResults} from 'core/state/selectors/settings';
import './style.scss';

import MainContainer from './MainContainer';

const TaskResultsContainer = React.memo(() => {
    const show = useSelector(showResults);
    if (!show) return null;
    return <MainContainer/>
});

export default TaskResultsContainer;
