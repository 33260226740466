import React from 'react';
import {facilityIds} from 'core/state/selectors/settings';
import {useSelector} from "react-redux";

export default ({info}) => {
    const getFacilities = useSelector(facilityIds);
    if(info || getFacilities) return null;
    return <div className="alert-row">
        <h2 className="alert-row__title">
            Выберите <span className='bold'>«Кустовые площадки»</span> с заполненными профилями добычи
        </h2>
    </div>
};
