import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SubLayer from './SubLayer'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

class List extends Component {
  onMouseEnterHandler = item => {
    if (typeof this.props.onMouseEnter === 'function') {
      return this.props.onMouseEnter(item)
    }
  };
  onMouseLeaveHandler = item => {
    if (typeof this.props.onMouseLeave === 'function') {
      return this.props.onMouseLeave(item)
    }
  };
  componentDidMount() {
    ReactTooltip.rebuild()
  };
  render() {
    const {
      data,
      active,
      rowClass,
      subLayer,
      afterComp,
      chooseRow,
      beforeComp,
      titleProps,
      beforeProp,
      activeText = '',
      onMouseEnter,
      onMouseLeave,
      containerClass,
      subLayerParent } = this.props;
    if (subLayer) {
      return Object.keys(subLayer).map((key, id) => {
        const listProps = {
          active,
          chooseRow,
          afterComp,
          beforeComp,
          onMouseEnter,
          onMouseLeave,
          beforeProp: subLayer[key].beforeProp,
          containerClass: containerClass + ` ${subLayer[key].containerClass || 'sublayer'}`
        };
        const titlePropsConfig = {
          onMouseEnter,
          onMouseLeave,
          itemKey: key,
          title: subLayer[key].title,
          afterComp: titleProps.afterComp,
          lastItemKey: titleProps.itemKey,
          titleClass: titleProps.titleClass,
          beforeProp: subLayer[key].beforeProp,
          containerClass: [titleProps.containerClass, subLayer[key].containerClass],
          afterCompFunc: [() => titleProps.subAfterCompFunc(key, subLayerParent), subLayer[key]],
          beforeComp: bgColor => titleProps.beforeComp(bgColor, key, subLayerParent)
        };
        if (subLayer[key].subLayer) {
          listProps['titleProps'] = titleProps;
          listProps['subLayerParent'] = key;
          listProps['subLayer'] = subLayer[key].subLayer;
          listProps['withoutGroup'] = subLayer[key].values;
        }
        else {
          listProps['data'] = subLayer[key].values;
        }
        return <SubLayer key={id}
          listProps={listProps}
          titleProps={titlePropsConfig} />
      })
    }
    if (!data) return null
    return data.map((item, key) => {
      let toggleText = item.name;
      if (activeText) {
        const re = new RegExp(activeText, 'ig');
        const arr = item.name.split(re);
        let regex = new RegExp(activeText, 'gi'), result, indices = [];
        while ((result = regex.exec(item.name))) {
          indices.push(result.index);
        }
        toggleText = arr.map((text, key) => {
          return <span key={key}>
            {text}{
              key !== arr.length - 1 &&
              <span className="row-toggle__text-active">
                {
                  item.name.slice(indices[key], indices[key] + activeText.length)
                }
              </span>
            }
          </span>
        });
      }
      return <li
        key={key}
        onMouseEnter={() => this.onMouseEnterHandler(item)}
        onMouseLeave={() => this.onMouseLeaveHandler(item)}
        className={classnames('row-toggle', { active: active(item) }, containerClass)}
      >
        {beforeComp && beforeComp(item, beforeProp)}
        <span className={classnames("row-toggle__text" , rowClass)} onClick={() => chooseRow(item)}>
          {toggleText}
        </span>
        {afterComp && afterComp(item)}
      </li>
    })
  }
}

List.propTypes = {
  data: PropTypes.array,
  active: PropTypes.func,
  subLayer: PropTypes.object,
  afterComp: PropTypes.func,
  chooseRow: PropTypes.func,
  beforeComp: PropTypes.func,
  titleProps: PropTypes.object,
  beforeProp: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  containerClass: PropTypes.string,
  subLayerParent: PropTypes.string
};

export default List;