import React from 'react';

import CommonField from '../../CommonField';

export default function PipeFields({disabled, propsNames, defaultValues, units}) {
    return (
        <>
            {Object.keys(defaultValues)
                .filter(key => propsNames[key])
                .map((key, id) => (
                        <div key={id} className="create-task-row">
                            <CommonField
                                addedProps={{
                                    dem: units[key] || '',
                                    propName: `props.${key}`,
                                    placeholder: '',
                                    type: 'number',
                                    classContainer: 'half nowrap-label',
                                    defaultValue: defaultValues[key],
                                    label: propsNames[key],
                                    disabled
                                }}
                            />
                        </div>
                    )
                )}
        </>
    )
};
