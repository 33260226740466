const priority = [
    {value: 0, label: 'Низкий'},
    {value: 4, label: 'Средний'},
    {value: 9, label: 'Высокий'}
];

const data = [
    {
        propName: 'name',
        placeholder: '',
        required: true,
        classContainer: 'wide',
        label: 'Название задачи',
    },
    {
        propName: 'priority',
        type: 'option',
        placeholder: '',
        required: true,
        classContainer: 'half',
        label: 'Приоритет',
        defaultValue: 4,
        options: priority,
    },
    {
        propName: 'props.gc_count',
        placeholder: '',
        type: 'number',
        required: true,
        content: '≤ кол-ва кустовых площадок',
        classContainer: 'half',
        label: 'Количество центров сбора',
    },
    'dem'
];

export default data;
