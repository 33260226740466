import {connect} from 'react-redux';
import {
    activeTab,
    createTask,
    createObject,
    removeClickType
} from 'core/state/actions/settings';
import {getProjectId} from 'core/state/selectors/projects';
import {getTaskSettings, getActiveTab} from 'core/state/selectors/settings';
import {removeTask, sendFacility, getFacilitiesFromProjectByTaskType} from 'core/state/actions/projects';

import CreateCardContainer from './CreateCardContainer';

const mapStateToProps = state => ({
    type: getActiveTab(state),
    project_id: getProjectId(state),
    getTaskSettings: getTaskSettings(state),
    selectedObjects: getTaskSettings(state).props
});

const mapDispathToProps = {
    activeTab,
    removeTask,
    createTask,
    sendFacility,
    createObject,
    removeClickType,
    getFacilitiesFromProjectByTaskType
};

export default connect(mapStateToProps, mapDispathToProps)(CreateCardContainer);
