import React, { Component } from 'react'
import { connect } from 'react-redux'

import dayjs from 'dayjs'

import { taskLogs, taskId } from 'core/state/selectors/projects'

import Log from './Log'

class TaskLogs extends Component {
  state = { taskLogsValues: [] }
  handleProps = props => {
    const { taskLogs, taskId } = props;
    let taskLogsValues = []
    if (taskLogs && taskId && taskLogs[taskId]) {
      taskLogs[taskId].forEach(item => {
        const { percentage, id, has_errors, message, _updated, finished, debug_info, task_id } = item
        taskLogsValues.push({
          id,
          task_id,
          message,
          finished,
          percentage,
          has_errors,
          debug_info,
          date: dayjs(_updated).format('YYYY-MM-DD H:mm:ss')
        })
      })
      this.setState({
        taskLogsValues
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    this.handleProps(nextProps)
  }
  componentDidMount() {
    this.handleProps(this.props)
  }
  render() {
    const { taskLogsValues } = this.state
    if (!taskLogsValues.length) return null
    return <div className="task-complete__logs">
      {taskLogsValues
        .map(({ id, ...props }) => <Log key={id} {...props} />)}
    </div>
  }
}


const mapStateToProps = state => ({
  taskId: taskId(state),
  taskLogs: taskLogs(state)
});

export default connect(mapStateToProps)(TaskLogs)