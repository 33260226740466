import React from 'react';
import { connect } from 'react-redux';
import {
  projectPalette,
  taskQuarries,
  taskObjects,
} from 'core/state/selectors/projects';
import { getVisibleFacilities } from 'core/state/selectors/objects';
import {
  showRuler,
  singleObj,
  chooseSinks,
  chooseSources,
  chooseQuarries,
  chooseFacility,
  chooseConsumers,
  chooseSingleFacility,
  chooseGatheringCenters,
} from 'core/state/selectors/settings';
import { setQuarriesIds } from 'core/state/actions/projects';
import {
  addTaskPoints,
  changeTaskSettings,
  setActiveFacilities,
} from 'core/state/actions/settings';

import FacilitiesContainer from './FacilitiesContainer';

const FacilitiesRedux = ({
  projectPalette,
  getVisibleFacilities,
  facilitiesFromProjectByTaskType,
  chooseQuarries,
  ...props
}) => {
  if (!getVisibleFacilities.length || !Object.keys(projectPalette).length)
    return null;
  return (
    <FacilitiesContainer
      {...props}
      chooseQuarries={chooseQuarries}
      projectPalette={projectPalette}
      getVisibleFacilities={getVisibleFacilities}
    />
  );
};

const mapDispatchToProps = {
  addTaskPoints,
  setQuarriesIds,
  changeTaskSettings,
  setActiveFacilities,
};

const mapStateToProps = state => ({
  showRuler: showRuler(state),
  singleObj: singleObj(state),
  taskObjects: taskObjects(state),
  chooseSinks: chooseSinks(state),
  taskQuarries: taskQuarries(state),
  chooseSources: chooseSources(state),
  chooseFacility: chooseFacility(state),
  chooseQuarries: chooseQuarries(state),
  projectPalette: projectPalette(state),
  chooseConsumers: chooseConsumers(state),
  chooseSingleFacility: chooseSingleFacility(state),
  getVisibleFacilities: getVisibleFacilities(state),
  chooseGatheringCenters: chooseGatheringCenters(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilitiesRedux);
