import React, {Component} from 'react';
import TaskData from '../TaskData';
import ScrollBar from 'components/Common/ScrollBar';
import MainContainerTop from './MainContainerTop';
import MainContainerBottom from './MainContainerBottom';
import Validation from '../Validation';
import {taskConstants} from 'utils/constants';
import ObjectsValidation from '../Validation/ObjectsValidation';
import ObjectsCard from '../ObjectsCard';
import _set from 'lodash.set';

const {PIPES, ...taskTypes} = taskConstants;

const checkSelectedObjects = (objects, type) => {
    for (let key of ObjectsValidation(type)) {
        if (!objects[key]) return false;
        else if (objects[key] instanceof Array) {
            if (!objects[key].length) return false
        }
    }
    return true
};

class CreateCardContainer extends Component {
    componentWillUnmount() {
        this.props.getFacilitiesFromProjectByTaskType('SUCCESS', false);
        this.props.removeClickType('SUCCESS');
    }

    sendNewFacilities = object => {
        const {getTaskSettings, sendFacility, project_id} = this.props;
        const {center_point, facility_area} = getTaskSettings;
        const currentObject = Object.assign({}, object);
        delete currentObject['metaClassId'];
        delete currentObject['lng'];
        delete currentObject['lat'];
        currentObject.props = {
            ...currentObject.props,
            start_date: null,
            finish_date: null
        };
        center_point.coordinates = center_point.coordinates.reverse();
        sendFacility('REQUEST', {
            ...currentObject,
            project_id,
            center_point,
            facility_area
        });
        this.props.removeClickType('SUCCESS');
    };

    createArea = taskProps => {
        const {getTaskSettings, project_id, createObject, type} = this.props;
        const {geom} = getTaskSettings;
        const {class_id, name, metaclass_id} = taskProps;
        const data = {name, geom, class_id, project_id, metaclass_id};
        createObject('REQUEST', {type, data});
        this.props.activeTab('SUCCESS', 'areas');
        this.props.removeClickType('SUCCESS');
    };
    createLinearObject = taskProps => {
        const {getTaskSettings, createObject, project_id, createTask} = this.props;
        const {geom} = getTaskSettings;
        if (geom) {
            const {type, ...data} = taskProps;
            createObject('REQUEST', {
                type: type,
                data: {
                    ...data,
                    geom,
                    type: 'existing',
                    project_id,
                    props: {
                        ...data.props,
                        finish_date: null,
                        start_date: null
                    }
                }
            });
            this.props.activeTab('SUCCESS', 'linear');
            createTask('SUCCESS', false);
            this.props.removeClickType('SUCCESS');
        }
    };
    createTask = taskProps => {
        const {type, selectedObjects, createTask} = this.props;
        taskProps.props = {...taskProps.props, ...selectedObjects};
        const checkObjects = checkSelectedObjects(selectedObjects, type);
        if (checkObjects) {
            createTask('SUCCESS', false);
            createTask('REQUEST', {
                type: taskProps.type,
                data: taskProps
            });
            this.props.activeTab('SUCCESS', 'tasks');
            this.props.removeClickType('SUCCESS');
        }
    };
    handleSubmit = ({elements}) => {
        const {type, project_id} = this.props;
        const taskProps = {
            type, props: {project_id}
        };
        Array.from(elements).forEach(elem => {
            if (elem.name && elem.nodeName === 'INPUT') {
                const {name, value, type} = elem;
                let currentValue = '';
                if (['priority', 'props.amount_of_solutions'].includes(name) || type === 'number') {
                    currentValue = parseFloat(value || 0)
                } else if (elem.type === 'checkbox') {
                    currentValue = elem.checked
                } else {
                    currentValue = value
                }
                _set(taskProps, name, currentValue)
            }
        });
        if ([...Object.values(taskTypes), ...PIPES].includes(type)) {
            if (taskProps.type === "roads") {
                if (!taskProps.props.use_roads_params) {
                    taskProps.props.height_angle = 0;
                    taskProps.props.minimal_radius = 0;
                }
            }
            this.createTask(taskProps);
        }
        if (type === 'createFacility') {
            this.sendNewFacilities(taskProps);
        }
        if (type === 'createArea') {
            this.createArea(taskProps);
        }
        if (['createLinearObject'].includes(type)) {
            this.createLinearObject(taskProps);
        }
    };

    render() {
        const {type} = this.props;
        return (
            <Validation type={type} handleSubmit={this.handleSubmit}>
                <MainContainerTop/>
                <ScrollBar>
                    {(() => {
                        if ([...PIPES, ...Object.values(taskTypes)].includes(type)) {
                            return <TaskData type={type}/>
                        } else {
                            return <ObjectsCard type={type}/>
                        }
                    })()}
                </ScrollBar>
                <MainContainerBottom/>
            </Validation>
        )
    }
}

export default CreateCardContainer;
