import React, {Component} from 'react'

import LinearResultsComponent from './LinearResultsComponent'

class LinearResults extends Component {
    state = {networks: [], resProps: false};

    componentDidMount() {
        this.setPropsToState(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.setPropsToState(nextProps);
        this.props.updated();
    }

    componentWillUnmount() {
        this.removeHideClass(this.state.networks)
    }

    removeHideClass = networks => {
        networks.forEach(key => {
            const elems = document.getElementsByClassName(`network-${key}`)
            Array.from(elems).forEach(elem => {
                if (elem.classList.contains('hide')) {
                    elem.classList.remove('hide')
                }
            })
        })
    };
    setPropsToState = props => {
        const {selectedResult, taskResult} = props;
        const networks = [];
        let resProps = {};
        taskResult.forEach(({props}) => {
            networks.push(props.network_id)
            if (props.network_id === selectedResult.value) {
                resProps = props
            }
        });
        this.removeHideClass(networks)
        networks.forEach(key => {
            if (key !== selectedResult.value) {
                const elems = document.getElementsByClassName(`network-${key}`)
                Array.from(elems).forEach(elem => {
                    if (!elem.classList.contains('hide')) {
                        elem.classList.add('hide')
                    }
                })
            }
        });
        this.setState({
            networks,
            resProps
        })
    };

    render() {
        const {resProps} = this.state;
        if (!resProps) return null;
        return <LinearResultsComponent resProps={resProps}/>
    }
}

export default LinearResults;