export default {
    classesByWellfieldUrl: id => `/api/wellfields/${id}/classes`,
    classPropsUrl: id => `/api/wellfields/${id}/class_properties`,
    removeWellfield: wellfieldId => `/api/wellfields/${wellfieldId}`,
    getLayouts: wellfield_id => `api/wellfields/${wellfield_id}/layouts`,
    getLayoutFacility: layout_id => `api/layouts/${layout_id}/facilities`,
    metaclassPropsUrl: id => `/api/wellfields/${id}/metaclass_properties`,
    facilitiesByLayout: layout_id => `api/layouts/${layout_id}/facilities`,
    downloadWellfield: wellfieldId => `/api/wellfields/${wellfieldId}/dump`,
    layoutsByWellfield: wellfield_id => `api/wellfields/${wellfield_id}/layouts`,
    connectionTypes: wellfield_id => `/api/wellfields/${wellfield_id}/connection_types`,
    demsByWellfield: id => `/api/dems?where={"wellfield_id":"${id}"}&max_results=100500`,
    classesByMetaclassUrl: id => `/api/classes?where={"metaclass": "${id}"}&sort=name&max_results=100500`,
    metaclassesByWellfieldUrl: id => `/api/metaclasses?where={"wellfield_id":"${id}"}&max_results=100500`,
    task_properties: wellfield_id => `/api/wellfields/${wellfield_id}/task_properties?max_results=100500`,
    addtitionalTaskProps: wellfield_id => `api/wellfields/${wellfield_id}/calculated_linear_metaclasses?max_results=100500`
}