import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ProjectItem = ({ date, item, index, remove, download, wellfield, handleClick, wellfieldCond }) => {
  return <li className="projects__item" >
    <div className="projects__item-bg"></div>
    <div className="projects__col-row" onClick={() => handleClick(index)}>
      {!wellfieldCond && <div className="projects__col-img"></div>}
      <div className={classnames("projects__col-desc", {
        wellfield: wellfieldCond
      })}>
        <h2 className="projects__col-desc-title">
          <span className="projects__col-desc-title-text">
            {item.name}
          </span>
        </h2>
        {
          !wellfieldCond && <h3 className="projects__col-desc-subtitle">{wellfield}</h3>
        }
        <h3 className="projects__col-date">
          <span>
            {
              wellfieldCond ?
                'Дата создания:' :
                'был открыт:'
            } {date}
          </span>
          {
            wellfieldCond && <span className="projects-count">Проектов: {item.projects_ids.length}</span>
          }
        </h3>
      </div>
    </div>
    <span className="wellfield-icons">
      <span onClick={() => download(item)} className="icon download-icon"></span>
      <span onClick={() => remove(item)} className="icon remove-icon remove-icon_wellfield"></span>
    </span>
  </li >;
};



ProjectItem.propTypes = {
  item: PropTypes.object.isRequired,
  wellField: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  date: PropTypes.string,
  index: PropTypes.object.isRequired
};

export default ProjectItem;