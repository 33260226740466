import React, { Component } from 'react';
import { connect } from 'react-redux';
import { taskStarted } from 'core/state/actions/settings';
import { activeTasks } from 'core/state/selectors/settings';
import {
  getTasksById,
  taskLogs,
  getProjectId,
} from 'core/state/selectors/projects';
import {
  getTasksLogs,
  getCalculatedObjects,
} from 'core/state/actions/projects';
import {
  setInfoLogs,
  setSuccessLogs,
  setError,
} from 'core/state/actions/common';
import _isEqual from 'lodash.isequal';
import WebSocketComponent from '../WebSocketContainer';

class TasksSocket extends Component {
  state = {};

  componentDidMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleProps(nextProps);
  }

  handleProps = (props, prevProps = {}) => {
    const { getTasksById, taskLogs, activeTasks } = props;
    if (getTasksById) {
      if (_isEqual(taskLogs, prevProps.taskLogs) && activeTasks) {
        Object.keys(taskLogs).forEach(key => {
          if (activeTasks.includes(key)) {
            if (taskLogs[key]) {
              const data = taskLogs[key];
              this.props.setInfoLogs(
                'SUCCESS',
                `${data[Object.keys(data).pop()].message}`
              );
              this.props.taskStarted('SUCCESS', key);
            }
          }
        });
      }
      if (taskLogs && this.props.taskLogs) {
        Object.keys(taskLogs).forEach(key => {
          if (this.props.taskLogs[key]) {
            for (let index = 0; index < taskLogs[key].length; index++) {
              const item = taskLogs[key][index];
              const oldProps = this.props.taskLogs[key][index];
              if (oldProps && item.has_errors !== oldProps.has_errors) {
                this.props.setError('SUCCESS', `${item.message}`);
                break;
              }
            }
            if (
              this.props.taskLogs[key][0].finished !== taskLogs[key][0].finished
            ) {
              if (!taskLogs[key][0].has_errors) {
                this.props.getCalculatedObjects(
                  'REQUEST',
                  taskLogs[key][0].task_id
                );
                this.props.setSuccessLogs(
                  'SUCCESS',
                  `${taskLogs[key][0].message}`
                );
              }
            }
          }
        });
      }
    }
  };

  onMessageHandler = data => {
    if (data) {
      this.props.getTasksLogs('SUCCESS', data);
    }
  };

  render() {
    const { getProjectId: projectId } = this.props;
    if (!projectId) return null;
    return (
      <WebSocketComponent
        onMessageHandler={this.onMessageHandler}
        url={`/projects/${projectId}/task_traces`}
      />
    );
  }
}

const mapStateToProps = state => ({
  taskLogs: taskLogs(state),
  activeTasks: activeTasks(state),
  getProjectId: getProjectId(state),
  getTasksById: getTasksById(state),
});

const mapDispatchToProps = {
  getCalculatedObjects,
  getTasksLogs,
  taskStarted,
  setSuccessLogs,
  setInfoLogs,
  setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksSocket);
