import React from 'react';
import { connect } from 'react-redux';
import { chooseLinearObject } from 'core/state/selectors/settings';
import { linearTaskObjects, taskQuarries } from 'core/state/selectors/projects';
import { getVisibleLinears } from 'core/state/selectors/objects';
import {
  changeTaskSettings,
  setCardInfo,
  activeTab,
} from 'core/state/actions/settings';
import { projectPalette } from 'core/state/selectors/projects';

import LinearContainer from './LinearContainer';

const LinearRedux = ({
  activeTab,
  setCardInfo,
  linearTaskObjects,
  changeTaskSettings,
  taskQuarries,
  getVisibleLinears,
  projectPalette,
  chooseLinearObject,
}) => {
  if (!(Object.keys(getVisibleLinears).length || !taskQuarries)) return null;
  return (
    <LinearContainer
      activeTab={activeTab}
      setCardInfo={setCardInfo}
      taskQuarries={taskQuarries}
      projectPalette={projectPalette}
      getVisibleLinears={getVisibleLinears}
      linearTaskObjects={linearTaskObjects}
      changeTaskSettings={changeTaskSettings}
      chooseLinearObject={chooseLinearObject}
    />
  );
};

const mapStateToProps = state => ({
  taskQuarries: taskQuarries(state),
  projectPalette: projectPalette(state),
  linearTaskObjects: linearTaskObjects(state),
  getVisibleLinears: getVisibleLinears(state),
  chooseLinearObject: chooseLinearObject(state),
});

const mapDispatchToProps = {
  changeTaskSettings,
  setCardInfo,
  activeTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearRedux);
