import React from "react";
import classnames from "classnames";

const TopRow = ({ name, logs, closeHandler }) => {
  let icon = "";
  if (logs) {
    const { finished, has_errors } = logs[0];
    icon = has_errors ? "error" : finished ? "done" : "process";
  }
  return (
    <div className='main-container__top-row task-info-top-row'>
      <h2 className='main-title'>{name}</h2>
      <span className={classnames("icon", icon)}></span>
      <div className='new-project__close' onClick={closeHandler}>
        <div className='new-project__close-line close'></div>
      </div>
    </div>
  );
};

export default TopRow;
