import React, {Component} from 'react';
import {connect} from 'react-redux';
import {changeTaskSettings} from 'core/state/actions/settings';
import {getFacilitiesFromProjectByTaskType} from 'core/state/actions/projects';
import _isEqual from 'lodash.isequal';
import classnames from 'classnames';

import CommonFields from '../CommonFields';
import CommonField from '../../CommonField';
import BottomRow from "./BottomRow";

export const facilityPlacementFields = [
    'step_m',
    'step_deg',
    'placement_radius',
];

const defaultValues = {
    step_m: 10,
    step_deg: 15,
    placement_radius: 100
};

class Facility extends Component {
    state = {
        defaultValues,
        taskInfo: {props: {}}
    };

    componentWillUnmount() {
        this.props.changeTaskSettings('SUCCESS', {props: {}})
    }

    componentDidMount() {
        this.setPropsToState(this.props);
        const {info} = this.props;
        if (!info) {
            this.props.getFacilitiesFromProjectByTaskType('REQUEST', 'facility_placement')
        } else {
            const facility_id = this.props.taskInfo.props.facility_id;
            const quarries_ids = this.props.taskInfo.props.quarries_ids;
            this.props.changeTaskSettings('SUCCESS', {props: {facility_id, quarries_ids}})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.info !== this.props.info) {
            const {taskInfo} = this.props;
            if (this.props.info && !_isEqual(prevProps.taskInfo, taskInfo)) {
                this.setState({
                    taskInfo,
                    defaultValues: {
                        placement_radius: taskInfo.props.placement_radius,
                        step_m: taskInfo.props.step_m,
                        step_deg: taskInfo.props.step_deg,
                    }
                })
            }
        }
    }

    setPropsToState = props => {
        const {info, taskInfo} = props;
        if (info) {
            this.setState({
                taskInfo,
                defaultValues: {
                    placement_radius: taskInfo.props.placement_radius,
                    step_m: taskInfo.props.step_m,
                    step_deg: taskInfo.props.step_deg,
                }
            })
        }
    };

    render() {
        const {defaultValues, taskInfo} = this.state;
        const {info, errors = []} = this.props;
        return (
            <div className={classnames({'task-info': info})}>
                <CommonFields info={info} errors={errors} taskInfo={taskInfo}/>
                <div className="create-task-main-container">
                    <h2 className="create-task-row__title">Шаг оптимизации</h2>
                    <div className="create-task-row">
                        <CommonField
                            error={errors.includes('props.step_m')}
                            addedProps={{
                                dem: 'м',
                                required: true,
                                propName: 'props.step_m',
                                placeholder: 'м',
                                type: 'number',
                                defaultValue: defaultValues.step_m,
                                classContainer: 'half nowrap-label',
                                label: 'По расстоянию',
                                disabled: info
                            }}
                        />
                        <CommonField
                            error={errors.includes('props.step_deg')}
                            addedProps={{
                                dem: 'deg',
                                required: true,
                                propName: 'props.step_deg',
                                placeholder: '',
                                type: 'number',
                                defaultValue: defaultValues.step_deg,
                                classContainer: 'half nowrap-label',
                                label: 'По градусу',
                                disabled: info
                            }}
                        />
                    </div>
                    <div className="create-task-row">
                        <CommonField
                            error={errors.includes('props.placement_radius')}
                            addedProps={{
                                dem: 'м',
                                required: true,
                                propName: 'props.placement_radius',
                                placeholder: '',
                                type: 'number',
                                defaultValue: defaultValues.placement_radius,
                                classContainer: 'half nowrap-label',
                                label: 'Радиус смещения',
                                disabled: info
                            }}
                        />
                    </div>
                </div>
                {!info && <BottomRow/>}
            </div>
        )
    }
}


const mapDispatchToProps = {
    changeTaskSettings,
    getFacilitiesFromProjectByTaskType
};
export default connect(null, mapDispatchToProps)(Facility);
