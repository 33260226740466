export default type => {
  switch (type) {
    case 'existing':
      return 'Существующие';
    case 'calculated':
      return 'Рассчитанные';
    default:
      return type;
  }
};
