import React from 'react';
import ReactTooltip from 'react-tooltip';
import './style.scss';

const ReactTooltipContainer = () => {
    const getContent = data => {
        if (data && data.includes(';')) {
            const arr = data.split(';').filter(el => el);
            if (!arr.length) return null;
            if (arr.length > 1) {
                return <div className='tooltip-text__container'>
                    <h2 className="tooltip-text__title">Для расчета необходимо:</h2>
                    <ul>
                        {arr.map((el, key) => <li className='tooltip-text__row' key={key}>
                            {el}
                        </li>)}
                    </ul>
                </div>
            }
            return <span className="tooltip-text">{arr[0]}</span>
        }
        return <span className="tooltip-text">{data}</span>
    };
    return (
        <ReactTooltip
            effect='solid'
            id='mainTooltip'
            data-place='bottom'
            aria-haspopup='true'
            globalEventOff='click'
            getContent={getContent}
        />
    );
};

export default ReactTooltipContainer;

