import React, { Component } from 'react';
import { connect } from 'react-redux';

import { taskLogs } from 'core/state/selectors/projects';

import classnames from 'classnames';

class TaskStatus extends Component {
  clickHandler = () => {
    this.textArea.select();
    document.execCommand('copy');
  };
  removeHandler = () => {
    this.props.removeHandler(this.props.item);
  };

  repeatTask = () => {
    this.props.repeatTask(this.props.item);
  };
  downloadTask = () => {
    this.props.downloadTask(this.props.item);
  };

  render() {
    const { item } = this.props;
    const { id } = item;
    let icon = 'process';
    let textForCopy = '';
    if (
      this.props.taskLogs &&
      this.props.taskLogs[id] &&
      this.props.taskLogs[id][0]
    ) {
      const { finished, has_errors, debug_info, task_id } = this.props.taskLogs[
        id
      ][0];
      icon = has_errors ? 'error' : finished ? 'done' : 'process';
      let debugInfo = !debug_info ? 'Нет информации' : debug_info;
      const port = process.env.API_PORT;
      const project_id = item.props.project_id;
      textForCopy = `project_id: ${project_id} \nport: ${port} \ntask_id: ${task_id} \ndebugInfo: ${debugInfo}`;
    }
    let tipTitle =
      icon === 'done'
        ? 'Завершена'
        : icon === 'error'
        ? 'Завершена с ошибкой'
        : 'В процессе';
    return (
      <div className='left-container-icon'>
        <span
          data-for='mainTooltip'
          onClick={this.downloadTask}
          data-tip='Скачать информацию по задаче'
          className='download-task-icon icon download-icon'
        />
        <span
          data-for='mainTooltip'
          onClick={this.repeatTask}
          className='icon repeatIcon'
          data-tip='Перезапустить задачу'
        />
        <span onClick={this.removeHandler} className='field-row__remove' />
        <span
          data-tip={tipTitle}
          data-for='mainTooltip'
          onClick={this.clickHandler}
          className={classnames('icon', icon)}
        />
        <textarea
          className='hidden-textarea'
          ref={ref => (this.textArea = ref)}
          value={textForCopy}
          readOnly
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  taskLogs: taskLogs(state),
});

export default connect(mapStateToProps)(TaskStatus);
