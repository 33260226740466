import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {facilityId} from 'core/state/selectors/settings';

export default () => {
    const facility = useSelector(facilityId);
    useEffect(() => {
        if (facility) {
            const elem = document.getElementsByClassName(`facility ${facility}`)[0];
            if (elem) {
                const nodeClasses = elem.classList;
                document.querySelectorAll('.facility.first-selected').forEach(el => {
                    if (el.classList.contains('first-selected')) {
                        el.classList.remove('first-selected')
                    }
                });
                if (!nodeClasses.contains('first-selected')) {
                    nodeClasses.add('first-selected');
                }
            }
        } else {
            document.querySelectorAll('.first-selected').forEach(el => {
                el.classList.remove('first-selected');
            })
        }
    }, [facility])
}
