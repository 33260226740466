import React from 'react';

import './Styles.scss';
import ExpandedMenu from './ExpandedMenu';
import ExpandedTopMenu from './ExpandedTopMenu';

const LeftMenu = () => (
    <div className="leftSideBar">
        <ExpandedTopMenu/>
        <ExpandedMenu/>
    </div>
);

export default LeftMenu;
