import React from 'react'
import classNames from 'classnames'

import Dropzone from 'react-dropzone'

export default ({ onDrop, fileName }) => <Dropzone onDrop={onDrop}>
  {({ getRootProps, getInputProps, isDragActive }) => {
    return (
      <div
        {...getRootProps()}
        className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
      >
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p className="dropzone__text">Перетащите сюда файл или выберете для загрузки</p> :
              <p className="dropzone__text">Перетащите сюда файл или выберете для загрузки</p>
        }
      </div>
    )
  }}
</Dropzone>