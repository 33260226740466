import {useEffect} from 'react';

const moveOnMap = ({map, points, dashedLine}) => {
    useEffect(() => {
        const moveHandler = ({latlng: {lat, lng}}) => {
            if (points && dashedLine && points[points.length - 1]) {
                const dashedLineCoods = [
                    points[points.length - 1],
                    [lat, lng]
                ];
                dashedLine.current.leafletElement.setLatLngs(dashedLineCoods);
            }
        };
        map.on('mousemove', moveHandler);
        return () => map.off('mousemove', moveHandler);
    }, [map, points]);
};

export default moveOnMap;