import React, {Component} from 'react'

export default class Logout extends Component {
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    };

    handleClickOutside = event => {
        if (this.topMenuRef && !this.topMenuRef.contains(event.target)) {
            document.removeEventListener('mousedown', this.handleClickOutside);
            this.props.showHandler()
        }
    };
    setTopMenuRef = node => {
        this.topMenuRef = node;
    };

    render() {
        return (
            <div
                ref={this.setTopMenuRef}
                className="menu__container"
                onClick={this.props.onClickHandler}
            >
                <div className="menu__triangle"/>
                <h2 className="menu__title">Выйти из системы</h2>
            </div>
        )
    }
}
