import React from "react";
import {Curve, Dot} from "recharts";

export default props => {
    return <>
        <Dot {...props}/>
        <Curve
            strokeWidth={1}
            points={[{x: props.cx, y: 16}, {x: props.cx, y: 226}]}
            stroke='rgba(119, 244, 232, 0.24)'
        />
        <Curve
            strokeWidth={1}
            stroke='rgba(119, 244, 232, 0.24)'
            points={[{x: 42, y: props.cy}, {x: props.cx, y: props.cy}]}
        />
    </>
};