import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import ToggleTitle from './ToggleTitle';
import ToggleList from './ToggleList';
import { getOppenedListByType } from 'core/state/selectors/groupsSettings';
import { setOpenedListByType } from 'core/state/actions/groupsSettings';

class SubLayer extends Component {
  state = { showList: false }
  showListHandler = () => {
    const { titleProps, setOpenedListByType, updated } = this.props;
    const { itemKey, lastItemKey } = titleProps
    this.setState({
      showList: !this.state.showList
    }, () => {
      setOpenedListByType('SUCCESS', { itemKey, lastItemKey })
      if (typeof updated === 'function') {
        updated()
      }
    })
  }
  componentDidMount() {
    const { titleProps, getOppenedListByType } = this.props;
    const { itemKey, lastItemKey } = titleProps
    const item = getOppenedListByType[lastItemKey]
    if (item) {
      if (item.includes(itemKey)) {
        this.setState({
          showList: true
        })
      }
    }
  }
  onMouseEnterHandler = item => {
    if (typeof this.props.listProps.onMouseEnter === 'function') {
      return this.props.listProps.onMouseEnter(item)
    }
  }
  onMouseLeaveHandler = item => {
    if (typeof this.props.listProps.onMouseLeave === 'function') {
      return this.props.listProps.onMouseLeave(item)
    }
  }
  render() {
    const { showList } = this.state;
    const { titleProps, listProps } = this.props;
    return (
      <div className="open-projects-main-row toggle-sublayer">
        <ToggleTitle
          {...titleProps}
          showList={showList}
          showListHandler={this.showListHandler} />
        {showList &&
          <ul>
            <ToggleList {...listProps} />
            {
              listProps.withoutGroup && listProps.withoutGroup.map((item, key) => {
                return <li
                  key={key}
                  onMouseEnter={() => this.onMouseEnterHandler(item)}
                  onMouseLeave={() => this.onMouseLeaveHandler(item)}
                  className={classnames('row-toggle', { active: listProps.active(item) }, listProps.containerClass)}>
                  {listProps.beforeComp && listProps.beforeComp(item, listProps.beforeProp)}
                  <span className="row-toggle__text" onClick={() => listProps.chooseRow(item)}>{item.name}</span>
                  {listProps.afterComp && listProps.afterComp(item)}
                </li>
              })}
          </ul>}
      </div>
    )
  }
}

export default connect(
  state => ({ getOppenedListByType: getOppenedListByType(state) }),
  { setOpenedListByType })(SubLayer);