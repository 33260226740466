import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import WebSocketComponent from 'components/Common/WebSocketComponent';
import { socketUrl } from 'socketConfig';

class WebSocketContainer extends Component {
  state = { socket: '' };

  componentDidMount() {
    if (!socketUrl) {
      const socket = `ws://${window.location.hostname}:${process.env.SOCKET_PORT}`;
      this.setState({ socket });
    } else {
      this.setState({ socket: socketUrl });
    }
  }

  logsHandler = data => {};
  onMessageHandler = e => {
    this.props.onMessageHandler(JSON.parse(e.data));
  };
  onerrorHandler = e => {
    console.log('socked err', e);
  };
  onCloseHandler = e => {};

  render() {
    const { socket } = this.state;
    if (!socket) return null;
    return (
      <WebSocketComponent
        onclose={this.onCloseHandler}
        onerror={this.onerrorHandler}
        url={`${socket}${this.props.url}`}
        onmessage={this.onMessageHandler}
      />
    );
  }
}

export default withRouter(WebSocketContainer);
