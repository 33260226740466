import React from 'react'
import dns from 'assets/images/objIco_upn_dns.svg'
import well from 'assets/images/objIco_well_cluster.svg'
import waterOil from 'assets/images/objIco_water_oil.svg'
import electricity from 'assets/images/objIco_electricity.svg'
import gas from 'assets/images/objIco_gas.svg'
import facility from 'assets/images/objIco_other.svg'
import quarry from 'assets/images/objIco_quarry.svg'

export default ComposedComponent => props => {
  const setIcon = type => {
    switch (type) {
      case 'well':
        return well;
      case 'quarry':
        return quarry;
      case 'gas_disposal_well':
      case 'compressor_station':
        return gas;
      case 'oil_treatment_plant':
      case 'booster_pump_station':
        return dns;
      case 'stop_valve_station':
      case 'water_disposal_well':
      case 'commissioning_station':
      case 'multiphase_pump_station':
      case 'raw_water_intake_facility':
      case 'central_gathering_facility':
      case 'oil_transfer_pumping_station':
      case 'modular_cluster_pump_station':
      case 'preliminary_water_removal_unit':
        return waterOil;
      case 'transformer_station':
      case 'plant_power_station':
      case 'outer_source_of_power_supply':
        return electricity;
      case 'facility':
        return facility;
      default:
        return null
    }
  };
  return <ComposedComponent {...props} setIcon={setIcon} />
};