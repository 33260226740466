import React from 'react';
import {useSelector} from "react-redux";
import coloringObj from 'utils/coloringObj'
import ButtonContainer from "./ButtonContainer";
import {getFacilityById} from 'core/state/selectors/objects';
import GetWithErrorHandling from "utils/GetWithErrorHandling";

export default ({taskResult}) => {
    if (!taskResult[0]) return null;
    const data = taskResult[0].props;
    const facilities = useSelector(getFacilityById);
    return <div className='gathering-centers__container'>
        {
            data.gathering_centers_ids.map((el, index) => {
                return <div key={el} className="gathering-centers__row">
                    <span className='gathering-centers__color-line'
                          style={{backgroundColor: coloringObj(`tmpColor${index}`).color}}/>
                    <div className="gathering-centers-inline"/>
                    <div className="d-inline align-middle gathering-centers__title-container">
                        <h3 className="gathering-centers__title">
                            {GetWithErrorHandling({data: facilities, path: [el, 0, 'name']})}
                        </h3>
                    </div>
                    <div className='d-inline align-middle'>
                        <h3 className="gathering-centers__sub-title d-inline align-middle">Кустовые площадки: <span
                            className="gathering-centers__facilities d-inline align-middle">
                                 {
                                     data.facilities_ids[index].map(el => GetWithErrorHandling({
                                         data: facilities,
                                         path: [el, 0, 'name']
                                     })).join(', ')
                                 }
                        </span>
                        </h3>
                    </div>
                    {
                        data.applied &&
                        <ButtonContainer data={data} index={index}/>
                    }
                </div>
            })
        }
    </div>
};
