import React, {Component} from 'react';
import classnames from 'classnames'

class ErrorItem extends Component {
    render() {
        const {err, success, errors, info} = this.props;
        return <div className={classnames("logs_alert", {success, errors, info})}>
            <span className={classnames("logs__icon", {success, errors, info})}/>
            <p className="logs__desc">{err._issues ? err._issues.id : err}</p>
        </div>
    }
}

export default ErrorItem;
