import React from 'react';
import {useSelector} from 'react-redux';
import {showModal} from 'core/state/selectors/settings';
import AboutProjectModalRedux from './AboutProjectModal/AboutProjectModalRedux';
import HelpModalRedux from './NewWellfieldHelpModal/HelpModalRedux';
import CancelConfirmationRedux from './CancelConfirmationModal/CancelConfirmationRedux';
import RemoveConfirmationRedux from './RemoveConfirmationModal/RemoveConfirmationRedux';
import LogoutModalRedux from './LogoutModal/LogoutModalRedux';

const Modal = () => {
    const type = useSelector(showModal);
    switch (type) {
        case 'AboutProjectModal':
            return <AboutProjectModalRedux/>;
        case 'NewWellfieldHelpModal':
            return <HelpModalRedux/>;
        case 'CancelConfirmationModal':
            return <CancelConfirmationRedux/>;
        case 'RemoveConfirmationModal':
            return <RemoveConfirmationRedux/>;
        case 'LogoutModal':
            return <LogoutModalRedux/>;
        default:
            return null;
    }
};

export default Modal;
