import React, { Component } from 'react'

class LinearCost extends Component {
  state = { total_cost: {}, total_length: {} };
  componentDidMount() {
    this.setPropsToState(this.props)
  }
  componentWillReciveProps(nextProps) {
    this.setPropsToState(nextProps)
  }
  setPropsToState = props => {
    const { taskResult, currentHandler } = props;
    let data = taskResult;
    if (!currentHandler === 'cost') {
      data = taskResult[0]
    }
    if (data) {
      let currentData = data;
      if (data[0]) {
        currentData = data[0]
      }
      this.setState({
        ...data.props,
        total_cost: currentData.props.total_cost || currentData.props.cost,
        total_length: currentData.props.total_length || currentData.props.length,
      }, () => {
        const { linear_id } = currentData.props;
        const elems = document.getElementsByClassName(`linear ${linear_id}`);
        Array.from(elems).forEach(elem => {
          if (!elem.classList.contains('active')) {
            elem.classList.add('active')
          }
        })
      })
    }
  };
  componentWillUnmount() {
    const { linear_id } = this.state;
    const elems = document.getElementsByClassName(`linear ${linear_id}`);
    Array.from(elems).forEach(elem => {
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
      }
    })
  }
  render() {
    const { total_cost, total_length } = this.state
    if (!total_cost || !total_length || !total_length.total) return null;
    return (
      <table className='result-table'>
        <thead>
          <tr className='result-table-top result-table-top__linear-cost'>
            <th>Участок</th>
            <th>Протяженность, км</th>
            <th>Стоимость строительства, тыс. руб.</th>
            <th></th>
          </tr>
        </thead>
        <tbody className='result-table__body result-table__body-linear-cost'>
          <tr className='result-table__body-common-fields'>
            <td>Общий путь</td>
            <td>{(total_length.total / 1000).toFixed(2) * 1}</td>
            <td>{(total_cost.total / 1000).toFixed(2) * 1}</td>
            <td></td>
          </tr>
          {!!total_cost.details && Object.keys(total_cost.details).map((key, id) => {
            return <tr key={id}>
              <td>{key}</td>
              <td>{(total_length.details[key] / 1000).toFixed(2) * 1}</td>
              <td>{(total_cost.details[key] / 1000).toFixed(2) * 1}</td>
              <td></td>
            </tr>
          })}
        </tbody>
      </table>
    )
  }
}


export default LinearCost;