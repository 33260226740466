import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getAdditionalProps} from 'core/state/selectors/wellfield'
import {getTaskSettings} from 'core/state/selectors/settings'
import {setCardInfo, changeTaskSettings} from 'core/state/actions/settings'

import CommonFields from './CommonFields'
import CommonField from '../../CommonField'

class Facility extends Component {
    state = {addedProps: []};

    componentWillUnmount() {
        this.props.setCardInfo('SUCCESS', {})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.getTaskSettings.class_id !== this.props.getTaskSettings.class_id) {
            let propsObj = this.props.getAdditionalProps[this.props.getTaskSettings.class_id] || {};
            const addedProps = Object.keys(propsObj).map(key => {
                if (!propsObj[key].unit) {
                    if (['width', 'length'].includes(propsObj[key].name)) {
                        propsObj[key].unit = 'м';
                    }
                }
                return propsObj[key]
            });
            this.setState({addedProps}, () => {
                this.setObjToStore(propsObj);
            })
        }
    }

    setObjToStore = propsObj => {
        const obj = {};
        Object.keys(propsObj).forEach(key => {
            if (["length", "width"].includes(propsObj[key].name)) {
                obj[`props.${propsObj[key].name}`] = parseFloat(propsObj[key].value)
            }
        });
        if (Object.keys(obj).length) {
            this.props.changeTaskSettings('SUCCESS', obj)
        }
    };

    componentDidMount() {
        const {
            info,
            taskInfo,
            getTaskSettings,
            getAdditionalProps
        } = this.props;
        let propsObj = getAdditionalProps[getTaskSettings.class_id] || {};
        if (taskInfo) {
            propsObj = getAdditionalProps[taskInfo.class_id] || {}
        }
        const addedProps = Object.keys(propsObj).map(key => {
            if (!propsObj[key].unit) {
                if (['width', 'length'].includes(propsObj[key].name)) {
                    propsObj[key].unit = 'м';
                }
            }
            return propsObj[key]
        });
        this.setState({addedProps}, () => {
            if (!info) {
                this.setObjToStore(propsObj)
            }
        })
    }

    render() {
        const {info, errors, getTaskSettings, taskInfo} = this.props;
        const {addedProps} = this.state;
        const {center_point} = getTaskSettings;
        return (
            <div>
                <CommonFields errors={errors} info={!!taskInfo} data={taskInfo}/>
                <div className="create-task-main-container">
                    {
                        addedProps.map((item, key) => {
                            return <div key={key} className="create-task-row">
                                <CommonField
                                    addedProps={{
                                        dem: item.unit || '',
                                        placeholder: '',
                                        type: item.data_type === '_float' ? 'number' : item.data_type,
                                        defaultValue: item.value,
                                        propName: `props.${item.name}`,
                                        classContainer: 'half nowrap-label',
                                        label: item.description,
                                        disabled: info
                                    }}
                                />
                            </div>
                        })
                    }
                </div>
                {
                    !center_point && !info && <div className="alert-row">
                        <h2 className="alert-row__title">Разместите объект на карте</h2>
                    </div>
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    getTaskSettings: getTaskSettings(state),
    getAdditionalProps: getAdditionalProps(state)
});

const mapDispatchToProps = {setCardInfo, changeTaskSettings};

export default connect(mapStateToProps, mapDispatchToProps)(Facility)
