import {action} from 'utils'

import {
    GET_TASK,
    ADD_TASK,
    GET_LAYOUT,
    SET_TASK_ID,
    REPEAT_TASK,
    CLEAR_STATE,
    REMOVE_TASK,
    REMOVE_CLASS,
    SEND_NETWORK,
    SEND_PROJECT,
    GET_NETWORKS,
    REMOVE_OBJECT,
    SEND_FACILITY,
    DOWNLOAD_TASK,
    GET_TASKS_LOGS,
    REMOVE_NETWORK,
    RENAME_PROJECT,
    SET_PROJECT_INFO,
    REMOVE_TASK_LOGS,
    REMOVE_METACLASS,
    GET_TASK_RESULTS,
    SET_QUARRIES_IDS,
    DOWNLOAD_PROJECT,
    GET_PROJECT_BY_ID,
    GET_PROJECT_PALETTE,
    SET_NEW_PO_POSITION,
    SET_NEW_PROJECT_AREA,
    GET_CALCULATED_OBJECTS,
    REMOVE_METACLASS_BY_TYPE,
    SET_AVALIABLE_TASK_TYPES,
    GET_PREVIEW_PROJECT_PROPS,
    GET_METACLASSES_BY_WELLFIELD_AND_TYPE,
    GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE
} from '../constants/projects'

export const getLayout = action(GET_LAYOUT);
export const removeTaskLogs = action(REMOVE_TASK_LOGS);
export const setAvaliableTaskTypes = action(SET_AVALIABLE_TASK_TYPES);
export const renameProject = action(RENAME_PROJECT);
export const downloadTask = action(DOWNLOAD_TASK);
export const getPreviewProjectProps = action(GET_PREVIEW_PROJECT_PROPS);
export const repeatTask = action(REPEAT_TASK);
export const getTask = action(GET_TASK);
export const downloadProject = action(DOWNLOAD_PROJECT);
export const removeNetwork = action(REMOVE_NETWORK);
export const removeClass = action(REMOVE_CLASS);
export const removeObject = action(REMOVE_OBJECT);
export const getCalculatedObjects = action(GET_CALCULATED_OBJECTS);
export const getProjectById = action(GET_PROJECT_BY_ID);
export const setQuarriesIds = action(SET_QUARRIES_IDS);
export const getNetworks = action(GET_NETWORKS);
export const setTaskId = action(SET_TASK_ID);
export const getTasksLogs = action(GET_TASKS_LOGS);
export const removeTask = action(REMOVE_TASK);
export const removeMetaclassByType = action(REMOVE_METACLASS_BY_TYPE);
export const sendNetwork = action(SEND_NETWORK);
export const getTaskResult = action(GET_TASK_RESULTS);
export const setNewPoPosition = action(SET_NEW_PO_POSITION);
export const addTask = action(ADD_TASK);
export const getProjectPalette = action(GET_PROJECT_PALETTE);
export const sendProject = action(SEND_PROJECT);

export const setNewProjectArea = action(SET_NEW_PROJECT_AREA);
export const setProjectInfo = action(SET_PROJECT_INFO);

export const clearState = action(CLEAR_STATE);
export const sendFacility = action(SEND_FACILITY);

export const removeMetaclass = action(REMOVE_METACLASS);
export const getMetaclassesByWellfieldAndType = action(GET_METACLASSES_BY_WELLFIELD_AND_TYPE);
export const getFacilitiesFromProjectByTaskType = action(GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE);
