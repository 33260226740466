import {connect} from 'react-redux';
import {
    setTaskId,
    repeatTask,
    downloadTask,
    getTaskResult
} from 'core/state/actions/projects';

import {
    taskType,
    activeTab,
    setRemoveProps,
    openRemoveAlert,
    setTaskFacilities
} from 'core/state/actions/settings';

import {
    getTasksById
} from 'core/state/selectors/projects';

import TasksContainer from './TasksContainer';

const mapStateToProps = state => ({
    getTasksById: getTasksById(state)
});

const mapDispatchToProps = {
    setTaskId,
    repeatTask,
    downloadTask,
    getTaskResult,
    activeTab,
    setTaskFacilities,
    taskType,
    setRemoveProps,
    openRemoveAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
