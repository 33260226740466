import React from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getDemsByWellfield} from "../../../../core/state/selectors/wellfield";
import {getFacilitiesFromProjectByTaskType} from "../../../../core/state/actions/projects";
import CommonField from "../../CommonField";

const DemsComponent = ({disabled = false, defaultValue, error}) => {
    const dems = useSelector(getDemsByWellfield, shallowEqual);
    const [options, setOptions] = React.useState([]);
    const dispatch = useDispatch();
    dispatch(getFacilitiesFromProjectByTaskType('REQUEST', 'gathering_centers'));
    React.useEffect(() => {
        const arr = dems.map(({id, type}) => ({
            value: id,
            label: type
        }));
        setOptions(arr);
    }, [dems]);
    const config = {
        disabled,
        defaultValue,
        required: true,
        propName: 'props.dem_id',
        type: 'option',
        placeholder: '',
        classContainer: 'wide',
        label: 'Цифровая модель рельефа',
        options
    };
    return <CommonField
        error={error}
        addedProps={config}
    />
};

export default DemsComponent;
