import { connect } from "react-redux";
import { getActiveTab } from "core/state/selectors/settings";
import { clickType } from "core/state/actions/settings";
import ChooseObjectsHandlerContainer from "./ChooseObjectsHandlerContainer";

const mapStateToProps = state => ({
  taskType: getActiveTab(state),
});

const mapDispatchToProps = {
  clickType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseObjectsHandlerContainer);
