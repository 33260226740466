import React, {Component} from 'react';
import CardsFactory from './CardsFactory';
import SwitchResults from './SwitchResults';
import TopRow from './TopRow';
import BottomRow from './BottomRow';
import handleTitle from './utils/handleTitle';

class InfoCardContainer extends Component {
    componentWillUnmount = () => {
        this.removeProps()
    };
    removeProps = () => {
        this.props.setTaskFacilities('SUCCESS', []);
        this.props.taskType('SUCCESS', null);
        this.props.setTaskId('SUCCESS', null);
        this.props.getTaskResult('SUCCESS', []);
        this.props.setQuarriesIds('SUCCESS', null);
    };
    closeHandler = () => {
        this.removeProps();
        const {cardInfo, activeTab} = this.props;
        if (cardInfo && cardInfo.type) {
            const {type} = cardInfo;
            if (type) {
                if (type === 'facility') {
                    activeTab('SUCCESS', 'objects');
                } else if (type === 'area') {
                    activeTab('SUCCESS', 'areas');
                } else {
                    activeTab('SUCCESS', type);
                }
            }
        } else {
            activeTab('SUCCESS', 'tasks');
        }
    };
    repeatTask = () => {
        if (!this.props.cardInfo.type) {
            const {id, name} = this.props.taskInfo;
            this.props.repeatTask('REQUEST', {id, name});
            this.removeProps();
            this.props.removeTaskLogs('SUCCESS', id);
            this.props.activeTab('SUCCESS', 'tasks');
        }
    };
    removeHandler = () => {
        const {setRemoveProps, openRemoveAlert, taskInfo, cardInfo, classesByWellfield} = this.props;
        let removeProps = {};
        if (taskInfo.type) {
            const {id, name, type} = taskInfo;
            removeProps = {
                id,
                name: name || 'Без названия',
                objectType: handleTitle(type),
                title: 'задачи',
                typeTitle: 'задачи',
                removeType: 'task',
                type,
                questionTitle: 'задачу'
            }
        } else {
            const {type} = cardInfo;
            const {id, name, class_id} = cardInfo.data;
            const {name: objectType} = classesByWellfield[class_id];
            const questionTitle = type === "linear" ?
                "линейный объект" :
                type === 'facility' ? 'объект' :
                    "область";
            const title = type === "linear" ?
                "линейного объекта" :
                type === 'facility' ? 'объекта' :
                    "области";
            removeProps = {
                id,
                name: name || 'Без названия',
                objectType,
                title,
                typeTitle: 'объекта',
                removeType: type === 'facility' ? 'object' : type,
                questionTitle
            }
        }
        setRemoveProps('SUCCESS', removeProps);
        openRemoveAlert('SUCCESS', true);
    };

    render() {
        const {taskInfo, cardInfo} = this.props;
        let type = taskInfo.type;
        let info = taskInfo;
        if (Object.keys(cardInfo).length) {
            type = cardInfo.type;
            info = cardInfo.data;
        }
        return (
            <div className="createTaskContainer">
                <TopRow
                    name={info.name}
                    closeHandler={this.closeHandler}
                    logs={this.props.taskLogs[info.id]}/>
                <SwitchResults type={type}/>
                {CardsFactory.build(type, info)}
                <BottomRow
                    type={type}
                    firstButtonFunc={this.repeatTask}
                    secondButtonFunc={this.closeHandler}
                    removeHandler={this.removeHandler}
                />
            </div>
        )
    }
}


export default InfoCardContainer;
