import React from "react";

export default ({ type }) => {
  switch (type) {
    case "menu":
      return (
        <svg width='24px' height='24px' viewBox='0 0 24 24'>
          <defs />
          <g
            id='m_menu'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <path
              d='M3,18 L21,18 L21,16 L3,16 L3,18 L3,18 Z M3,13 L21,13 L21,11 L3,11 L3,13 L3,13 Z M3,6 L3,8 L21,8 L21,6 L3,6 L3,6 Z'
              id='menu'
            />
          </g>
        </svg>
      );
    case "tasks":
      return (
        <svg width='24px' height='24px' viewBox='0 0 24 24'>
          <g
            id='m_tasks'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <path
              d='M18.2222222,4.8 L14.5066667,4.8 C14.1333333,3.756 13.1555556,3 12,3 C10.8444444,3 9.86666667,3.756 9.49333333,4.8 L5.77777778,4.8 C4.79593822,4.8 4,5.60588745 4,6.6 L4,19.2 C4,20.1941125 4.79593822,21 5.77777778,21 L18.2222222,21 C19.2040618,21 20,20.1941125 20,19.2 L20,6.6 C20,5.60588745 19.2040618,4.8 18.2222222,4.8 Z M12,4.8 C12.4909198,4.8 12.8888889,5.20294373 12.8888889,5.7 C12.8888889,6.19705627 12.4909198,6.6 12,6.6 C11.5090802,6.6 11.1111111,6.19705627 11.1111111,5.7 C11.1111111,5.20294373 11.5090802,4.8 12,4.8 Z M7.55555556,8.4 L16.4444444,8.4 L16.4444444,6.6 L18.2222222,6.6 L18.2222222,19.2 L5.77777778,19.2 L5.77777778,6.6 L7.55555556,6.6 L7.55555556,8.4 Z M8,14.25 L9.33333333,12.9 L11.1111111,14.7 L15.1111111,10.65 L16.4444444,12 L11.1111111,17.4 L8,14.25 Z'
              id='tasks'
              fillRule='nonzero'
            />
          </g>
        </svg>
      );
    case "objects":
      return (
        <svg width='24px' height='24px' viewBox='0 0 24 24'>
          <g
            id='m_objects'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <path
              d='M13.9,11.55 C14.4967371,11.55 15.0690334,11.3129471 15.4909903,10.8909903 C15.9129471,10.4690334 16.15,9.8967371 16.15,9.3 C16.15,8.05735931 15.1426407,7.05 13.9,7.05 C12.6573593,7.05 11.65,8.05735931 11.65,9.3 C11.65,10.5426407 12.6573593,11.55 13.9,11.55 Z M13.9,3 C17.374,3 20.2,5.817 20.2,9.3 C20.2,14.025 13.9,21 13.9,21 C13.9,21 7.6,14.025 7.6,9.3 C7.6,5.82060608 10.4206061,3 13.9,3 Z M5.8,9.3 C5.8,13.35 10.372,18.894 11.2,19.929 L10.3,21 C10.3,21 4,14.025 4,9.3 C4,6.447 5.899,4.035 8.5,3.261 C6.844,4.746 5.8,6.897 5.8,9.3 Z'
              id='objects'
              fill='#fff'
              fillRule='nonzero'
            />
          </g>
        </svg>
      );
    case "linear":
      return (
        <svg width='24px' height='24px' viewBox='0 0 24 24'>
          <g
            id='m_linear_objects'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <path
              d='M22,7 C22,8.1 21.1,9 20,9 C19.82,9 19.65,8.98 19.49,8.93 L16.93,13.48 C16.98,13.64 17,13.82 17,14 C17,15.1 16.1,16 15,16 C13.9,16 13,15.1 13,14 C13,13.82 13.02,13.64 13.07,13.48 L10.52,10.93 C10.36,10.98 10.18,11 10,11 C9.82,11 9.64,10.98 9.48,10.93 L5.93,16.49 C5.98,16.65 6,16.82 6,17 C6,18.1 5.1,19 4,19 C2.9,19 2,18.1 2,17 C2,15.9 2.9,15 4,15 C4.18,15 4.35,15.02 4.51,15.07 L8.07,9.52 C8.02,9.36 8,9.18 8,9 C8,7.9 8.9,7 10,7 C11.1,7 12,7.9 12,9 C12,9.18 11.98,9.36 11.93,9.52 L14.48,12.07 C14.64,12.02 14.82,12 15,12 C15.18,12 15.36,12.02 15.52,12.07 L18.07,7.51 C18.02,7.35 18,7.18 18,7 C18,5.9 18.9,5 20,5 C21.1,5 22,5.9 22,7 Z'
              id='linear_objects'
              fill='#fff'
              fillRule='nonzero'
            />
          </g>
        </svg>
      );
    case "areas":
      return (
        <svg width='24px' height='24px' viewBox='0 0 24 24'>
          <g
            id='m_areas'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <path
              d='M4.71428571,3 C3.76285714,3 3,3.76285714 3,4.71428571 L3,14.1428571 C3,15.0942857 3.76285714,15.8571429 4.71428571,15.8571429 C7.86,15.8571429 10.9971429,15.8571429 14.1428571,15.8571429 C15.0942857,15.8571429 15.8571429,15.0942857 15.8571429,14.1428571 C15.8571429,10.9971429 15.8571429,7.86 15.8571429,4.71428571 C15.8571429,3.76285714 15.0942857,3 14.1428571,3 L4.71428571,3 Z M4.71428571,4.71428571 L14.1428571,4.71428571 L14.1428571,14.1428571 L4.71428571,14.1428571 L4.71428571,4.71428571 Z M17.5714286,8.14285714 L17.5714286,9.85714286 L19.2857143,9.85714286 L19.2857143,19.2857143 L9.85714286,19.2857143 L9.85714286,17.5714286 L8.14285714,17.5714286 L8.14285714,19.2857143 C8.14285714,20.2371429 8.90571429,21 9.85714286,21 L19.2857143,21 C20.2371429,21 21,20.2371429 21,19.2857143 L21,9.85714286 C21,8.90571429 20.2371429,8.14285714 19.2857143,8.14285714 L17.5714286,8.14285714 Z M4,6 C5.1045695,6 6,5.1045695 6,4 C6,2.8954305 5.1045695,2 4,2 C2.8954305,2 2,2.8954305 2,4 C2,5.1045695 2.8954305,6 4,6 Z M15,6 C16.1045695,6 17,5.1045695 17,4 C17,2.8954305 16.1045695,2 15,2 C13.8954305,2 13,2.8954305 13,4 C13,5.1045695 13.8954305,6 15,6 Z M4,17 C5.1045695,17 6,16.1045695 6,15 C6,13.8954305 5.1045695,13 4,13 C2.8954305,13 2,13.8954305 2,15 C2,16.1045695 2.8954305,17 4,17 Z M15,17 C16.1045695,17 17,16.1045695 17,15 C17,13.8954305 16.1045695,13 15,13 C13.8954305,13 13,13.8954305 13,15 C13,16.1045695 13.8954305,17 15,17 Z M9,22 C10.1045695,22 11,21.1045695 11,20 C11,18.8954305 10.1045695,18 9,18 C7.8954305,18 7,18.8954305 7,20 C7,21.1045695 7.8954305,22 9,22 Z M20,22 C21.1045695,22 22,21.1045695 22,20 C22,18.8954305 21.1045695,18 20,18 C18.8954305,18 18,18.8954305 18,20 C18,21.1045695 18.8954305,22 20,22 Z M20,11 C21.1045695,11 22,10.1045695 22,9 C22,7.8954305 21.1045695,7 20,7 C18.8954305,7 18,7.8954305 18,9 C18,10.1045695 18.8954305,11 20,11 Z'
              id='areas'
              fill='#fff'
              fillRule='nonzero'
            />
          </g>
        </svg>
      );
    default:
      return null;
  }
};
