import React from 'react';
import CommonField from "../../CommonField";

const BackfillingHeight = React.memo(({error, disabled, backfilling_height}) => {
    return <CommonField
        error={error}
        addedProps={{
            dem: 'м',
            required: true,
            propName: 'props.backfilling_height',
            placeholder: '',
            type: 'number',
            defaultValue: backfilling_height,
            classContainer: 'half',
            label: 'Высота отсыпки',
            disabled
        }}
    />
});

export default BackfillingHeight;
