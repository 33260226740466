import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {changeTaskSettings} from 'core/state/actions/settings'

class CheckBox extends Component {
    state = {checked: false};
    setPropsToState = props => {
        const {defaultValue} = props;
        if (defaultValue !== undefined) {
            this.setState({
                checked: defaultValue
            })
        }
    };
    onChangeHandler = () => {
        this.setState({
            checked: !this.state.checked
        }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(this.state.checked)
            }
            if (this.props.propName) {
                this.props.changeTaskSettings('SUCCESS', {
                    [this.props.propName]: this.state.checked
                })
            }
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.defaultValue !== undefined && prevProps.defaultValue !== prevState.checked) {
            this.setState({
                checked: prevProps.defaultValue
            })
        }
    }

    componentDidMount() {
        this.setPropsToState(this.props)
    }

    render() {
        const {checked} = this.state;
        const {id, label, disabled, propName: name} = this.props;
        return <div className="checkBoxContainer">
            <input
                id={id}
                name={name}
                type="checkbox"
                checked={checked}
                disabled={disabled}
                className="checkBoxInput"
                onChange={this.onChangeHandler}/>
            <label
                htmlFor={id}
                className="checkBoxLabel">
                <span className="checkBoxSpan">{label}</span>
            </label>
        </div>;
    }
}

CheckBox.propTypes = {
    checked: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func
};

export default connect(null, {changeTaskSettings})(CheckBox);
