import { connect } from 'react-redux';
import { networks, getTasksById } from 'core/state/selectors/projects';

import { getLinears } from 'core/state/selectors/objects';

import { setInfoLogs } from 'core/state/actions/common';

import {
  taskType,
  activeTab,
  setCardInfo,
  setRemoveProps,
  openRemoveAlert,
  setTaskFacilities,
} from 'core/state/actions/settings';

import {
  hideLinears,
  setOpenedTasks,
  hideLinearsMetaclass,
  hideLinearsByMetaclassAndType,
  hideLinearsByMetaclassAndNetwork,
} from 'core/state/actions/groupsSettings';

import {
  sortClassesByMetaclasses,
  sortMetaclassesByClasses,
  getMetaclassesSortedById,
} from 'core/state/selectors/wellfield';

import { getTaskResult, setTaskId } from 'core/state/actions/projects';

import LinearsListContainer from './LinearsListContainer';

const mapDispatchToProps = {
  taskType,
  activeTab,
  setTaskId,
  setInfoLogs,
  setCardInfo,
  hideLinears,
  getTaskResult,
  setRemoveProps,
  setOpenedTasks,
  openRemoveAlert,
  setTaskFacilities,
  hideLinearsMetaclass,
  hideLinearsByMetaclassAndType,
  hideLinearsByMetaclassAndNetwork,
};

const mapStateToProps = state => ({
  networks: networks(state),
  linears: getLinears(state),
  getTasksById: getTasksById(state),
  sortClassesByMetaclasses: sortClassesByMetaclasses(state),
  sortMetaclassesByClasses: sortMetaclassesByClasses(state),
  getMetaclassesSortedById: getMetaclassesSortedById(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinearsListContainer);
