import React from 'react'

import Button from 'components/Common/Button'
import DataFields from 'components/Common/DataFields'
import './style.scss'
import Logs from './Logs'
import NewWellfieldDropzone from './NewWellfieldDropzone'

const NewWellfieldView = ({onDrop, progress, onCancelUpload, onCancelChecking, disabled, fileName, fileSize, wellfieldName, closeHandler, createHandler, helpHandler, onChangeHandler, cancelHandler, canceled}) => (
    <div className='new-wellfield-container'>
        <div className="new-wellfield">
            <div className="projects__col-row title">
                <h2 className="main-title">Создание месторождения</h2>
                <div className="new-project__close" onClick={closeHandler}>
                    <div className="new-project__close-line close"/>
                </div>
            </div>
            <div className="projects__col-row">
                <DataFields
                    value={wellfieldName}
                    label="Название месторождения"
                    onChangeHandler={onChangeHandler}
                    classContainer="new-wellfield__input-container"/>
                <NewWellfieldDropzone
                    onDrop={onDrop}
                    fileName={fileName}
                    fileSize={fileSize}
                    uploadstarted={disabled}
                    onCancelUpload={onCancelUpload}
                    onCancelChecking={onCancelChecking}
                    progress = {progress}
                    canceled = {canceled}
                />
                <Logs
                    checkingCanceled = {canceled}/>
            </div>
            <div className="new-project__bottom">
                <Button
                    disabled={disabled}
                    onClick={createHandler}
                    className="new-wellfield__create-button">
                    Создать
                </Button>
                <Button
                    secondary
                    onClick={cancelHandler}>
                    Отмена
                </Button>
                <Button
                    secondary
                    className="new-wellfield__help-button"
                    onClick={helpHandler}>
                    Помощь
                </Button>
            </div>
        </div>
    </div>
);

export default NewWellfieldView