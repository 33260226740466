import {connect} from 'react-redux';
import {taskLogs} from 'core/state/selectors/projects';
import {removeClickType, changeTaskSettings} from 'core/state/actions/settings';

import {activeTab} from 'core/state/actions/settings'
import {getActiveTab} from 'core/state/selectors/settings'

import MainContainerTopContainer from './MainContainerTopContainer'

const mapStateToProps = state => ({
    taskLogs: taskLogs(state),
    type: getActiveTab(state)
});

const mapDispatchToProps = {
    activeTab,
    removeClickType,
    changeTaskSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainerTopContainer);
