export default type => {
  switch (type) {
    case 'existing':
      return 'Существует';
    case 'calculated':
      return 'Рассчитанный';
    default:
      return '';
  }
};
