import React from 'react';
import { connect } from 'react-redux';
import { getProjectId } from 'core/state/selectors/projects';
import { setRemoveProps, openRemoveAlert } from 'core/state/actions/settings';

const EditProjectName = ({
  projectName,
  projectId,
  setRemoveProps,
  openRemoveAlert,
}) => {
  const showModal = () => {
    setRemoveProps('SUCCESS', {
      id: projectId,
      removeType: 'renameProject',
      name: projectName || 'Без названия',
      title: 'Изменить название',
    });
    openRemoveAlert('SUCCESS', true);
  };
  return <button onClick={showModal} className='icon edit edit-project-name' />;
};

const mapStateToProps = state => ({
  projectId: getProjectId(state),
});

const mapDispatchToProps = {
  setRemoveProps,
  openRemoveAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectName);
