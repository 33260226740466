import React from 'react';
import Popup from '../../Popup';
import { withLeaflet } from 'react-leaflet';
import classnames from 'classnames';
import { GeoJSONFillable } from 'react-leaflet-geojson-patterns';

const AreasView = React.memo(({ stateAreas, setStyle }) => {
  return (
    <>
      {stateAreas.map(data => {
        return (
          <React.Fragment key={data.properties.id}>
            <GeoJSONFillable
              data={data}
              style={setStyle}
              key={data.properties.id}
              className={classnames('areas', data.properties.id)}
            >
              <Popup properties={data.properties} />
            </GeoJSONFillable>
          </React.Fragment>
        );
      })}
    </>
  );
});

export default withLeaflet(AreasView);
