import React from 'react'

import ListWrapperItem from './ListWrapperItem'
import WithCommonObjectsFunc from 'components/HOC/WithCommonObjectsFunc'

const ListWrapper = props => (
    <>
      {Object.keys(props.objects).map((key) => (
          <ListWrapperItem
              key={key}
              itemKey={key}
              {...props} />
      ))}
    </>
);

export default WithCommonObjectsFunc(ListWrapper);