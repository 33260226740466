import React from 'react';

import ExpandedTopMenuView from './ExpandedTopMenuView';

const ExpandedTopMenuContainer = ({ projectName, getActiveTab }) => {
  return (
    <ExpandedTopMenuView
      projectName={projectName}
      getActiveTab={getActiveTab}
    />
  );
};

export default ExpandedTopMenuContainer;
