import React from 'react';
import {connect} from "react-redux";
import {getNewAreaPoints, getPolygonArea} from 'core/state/selectors/settings';
import pluralizeRus from '../../../utils/pluralizeRus'

const Hint = ({getNewAreaPoints, getPolygonArea}) => {
    if (getPolygonArea > 0) {
        return <div className="DataFieldsRow wide areaRow">
            <h2 className="input-label">Область строительства</h2>
            <div
                className={`new-project__area last`}>
                <span className="new-project__first">
                    <span>
                        {`${getNewAreaPoints.length} точ${pluralizeRus(getNewAreaPoints.length, ['кa', 'ки', 'ек'])} `}
                        ({`${getPolygonArea} км`}<span className="pow">2</span>)
                    </span>
                </span>
            </div>
        </div>
    }
    return <div className="alert-row">
        <h2 className="alert-row__title">Нарисуйте область строительства на карте</h2>
    </div>
};

const mapStateToProps = state => ({
    getNewAreaPoints: getNewAreaPoints(state),
    getPolygonArea: getPolygonArea(state)
});

export default connect(mapStateToProps)(Hint);
