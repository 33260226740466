import {all} from 'redux-saga/effects';
import authSaga from '../core/effects/auth';
import commonSaga from '../core/effects/common';
import objectsSaga from '../core/effects/objects';
import projectSaga from '../core/effects/projects';
import wellfieldSaga from '../core/effects/wellfield';

export default function* root() {
    yield all([
        ...authSaga,
        ...commonSaga,
        ...projectSaga,
        ...objectsSaga,
        ...wellfieldSaga
    ])
};
