import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import ButtonList from './ButtonList';
import classnames from 'classnames';
import {activeTab} from 'core/state/actions/settings';
import {getActiveTab} from 'core/state/selectors/settings';

const Handlers = () => {
    const dispatch = useDispatch();
    const type = useSelector(getActiveTab);
    const optionHandler = currentType => {
        dispatch(activeTab('SUCCESS', currentType));
    };
    return (
        <div className="left-sideBar__options">
            <ButtonList optionHandler={optionHandler}/>
            <button
                onClick={() => optionHandler('hide')}
                className={classnames("openLeftSideBar", {'open': type})}>
            </button>
        </div>
    )
};

export default Handlers;
