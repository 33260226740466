import React from 'react'
import ScrollBar from 'components/Common/ScrollBar'

const Quarries = ({ taskResult }) => {
  const config = [
    [
      {
        title: 'Длина дорог, км',
        value: 'total_length',
        divisor: 1000
      },
      {
        title: 'Плечо возки, т*км',
        value: 'total_arm',
        divisor: 523.286237572
      },
      {
        title: 'Объем добычи, м3',
        value: 'total_volume',
        divisor: 1
      }
    ],
    [
      {
        title: 'Стоимость добычи, млн руб.',
        value: 'total_extraction_cost',
        divisor: 1000000
      },
      {
        title: 'Стоимость перевозки, млн руб.',
        value: 'total_transportation_cost',
        divisor: 1000000
      },
      {
        title: 'Стоимость разгрузки, млн руб.',
        value: 'total_unloading_cost',
        divisor: 1000000

      },
      {
        title: 'Общая стоимость, млн руб.',
        value: ['total_extraction_cost', 'total_transportation_cost', 'total_unloading_cost'],
        divisor: 1000000
      }
    ]
  ]
  if (!taskResult[0].props) return null
  return <ScrollBar className="quarries-results">
    <div>
      {config.map((elem, id) => {
        return <div key={id} className="quarries-results__col">
          <div className="quarries-results__sub-col" >
            {elem.map((obj, key) => {
              return <h2 className="quarries-results__title" key={key}>{obj.title}</h2>
            })}
          </div>
          <div className="quarries-results__sub-col">
            {elem.map((obj, key) => {
              let value = ''
              if (typeof obj.value === 'object') {
                value = obj.value.reduce((acc, cv) => acc + taskResult[0].props[cv], 0)
              }
              else {
                value = taskResult[0].props[obj.value]
              }
              value = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(value / obj.divisor)
              return <h2 key={key} className="quarries-results__value">{value}</h2>
            })}
          </div>
        </div>
      })}
    </div>
  </ScrollBar>
}

export default Quarries;