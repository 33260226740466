import React from 'react'
import {Polygon} from 'react-leaflet'

const style = {
    fillColor: '#fff',
    fillOpacity: '0.2',
    opacity: '0'
};

const cornersCoords = [
    [85, -180],
    [85, 180],
    [-85, 180],
    [-85, -180]
];

export default React.forwardRef(({projectArea}, ref) => {
    let coords = [];
    if (projectArea) {
        coords = projectArea.coordinates[0].map(el => [el[1], el[0]]);
    }
    return <Polygon
        ref={ref}
        {...style}
        positions={[cornersCoords, coords]}
    />
})