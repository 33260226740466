export const SET_CENTER = 'SET_CENTER';
export const SET_MAP_TYPE = 'SET_MAP_TYPE';
export const SET_ZOOM = 'SET_ZOOM';
export const SET_VIEW = 'SET_VIEW';
export const MOVE_TO_MAP_CENTER = 'MOVE_TO_MAP_CENTER';
//REQUEST
export const MOVE_TO_MAP_CENTER_REQUEST = 'MOVE_TO_MAP_CENTER_REQUEST';

//SUCCESS
export const SET_VIEW_SUCCESS = 'SET_VIEW_SUCCESS';
export const SET_CENTER_SUCCESS = 'SET_CENTER_SUCCESS';
export const SET_MAP_TYPE_SUCCESS = 'SET_MAP_TYPE_SUCCESS';
export const SET_ZOOM_SUCCESS = 'SET_ZOOM_SUCCESS';
