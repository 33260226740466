import React from 'react';
import PropTypes from 'prop-types';
import ToggleContainer from 'components/Common/ToggleContainer';

const ProjectList = ({download, activeText, openList, remove, projects, activeRow, choseProject, updated}) =>
    projects.map((project, id) => {
        const listAfter = item =>
            <span className="common-component-handlers">
        <span onClick={() => download(item)} className="icon download-icon"></span>
        <span onClick={() => remove(item)} className="field-row__remove"></span>
      </span>;
        return <ToggleContainer
            key={id}
            updated={updated}
            listProps={{
                rowClass: 'toggle-project-row',
                data: project.data,
                chooseRow: choseProject,
                afterComp: listAfter,
                active: activeRow,
                activeText: activeText
            }}
            titleProps={{title: project.title, openList}}
        />;
    });

ProjectList.propTypes = {
    activeRow: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    choseProject: PropTypes.func.isRequired
};

export default ProjectList;