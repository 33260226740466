import React from 'react';

import Hvl from './Hvl';
import Pipes from './Pipes';
import Roads from './Roads';
import Quarries from './Quarries';
import Facility from './Facility';
import LinearCost from './LinearCost';
import GatheringCenters from './GatheringCenters';
import {ErrorsContext} from '../Validation';
import {taskConstants} from 'utils/constants';
import './style.scss';

const {ROADS, HVL, QUARRIES, LINEAR_COST, FACILITY, PIPES, GATHERING_CENTERS} = taskConstants;

const TaskDataComponent = props => {
    const {errors, clearErrors} = React.useContext(ErrorsContext);
    React.useEffect(() => {
        clearErrors();
    }, [props.children]);
    const children = React.Children.map(props.children,
        child => React.cloneElement(child, {errors}));
    return <>{children}</>
};


export default React.memo(({type}) => {
    let Component = null;
    if (PIPES.includes(type)) {
        Component = Pipes
    } else if (type === LINEAR_COST) {
        Component = LinearCost
    } else if (type === ROADS) {
        Component = Roads
    } else if (type === FACILITY) {
        Component = Facility
    } else if (type === HVL) {
        Component = Hvl
    } else if (type === QUARRIES) {
        Component = Quarries
    } else if (type === GATHERING_CENTERS) {
        Component = GatheringCenters
    } else {
        return null
    }
    return <TaskDataComponent>
        <Component/>
    </TaskDataComponent>
}, (prevProps, nextProps) => {
    return prevProps.type === nextProps.type
});
