import { connect } from 'react-redux';
import { projectPalette } from 'core/state/selectors/projects';
import { connectionPoints } from 'core/state/selectors/objects';
import {
  connectionTypes,
  sortClassesByMetaclasses,
} from 'core/state/selectors/wellfield';
import ConnectionPointsContainer from './ConnectionPointsContainer';

const mapStateToProps = state => ({
  projectPalette: projectPalette(state),
  connectionTypes: connectionTypes(state),
  connectionPoints: connectionPoints(state),
  sortClassesByMetaclasses: sortClassesByMetaclasses(state),
});

export default connect(mapStateToProps)(ConnectionPointsContainer);
