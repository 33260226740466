import React, { useEffect, useState } from 'react';

import coloringObj from 'utils/coloringObj';
import groupBy from 'utils/groupBy';
import _get from 'lodash.get';

import setSubtitle from './utils/setSubtitle';
import setCondition from './utils/setCondition';
import ListWrapper from '../../MainContainer/common/ListWrapper';
import WithCommonObjectsFunc from 'components/HOC/WithCommonObjectsFunc';

const LinearsList = props => {
  const [objects, setObjects] = useState({});
  useEffect(() => {
    const updateObjects = props => {
      const { linears, networks, getMetaclassesSortedById } = props;
      if (linears) {
        let linearObjects = {};
        const objectsByMetaclass = groupBy(linears, 'metaclass_id');
        Object.keys(objectsByMetaclass).forEach(key => {
          const title = _get(getMetaclassesSortedById[key], '[0].name');
          const objects = objectsByMetaclass[key];
          let beforeProp = coloringObj(
            props.projectPalette[objects[0].class_id]
          );
          beforeProp = beforeProp ? beforeProp.color : null;
          linearObjects[key] = {
            title: title,
            beforeProp,
          };
          const objectsByNetwork = groupBy(objects, 'first_network');
          const objectsWithoutNetwork = objectsByNetwork['without_network'];
          delete objectsByNetwork['without_network'];
          const objectsWithoutNetworkByType = groupBy(
            objectsWithoutNetwork,
            'type'
          );
          delete objectsWithoutNetworkByType['calculated_tmp'];
          if (Object.keys(objectsWithoutNetworkByType).length) {
            Object.keys(objectsWithoutNetworkByType).forEach(subKey => {
              if (
                getMetaclassesSortedById[key][0].palette !== 'forest_corridor'
              ) {
                linearObjects[key].subLayer = {
                  [subKey]: {
                    beforeProp,
                    title: setSubtitle(subKey),
                    values: objectsWithoutNetworkByType[subKey],
                  },
                };
              } else {
                linearObjects[key] = {
                  ...linearObjects[key],
                  values: objectsWithoutNetworkByType[subKey],
                };
              }
            });
          }
          Object.keys(objectsByNetwork).forEach(networkKey => {
            const objectsByNetworkByType = groupBy(
              objectsByNetwork[networkKey],
              'type'
            );
            delete objectsByNetworkByType['calculated_tmp'];
            if (Object.keys(objectsByNetworkByType).length) {
              Object.keys(objectsByNetworkByType).forEach(subKey => {
                if (!linearObjects[key].subLayer) {
                  linearObjects[key].subLayer = {};
                }
                if (!linearObjects[key].subLayer[subKey]) {
                  linearObjects[key].subLayer[subKey] = {
                    beforeProp,
                    title: setSubtitle(subKey),
                  };
                }
                if (!linearObjects[key].subLayer[subKey].subLayer) {
                  linearObjects[key].subLayer[subKey].subLayer = {};
                }
                if (networks[networkKey]) {
                  linearObjects[key].subLayer = {
                    ...linearObjects[key].subLayer,
                    [subKey]: {
                      ...linearObjects[key].subLayer[subKey],
                      subLayer: {
                        ...linearObjects[key].subLayer[subKey].subLayer,
                        [networkKey]: {
                          beforeProp,
                          containerClass: 'network',
                          networkId: networks[networkKey][0],
                          title: networks[networkKey][0].name,
                          values: objectsByNetworkByType[subKey],
                          taskId:
                            objectsByNetworkByType[subKey][0].props.task_id,
                        },
                      },
                    },
                  };
                }
              });
            }
          });
        });
        Object.keys(linearObjects).forEach(key => {
          if (!linearObjects[key].subLayer && !linearObjects[key].values) {
            delete linearObjects[key];
          }
        });
        setObjects(linearObjects);
      }
    };
    updateObjects(props);
  }, [props]);

  const showRow = ({ id }) => {
    props.hideLinears('SUCCESS', id);
  };
  const showList = (item, subLayerKey, subLayerParent) => {
    if (!subLayerKey && !subLayerParent) {
      props.hideLinearsMetaclass('SUCCESS', item);
    } else if (subLayerKey && !subLayerParent) {
      props.hideLinearsByMetaclassAndType('SUCCESS', {
        metaclass: item,
        types: subLayerKey,
      });
    } else {
      props.hideLinearsByMetaclassAndNetwork('SUCCESS', {
        metaclass: item,
        networks: subLayerKey,
        types: subLayerParent,
      });
    }
  };
  const removeItem = ({ name, id, class_id, type }) => {
    const {
      setRemoveProps,
      openRemoveAlert,
      sortClassesByMetaclasses,
      sortMetaclassesByClasses,
    } = props;
    const { title: objectType } = _get(
      sortClassesByMetaclasses,
      sortMetaclassesByClasses[class_id]
    );
    const condition =
      objectType.toLowerCase() === 'просеки' ? '' : setCondition(type);
    setRemoveProps('SUCCESS', {
      id,
      name: name || 'Без названия',
      objectType,
      typeTitle: 'объекта',
      removeType: 'linear',
      title: 'линейного объекта',
      questionTitle: 'линейный объект',
      condition,
    });
    openRemoveAlert('SUCCESS', true);
  };
  const removeMetaclass = id => {
    const { setRemoveProps, openRemoveAlert, sortClassesByMetaclasses } = props;
    const { title: objectType } = _get(sortClassesByMetaclasses, id);
    setRemoveProps('SUCCESS', {
      id,
      objectType,
      typeTitle: 'объекта',
      removeType: 'metaclass',
      title: 'группы линейных объектов',
      questionTitle: 'группу линейных объектов',
    });
    openRemoveAlert('SUCCESS', true);
  };
  const removeMetaclassByType = (metaclassId, type, subLayerParent) => {
    const {
      networks,
      setRemoveProps,
      openRemoveAlert,
      sortClassesByMetaclasses,
    } = props;
    const removeType = ['calculated', 'existing'].includes(type)
      ? 'metaclassByType'
      : 'network';
    const { title: objectType } = _get(sortClassesByMetaclasses, metaclassId);
    let id;
    let title;
    let questionTitle;
    let name;
    let condition;
    if (removeType === 'network') {
      id = type;
      name = networks[id][0].name;
      title = 'сети линейных объектов';
      questionTitle = 'сеть линейных объектов';
      condition = setCondition(subLayerParent);
    } else {
      id = metaclassId;
      title = 'группы линейных объектов';
      questionTitle = 'группу линейных объектов';
      condition = setCondition(type);
    }
    setRemoveProps('SUCCESS', {
      id,
      type,
      name,
      title,
      objectType,
      removeType,
      condition,
      questionTitle,
      typeTitle: 'объекта',
    });
    openRemoveAlert('SUCCESS', true);
  };
  const handleTask = (id, type, task) => {
    const {
      taskType,
      activeTab,
      setTaskId,
      getTaskResult,
      setTaskFacilities,
    } = props;
    if (task.type) {
      taskType('SUCCESS', task.type);
    }
    if (task.props) {
      setTaskFacilities('SUCCESS', task.props.facilities || []);
    }
    getTaskResult('REQUEST', { id, type });
    setTaskId('SUCCESS', task.id);
    activeTab('SUCCESS', 'taskInfo');
  };
  const openTask = ({ taskId }) => {
    const item = props.getTasksById[taskId];
    if (item) {
      const { id, type } = item[0];
      props.setOpenedTasks('SUCCESS', {
        taskId: id,
        currentHandler: 'results',
      });
      handleTask(id, type, item[0]);
    } else {
      props.setInfoLogs('SUCCESS', 'Задачи не существует');
    }
  };
  const chooseRow = item => {
    props.setCardInfo('SUCCESS', {
      type: 'linear',
      data: item,
    });
    props.activeTab('SUCCESS', 'linearInfoCard');
  };
  if (!props.linears) return <h2 className='preload-text'>Идёт загрузка...</h2>;
  if (!Object.keys(objects).length) {
    return <h2 className='preload-text'>Список линейных объектов пуст</h2>;
  }
  return (
    <ListWrapper
      objects={objects}
      showRow={showRow}
      type={props.type}
      showList={showList}
      openTask={openTask}
      chooseRow={chooseRow}
      removeItem={removeItem}
      updated={props.updated}
      removeMetaclass={removeMetaclass}
      removeMetaclassByType={removeMetaclassByType}
    />
  );
};

export default WithCommonObjectsFunc(LinearsList);
