import set from 'lodash.set';
import get from 'lodash.get';

import {
    TASK_TYPE_SUCCESS,
    CLICK_TYPE_SUCCESS,
    ACTIVE_TAB_SUCCESS,
    SHOW_MODAL_SUCCESS,
    SHOW_LOADER_SUCCESS,
    TASK_STARTED_SUCCESS,
    SET_CARD_INFO_SUCCESS,
    ADD_TASK_POINTS_SUCCESS,
    SET_REMOVE_PROPS_SUCCESS,
    OPEN_REMOVE_ALERT_SUCCESS,
    SET_TASK_SETTINGS_SUCCESS,
    REMOVE_CLICK_TYPE_SUCCESS,
    SHOW_TASK_RESULTS_SUCCESS,
    SET_DEFAULT_PROPS_SUCCESS,
    SET_TASK_FACILITIES_SUCCESS,
    CHANGE_TASK_SETTINGS_SUCCESS,
    SET_DATA_FOR_SENDING_SUCCESS,
    SET_ACTIVE_FACILITIES_SUCCESS,
    SET_TRANSFORM_SETTINGS_SUCCESS
} from '../constants/settings'

import {taskConstants} from 'utils/constants';

import {
    CLEAR_STATE_SUCCESS,
    GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE_SUCCESS
} from '../constants/projects';

import addOrRemoveItem from 'utils/addOrRemoveItem';

const {PIPES, ...taskConst} = taskConstants;

const initialState = {
    transformSettings: {
        draw: true,
        draggable: true,
        transform: {
            scaling: false,
            rotation: false
        }
    },
    taskType: null,
    showModal: false,
    showHelpModal: false,
    showCancelConfirmationModal: false,
    showRemoveAlert: false,
    dataForSending: {},
    taskSettings: {
        props: {}
    },
    activeObject: {},
    removeProps: {},
    activeTab: 'objects',
    lastActiveTab: 'objects',
    clickType: {
        ruler: false,
        createFacility: false
    },
    cardInfo: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DATA_FOR_SENDING_SUCCESS: {
            return {
                ...state,
                dataForSending: {
                    [action.payload.type]: action.payload.data
                }
            }
        }
        case ADD_TASK_POINTS_SUCCESS:
            const pointKeys = Object.keys(action.payload);
            let pointsArr = [];
            if (!state.taskSettings.props[pointKeys]) {
                pointsArr.push(action.payload[pointKeys]);
            } else {
                if (!state
                    .taskSettings.props[pointKeys]
                    .includes(action.payload[pointKeys])) {
                    pointsArr = [
                        ...state.taskSettings.props[pointKeys],
                        action.payload[pointKeys]
                    ];
                } else {
                    const arrayToExclude = [...state.taskSettings.props[pointKeys]];
                    const index = arrayToExclude.indexOf(action.payload[pointKeys]);
                    if (index !== -1) {
                        arrayToExclude.splice(index, 1);
                        pointsArr = arrayToExclude;
                    }
                }
            }
            return {
                ...state,
                taskSettings: {
                    ...state.taskSettings,
                    props: {
                        ...state.taskSettings.props,
                        [pointKeys]: pointsArr
                    }
                }
            };

        case SET_ACTIVE_FACILITIES_SUCCESS: {
            return {
                ...state,
                activeFacilities: action.payload
            }
        }
        case SET_CARD_INFO_SUCCESS: {
            return {
                ...state,
                cardInfo: action.payload
            }
        }
        case SHOW_MODAL_SUCCESS: {
            const {type, option} = action.payload;
            return {
                ...state,
                showModal: type,
                modalOption: option
            }
        }
        case GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE_SUCCESS: {
            return {
                ...state,
                facilitiesFromProjectByTaskType: action.payload
            }
        }
        case SHOW_LOADER_SUCCESS: {
            return {
                ...state,
                showLoader: action.payload
            }
        }
        case CLEAR_STATE_SUCCESS: {
            return initialState
        }
        case SET_REMOVE_PROPS_SUCCESS: {
            return {
                ...state,
                removeProps: {
                    ...action.payload
                }
            }
        }
        case OPEN_REMOVE_ALERT_SUCCESS: {
            if (!action.payload) {
                return {
                    ...state,
                    removeProps: {},
                    showRemoveAlert: action.payload
                }
            }
            return {
                ...state,
                showRemoveAlert: action.payload
            }
        }
        case ACTIVE_TAB_SUCCESS: {
            let lastActiveTab = [
                ...PIPES,
                ...Object.keys(taskConst).map(key => taskConst[key])
            ].includes(state.activeTab)
                ? 'tasks' : state.activeTab;
            if (['createLinearObject', 'createFacility', 'createArea'].includes(state.activeTab)) {
                lastActiveTab = state.lastActiveTab
            }

            let buttonType = '';
            if (!['hide', state.activeTab].includes(action.payload)) {
                buttonType = action.payload
            }
            if (!state.activeTab && action.payload === 'hide') {
                buttonType = state.lastActiveTab;
            }
            return {
                ...state,
                lastActiveTab,
                activeTab: buttonType
            }
        }
        case SET_DEFAULT_PROPS_SUCCESS: {
            return {
                ...state,
                taskSettings: {
                    ...state.taskSettings,
                    props: {
                        ...state.taskSettings.props,
                        ...action.payload
                    }
                }
            }
        }
        case TASK_STARTED_SUCCESS: {
            return {
                ...state,
                activeTasks: addOrRemoveItem(state.activeTasks, action.payload)
            }
        }
        case SHOW_TASK_RESULTS_SUCCESS: {
            return {
                ...state,
                showResults: action.payload
            }
        }
        case SET_TRANSFORM_SETTINGS_SUCCESS: {
            const transformSettings = {
                draggable: false,
                transform: {
                    scaling: false,
                    rotation: false
                }
            };
            set(transformSettings, action.payload, true);
            return {
                ...state,
                transformSettings
            }
        }
        case TASK_TYPE_SUCCESS:
            return {
                ...state,
                taskType: action.payload
            };
        case SET_TASK_SETTINGS_SUCCESS:
            return {
                ...state,
                taskSettings: action.payload
            };
        case CHANGE_TASK_SETTINGS_SUCCESS:
            const keys = Object.keys(action.payload);
            const taskSettings = Object.assign({}, state.taskSettings);
            for (let key of keys) {
                set(taskSettings, key, action.payload[key])
            }
            return {
                ...state,
                taskSettings
            };
        case CLICK_TYPE_SUCCESS:
            const clickType = Object.assign({}, initialState.clickType);
            if (Array.isArray(action.payload)) {
                for (let item of action.payload) {
                    set(clickType, item, true);
                }
            } else if (typeof action.payload === 'object') {
                for (let item in action.payload) {
                    set(clickType, item, action.payload[item]);
                }
            } else {
                set(clickType, action.payload, !get(state.clickType, action.payload))
            }
            return {
                ...state,
                clickType
            };
        case REMOVE_CLICK_TYPE_SUCCESS: {
            return {
                ...state,
                clickType: initialState.clickType
            }
        }
        case SET_TASK_FACILITIES_SUCCESS:
            return {
                ...state,
                taskFacilities: action.payload
            };
        default:
            return state;
    }
}
