import React from "react";
import ListElem from './ListElem';
import coloringObj from "utils/coloringObj";

export default function List({metaclassesByType, type, clickType, handleClick, colorType}) {
    return metaclassesByType[type]
        .filter(elem => elem.name)
        .map((elem, id) => {
            const {palette, name} = elem;
            const backgroundColor = coloringObj(palette)[colorType];
            return <ListElem
                key={id}
                elem={elem}
                name={name}
                clickType={clickType}
                handleClick={handleClick}
                backgroundColor={backgroundColor}
            />
        });
};
