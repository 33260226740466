export default {
  sendArea: '/api/areas',
  sendLinear: '/api/linears',
  sendProjectUrl: '/api/projects',
  sendFacility: '/api/facilities',
  layout: layout_id => `api/layouts/${layout_id}`,
  downloadProject: project_id => `/api/projects/${project_id}/dump`,
  downloadTask: task_id => `/api/tasks/${task_id}/report`,
  sendNewTaskUrl: '/api/tasks',
  removeTask: id => `/api/tasks/${id}`,
  cancelTask: id => `/api/tasks/${id}/cancel`,
  removeNetwork: networkId => `api/networks/${networkId}`,
  getNetworks: project_id => `/api/projects/${project_id}/networks?max_results=100500`,
  linearTaskTrace: id => `/api/linear_task_traces?where={"task_id":"${id}"}&max_results=100500`,
  facilityTaskTrace: id => `/api/facility_task_traces?where={"task_id":"${id}"}&max_results=100500`,
  sendTaskResult: task_result_id => `/api/task_results/${task_result_id}/apply`,
  areasById: id => `/api/areas/${id}?embedded={"connection_points":1}`,
  linearById: id => `/api/linears/${id}?embedded={"connection_points":1}`,
  classifierUrl: id => `/api/classifier/${id}/facility`,
  projectById: id => `/api/projects/${id}`,
  calculateTaskUrl: id => `/api/tasks/${id}/calculate`,
  classesPalette: id => `/api/wellfields/${id}/classes_pallete?max_results=100500`,
  removeСlass: (key, projectId) => `/api/projects/${projectId}/classes/${key}/facilities`,
  removeMetaclass: (key, projectId) => `/api/projects/${projectId}/metaclasses/${key}`,
  taskResult: id => `/api/task_results?where={"task_id":"${id}"}&max_results=100500&sort=[("id", -1)]`,
  getTask: id => `/api/projects/${id}/tasks`,
  getAvaliableTaskTypes: project_id => `/api/projects/${project_id}/available_task_types`,
  classesByMetaclassUrl: id => `/api/classes?where={"metaclass": "${id}"}&max_results=100500`,
  removeByTypeMetaclass: (key, projectId, type) => `/api/projects/${projectId}/metaclasses/${key}/${type}`,
  metaclassesByWellfieldAndTypeUrl: (id, type) => `/api/metaclasses?where={"wellfield_id": "${id}", "type": "${type}"}`,
  getFacilitiesFromProjectByTaskType: (project_id, task_type) => `/api/projects/${project_id}/tasks/${task_type}/facilities`
}
