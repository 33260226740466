import { useState, useEffect } from 'react';
import patternsSettings from 'utils/patterns';

export default () => {
  const [patterns, setPatterns] = useState({});
  useEffect(() => {
    const patternsObj = {};
    Object.keys(patternsSettings).forEach(key => {
      let patternConfig = {};
      if (key === 'forest') {
        patternConfig = {
          patternTransform: 'scale(1.3)',
        };
      }
      patternConfig = {
        ...patternsSettings[key],
        ...patternConfig,
        key,
        width: 60,
        height: 60,
      };
      patternsObj[key] = patternConfig;
    });
    setPatterns(patternsObj);
  }, []);
  return patterns;
};
