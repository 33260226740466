import React, {useState, useRef } from 'react';
import ScrollBar from '../../ScrollBar';
import CustomSelectValues from './CustomSelectValues';
import classnames from 'classnames';

export default ({ options }) => {
    const node = useRef(null);
    const [active, setActive] = useState(false);
    const [activeOptions, setActiveOptions] = useState(options);
    const [inputValue, setInputValue] = useState({value:'',label:''});
    const [lastInputValue, setLastInputValue] = useState({value:'',label:''});
    const onFocusHandler = () => {
        setActive(true);
    };
    const chooseItemHandler = value => {
      setInputValue(value);
      setLastInputValue(value);
      setActiveOptions(options);
      setActive(false);
    };
    const onChangeHandler = ({target: {value}}) => {
        const lastValue = value.slice(-1);
        if(lastValue) {
            const filteredData = options
                .filter(({ label }) => label
                    .toLowerCase()
                    .includes(lastValue));
            setInputValue({value: '', label: value.slice(-1)});
            setActiveOptions(filteredData);
        }
        else {
            setInputValue(lastInputValue);
            setActiveOptions(options);
        }
    };
    return <div
        ref={node}
        className={classnames('custom-select__container', {active})}>
        <input
            type="text"
            className='DataFields'
            value={inputValue.label}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
        />
        <svg
            width="6"
            height="3"
            viewBox="0 0 6 3"
            className="custom-select__triangle"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 3L3 0L0 3H6Z"
                fill="#77F4E8"/>
        </svg>
        {active && !!activeOptions.length &&
        <ScrollBar
            className='custom-select'
            style={{
                minHeight: 32*options.length > 200 ? 200:32*options.length
            }}
        >
            <CustomSelectValues
                node={node}
                options={activeOptions}
                chooseItem={chooseItemHandler}
                hideValues={() => setActive(false)}
            />
        </ScrollBar>
        }
    </div>
}