import React, {useState, useEffect} from 'react';
import TasksList from './TasksList';

import classnames from 'classnames';
import handleTitle from '../utils/handleTitle';
import groupBy from 'utils/groupBy';
import sortByTitle from 'utils/sortByTitle';

export default props => {
    const [data, setData] = useState(false);
    useEffect(() => {
        const setTaskList = props => {
            const {getTasksById} = props;
            if (getTasksById) {
                const sortedTasks = groupBy(Object.keys(getTasksById).map(key => getTasksById[key][0]), 'type');

                const obj = Object.keys(sortedTasks)
                    .map(key => ({
                        titleProps: {afterComp: () => <span className={classnames('task-toggle__icon', key)}/>},
                        listProps: {containerClass: 'task-row'},
                        itemKey: key,
                        title: handleTitle(key),
                        values: sortedTasks[key].sort(sortByTitle('title'))
                    }))
                    .sort(sortByTitle('title'));
                setData(obj);
            } else {
                setData(false)
            }
        };
        setTaskList(props)
    }, [props]);

    const handleTask = (id, type, task) => {
        const {taskType, setTaskId, getTaskResult, setTaskFacilities} = props;
        if (task.type) {
            taskType('SUCCESS', task.type)
        }
        if (task.props) {
            setTaskFacilities('SUCCESS', props.facilities || [])
        }
        getTaskResult('REQUEST', {id, type});
        setTaskId('SUCCESS', task.id)
    };

    const active = () => {
    };
    const chooseRow = item => {
        const {id, type} = item;
        handleTask(id, type, item);
        props.activeTab('SUCCESS', 'taskInfo')
    };

    const removeHandler = ({id, name, type}) => {
        const {setRemoveProps, openRemoveAlert} = props;
        setRemoveProps('SUCCESS', {
            id,
            name: name || 'Без названия',
            objectType: handleTitle(type),
            title: 'задачи',
            typeTitle: 'задачи',
            removeType: 'task',
            type,
            questionTitle: 'задачу'
        });
        openRemoveAlert('SUCCESS', true)
    };

    const repeatTask = ({id, name}) => {
        props.repeatTask('REQUEST', {id, name})
    };

    const downloadTask = ({id, name}) => {
        props.downloadTask('REQUEST', {id, name})
    };

    if (!data) return <h2 className="preload-text">Идёт загрузка...</h2>;

    if (!data.length) return <h2 className="preload-text">Список задач пуст</h2>;

    return (
        <div className="taskContainer">
            {data.map((item, id) => <TasksList
                key={id}
                data={item}
                active={active}
                chooseRow={chooseRow}
                updated={props.updated}
                repeatTask={repeatTask}
                downloadTask={downloadTask}
                removeHandler={removeHandler}/>)}
        </div>
    )
};
