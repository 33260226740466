import React from 'react'
import { connect } from 'react-redux'
import { newWellfieldLogs } from 'core/state/selectors/common'

import LogsContainer from './LogsContainer'

const LogsRedux = ({ ...props }) => <LogsContainer {...props} />

const mapStateToProps = state => ({
  newWellfieldLogs: newWellfieldLogs(state)
})

export default connect(mapStateToProps)(LogsRedux)