import React from "react";

export default ({type, setZoom}) => {
    const clickHandler = () => {
        const zoom = type === 'in' ? 1 : -1;
        setZoom(zoom);
    };
    return <span
        onClick={clickHandler}
        className={`rightMenuZoomIcon ${type}`}
    />
}