import React from 'react';
import {connect} from 'react-redux';
import {getProjectArea} from 'core/state/selectors/projects';
import SplashPolyContainer from './SplashPolyContainer';

const SplashPolyRedux = ({projectArea, splashRef}) => {
    return <SplashPolyContainer projectArea={projectArea} ref={splashRef}/>
};

const mapStateToProps = state => ({
    projectArea: getProjectArea(state)
});

export default connect(mapStateToProps)(SplashPolyRedux);