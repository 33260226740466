import React from 'react';
import BottomRow from './BottomRow';
import handleTitle from './utils/handleTitle';

const MainContainerBottomContainer = props => {
    const removeHandler = e => {
        e.preventDefault();
        props.removeClickType('SUCCESS');
        props.changeTaskSettings('SUCCESS', {});
        if (props.type === 'taskInfo') {
            const {type, id, name} = props.taskInfo;
            props.setRemoveProps('SUCCESS', {
                id,
                type,
                title: 'задачи',
                removeType: 'task',
                typeTitle: 'задачи',
                questionTitle: 'задачу',
                name: name || 'Без названия',
                objectType: handleTitle(type),
            });
            props.openRemoveAlert('SUCCESS', true)
        } else {
            props.setTaskSettings('SUCCESS', {
                props: {}
            });
            props.getFacilitiesFromProjectByTaskType('SUCCESS', false);
            props.activeTab('SUCCESS', props.lastActiveTab);
        }
    };
    return <div className="taskSettingsContainerRow taskSettingsContainerBottom">
        <BottomRow secondButtonHandler={removeHandler}/>
    </div>
};

export default MainContainerBottomContainer;
