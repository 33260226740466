import React, {Component} from 'react';
import {connect} from 'react-redux';
import {cardInfo} from 'core/state/selectors/settings';
import {getTaskResult} from 'core/state/actions/projects';
import {taskId, taskResult, getTasksById} from 'core/state/selectors/projects';
import {setOpenedTasks, setListsToggle} from 'core/state/actions/groupsSettings';
import {oppenedTasks, getLinearsToggle} from 'core/state/selectors/groupsSettings';

import _get from 'lodash.get';
import TaskLogs from './TaskLogs';
import {taskConstants} from 'utils/constants';
import ScrollBar from 'components/Common/ScrollBar';
import TaskResultsTopRow from './TaskResultsTopRow';
import TaskResultComponent from './TaskResultComponent';

class MainContainer extends Component {
    state = {
        type: '',
        taskData: [],
        taskResult: false,
        taskResValues: [],
        currentHandler: 'logs',
        selectedResult: {value: 0}
    };
    toggleHandler = cond => {
        if (['cost', 'profile', 'distribution'].includes(cond)) {
            this.setState({currentHandler: cond});
            if (_get(this.props, ['cardInfo', 'data', 'id'])) {
                this.props.setListsToggle('SUCCESS', {
                    id: _get(this.props, ['cardInfo', 'data', 'id']),
                    currentHandler: cond
                })
            }
        }
        if (['logs', 'results'].includes(cond)) {
            if (cond === 'results') {
                const {taskResult, taskId, getTasksById} = this.props;
                if (taskResult && taskResult && !taskResult.length && taskId) {
                    if (getTasksById[taskId]) {
                        this.props.getTaskResult('REQUEST', {
                            id: taskId,
                            type: getTasksById[taskId][0].type
                        });
                    }
                }
                this.setState({
                    type: getTasksById[taskId][0].type,
                    taskResult: taskResult,
                    currentHandler: cond
                });
            } else {
                this.setState({currentHandler: cond});
            }
            if (this.props.taskId) {
                this.props.setOpenedTasks('SUCCESS', {
                    taskId: this.props.taskId,
                    currentHandler: cond
                })
            }
        }
    };
    handleProps = props => {
        const {taskResult, getTasksById, cardInfo, taskId} = props;
        if (!taskResult || !taskResult.length) {
            this.setState({
                taskData: [],
                taskResValues: [],
                taskResult: false
            })
        }
        if (taskResult) {
            if (taskResult[0]) {
                const type = getTasksById[taskId][0].type;
                if ([
                    taskConstants.HVL,
                    taskConstants.ROADS,
                    ...taskConstants.PIPES
                ].includes(type)) {
                    this.setState(() => {
                        return {
                            resultOptions: [
                                {
                                    value: 0,
                                    label: 'Сравнение результатов'
                                },
                                ...taskResult.map(({props}, id) => {
                                    const {network_id} = props;
                                    return {
                                        value: network_id,
                                        label: `Характеристики варианта ${id + 1}`
                                    }
                                })
                            ]
                        }
                    })
                }
                this.setState(() => ({type, taskResult}));
            } else {
                if (cardInfo) {
                    let currentHandler = 'cost';
                    if (props.taskId) {
                        if (props.oppenedTasks[props.taskId]) {
                            currentHandler = props.oppenedTasks[props.taskId].currentHandler;
                        }
                    }
                    if (_get(props, ['cardInfo', 'data', 'id'])) {
                        if (_get(props.getLinearsToggle, [_get(props, ['cardInfo', 'data', 'id'])])) {
                            currentHandler = props.getLinearsToggle[_get(props, ['cardInfo', 'data', 'id'])].currentHandler;
                        }
                    }
                    const {type, data: taskResult} = cardInfo;
                    this.setState(() => ({type, taskResult, currentHandler}));
                }
            }
        } else {
            const {type, data: taskResult} = cardInfo;
            this.setState(() => ({type, taskResult, currentHandler: 'cost'}));
        }
    };

    componentWillReceiveProps(nextProps) {
        this.handleProps(nextProps)
    }

    componentDidMount() {
        this.handleProps(this.props);
        if (this.props.taskId) {
            if (this.props.oppenedTasks[taskId]) {
                this.setState(() => ({
                    currentHandler: this.props.oppenedTasks[taskId].currentHandler
                }))
            }
        }
        if (_get(this.props, ['cardInfo', 'data', 'id'])) {
            if (_get(this.props.getLinearsToggle, [_get(this.props, ['cardInfo', 'data', 'id'])])) {
                this.setState(() => ({
                    currentHandler: this.props.getLinearsToggle[_get(this.props, ['cardInfo', 'data', 'id'])].currentHandler
                }))
            }
        }
    }

    handleResults = selectedResult => {
        this.setState({
            selectedResult
        })
    };

    render() {
        const {
            type,
            taskData,
            taskResult,
            taskResValues,
            resultOptions,
            selectedResult,
            currentHandler
        } = this.state;
        return (
            <div className="task-complete">
                <TaskResultsTopRow
                    type={type}
                    taskResult={taskResult}
                    resultOptions={resultOptions}
                    currentHandler={currentHandler}
                    selectedResult={selectedResult}
                    handleResults={this.handleResults}
                    toggleHandler={this.toggleHandler}
                />
                <div className='task-complete__main-container'>
                    <ScrollBar>
                        {
                            ['results', 'cost', 'profile', 'distribution'].includes(currentHandler) ?
                                <TaskResultComponent
                                    type={type}
                                    taskData={taskData}
                                    taskResult={taskResult}
                                    taskResValues={taskResValues}
                                    currentHandler={currentHandler}
                                    selectedResult={selectedResult}
                                /> :
                                <TaskLogs/>
                        }
                    </ScrollBar>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    taskId: taskId(state),
    cardInfo: cardInfo(state),
    taskResult: taskResult(state),
    getLinearsToggle: getLinearsToggle(state),
    oppenedTasks: oppenedTasks(state),
    getTasksById: getTasksById(state)
});

const mapDispatchToProps = {
    setOpenedTasks,
    setListsToggle,
    getTaskResult
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
