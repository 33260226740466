import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { setView } from 'core/state/actions/map'
import { projectPalette } from 'core/state/selectors/projects'
const WithCommonObjectsFunc = ComposedComponent => props => {
  const setViewToPolygonOrLine = coordinates => {
    let lat = 0
    let lng = 0
    coordinates.forEach(item => {
      lat += item[1]
      lng += item[0]
    })
    lat = lat / coordinates.length
    lng = lng / coordinates.length
    props.setView('SUCCESS', { lat, lng })
  };
  const setView = item => {
    if (item.center_point) {
      const [lng, lat] = item.center_point.coordinates;
      props.setView('SUCCESS', { lat, lng })
    }
    if (item.geom) {
      const { type, coordinates } = item.geom;
      if (type === 'LineString') {
        setViewToPolygonOrLine(coordinates)
      }
      if (type === 'Polygon') {
        setViewToPolygonOrLine(coordinates[0])
      }
    }
  };
  const handleActiveObject = (id, type) => {
    if (type !== 'network') {
      const elem = document.getElementsByClassName(`${id}`)[0];
      if (elem) {
        elem.classList.add('active', 'active-element')
      }
    }
    else {
      const elems = document.getElementsByClassName(`network-${id}`);
      if (elems) {
        Array.from(elems).forEach(elem => {
          elem.classList.add('active', 'active-element')
        })
      }
    }

  };
  const onMouseEnter = item => {
    handleActiveObject(item.id, item.type)
  };
  const onMouseLeave = (item, type) => {
    const elems = document.getElementsByClassName(`active active-element`)
    if (elems) {
      Array.from(elems).forEach(elem => {
        elem.classList.remove('active', 'active-element')
      })
    }
  };
  return <ComposedComponent
    {...props}
    setView={setView}
    onMouseLeave={onMouseLeave}
    onMouseEnter={onMouseEnter} />
};

const mapDispatchToProps = {
  setView
};

const mapStateToProps = state => ({
  projectPalette: projectPalette(state)
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  WithCommonObjectsFunc
);