import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {networkId} from 'core/state/selectors/settings';

export default () => {
    const network = useSelector(networkId);
    useEffect(() => {
        document.querySelectorAll('.roads.active').forEach(el => {
            if (!el.getAttribute('class').includes(network)) {
                el.classList.remove('active');
            }
        });
        if (network) {
            document.querySelectorAll(`.network-${network}`).forEach(el => {
                if (!el.classList.contains('active')) {
                    el.classList.add('active');
                }
            });
        }
    }, [network])
};
