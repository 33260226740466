import { connect } from 'react-redux';
import { taskResult } from 'core/state/selectors/projects';
import { setNewPoPosition } from 'core/state/actions/projects';
import FacilityTaskResultContainer from './FacilityTaskResultContainer';

const mapStateToProps = state => ({
    taskResult: taskResult(state)
});

const mapDispatchToProps = {
    setNewPoPosition
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilityTaskResultContainer);
