import React from 'react'
import classNames from 'classnames'

export default React.memo(({onClick, clickType, title, active, type}) => {
    const onClickHandler = () => {
        if (typeof onClick === 'function') {
            onClick(clickType);
        }
    };
    return <button
        onClick={onClickHandler}
        className={classNames('handlers__button', {active})}>
        <span className={classNames('handlers__icon', [type])}/>
        <span className="handlers__button-text">{title}</span>
    </button>;
}, (prev, next) => {
    return prev.active === next.active;
});