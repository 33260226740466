import React, { useRef } from 'react';
import classnames from 'classnames';
import _get from 'lodash.get';
import { GeoJSON, Marker } from 'react-leaflet';
import Popup from '../../Popup';
import { divIcon } from 'leaflet';

import './style.scss';

export default ({
  data,
  onClickHandler,
  position,
  class_id,
  id,
  type,
  name,
  style,
  icon,
  className,
  projectPalette,
  showRuler,
}) => {
  const ref = useRef(null);
  const markerHtmlStyles = `background-image: url(${icon});width: 16px;height: 16px;`;
  const customIcon = divIcon({
    className: `custom-pin facility-pin ${className || ''}`,
    iconSize: [16, 16],
    iconAnchor: [8, 8],
    html: `<div style="${markerHtmlStyles}" />`,
  });
  const onMouseOut = () => {
    const elem = document.getElementsByClassName(`active for-hover`)[0];
    if (elem) {
      elem.classList.remove('active');
    }
  };
  const onMouseOver = () => {
    const elem = document.getElementsByClassName(`${id} for-hover`)[0];
    if (elem && !elem.classList.contains('active')) {
      elem.classList.add('active');
    }
  };
  return (
    <React.Fragment key={id}>
      <GeoJSON data={data} className={classnames(id, 'for-hover')} />
      <GeoJSON
        key={id}
        ref={ref}
        data={data}
        style={style}
        onMouseOut={onMouseOut}
        onClick={onClickHandler}
        onMouseOver={onMouseOver}
        className={classnames(
          id,
          type,
          className,
          'facility',
          _get(projectPalette, [class_id])
        )}
      >
        <Marker
          data={data}
          icon={customIcon}
          zIndexOffset={9999}
          position={position}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          onClick={showRuler ? null : onClickHandler}
        />
        <Popup properties={{ name, style, class_id }} />
      </GeoJSON>
    </React.Fragment>
  );
};
