import React from 'react';
import {connect} from 'react-redux';
import {getTaskSettings} from 'core/state/selectors/settings';

const BottomRow = ({quarries_ids}) => {
    if (quarries_ids && quarries_ids.length) {
        return null
    }
    return <div className="alert-row">
        <h2 className="alert-row__title">Выберите <span
            className="alert-row__title-bold">«Карьеры»</span> для расчета</h2>
    </div>
};

const mapStateToProps = state => ({
    quarries_ids: getTaskSettings(state).props.quarries_ids,
});

export default connect(mapStateToProps)(BottomRow);
