import React from 'react';
import coloringObj from 'utils/coloringObj';
import AreasView from './AreasView';
import PatternsObj from '../../utils/Patterns';
import { Patterns } from 'react-leaflet-geojson-patterns';

export default ({ areas = [] }) => {
  const patterns = PatternsObj();
  const setStyle = ({ geometry: { properties } }) => {
    const { key } = properties;
    const style = coloringObj(key);
    let styleObj = {
      ...style,
      weight: 0,
      fillOpacity: 1,
    };
    if (patterns[key]) {
      styleObj.fillPattern = Patterns.PathPattern(patterns[key]);
    } else {
      styleObj.weight = 1;
    }
    return styleObj;
  };
  return (
    <AreasView stateAreas={areas} patterns={patterns} setStyle={setStyle} />
  );
};
