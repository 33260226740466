import React from 'react';

export default ({ remove, openTask, subLayerKey }) => (
  <span className="common-component-handlers">
    {!!(typeof openTask === 'function' && subLayerKey && subLayerKey.taskId) && <span
      data-tip='Перейти к задаче'
      data-for='mainTooltip'
      onClick={() => openTask(subLayerKey)}
      className="icon networkInfo">
    </span>
    }
    <span onClick={remove} className="field-row__remove remove-metaclass"></span>
  </span>
);