import React, { Component } from 'react';

import SwitchComponent from 'components/Common/SwitchComponent';
import _get from 'lodash.get';

class SwitchResultsContainer extends Component {
  state = {
    switchResult: false,
    title: '',
  };
  switchResultsHandler = () => {
    this.setState(
      {
        switchResult: !this.state.switchResult,
      },
      () => {
        this.showResults(this.state.switchResult);
        if (!this.state.switchResult) {
          this.props.removeOpenedTasks('SUCCESS', {
            taskId: this.props.taskId,
          });
        }
      }
    );
  };
  showResults = val => {
    this.props.showTaskResults('SUCCESS', val);
  };

  componentWillUnmount() {
    this.showResults(false);
  }

  handleTitle = () => {
    const { type } = this.props;
    const title =
      type === 'linear'
        ? 'Вид профиля и распределение стоимости'
        : 'Журнал выполнения и результаты задачи';
    this.setState({
      title,
    });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.handleTitle();
    }
  }
  componentDidMount() {
    const {
      taskId,
      showTaskResults,
      oppenedTasks,
      getLinearsToggle,
      cardInfo,
    } = this.props;
    if (oppenedTasks[taskId]) {
      this.setState(
        {
          switchResult: true,
        },
        () => showTaskResults('SUCCESS', true)
      );
    }
    if (_get(cardInfo, ['data', 'id'])) {
      if (_get(getLinearsToggle, cardInfo.data.id)) {
        this.setState(
          {
            switchResult: true,
          },
          () => showTaskResults('SUCCESS', true)
        );
      }
    }
    this.handleTitle();
  }

  render() {
    const { title } = this.state;
    const { type } = this.props;
    if (['facility', 'area'].includes(type)) return null;
    return (
      <div className='switch-results'>
        <h2 className='switch-results__title'>{title}</h2>
        <div className='switch-results__line' />
        <SwitchComponent
          value={this.state.switchResult}
          onChange={this.switchResultsHandler}
          containerClass='switch-results__switch'
        />
      </div>
    );
  }
}

export default SwitchResultsContainer;
