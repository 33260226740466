import React from 'react';

import Linear from './Linear';
import Quarries from './Quarries';
import LinearResults from './LinearResults';
import AllLinearResults from './AllLinearResults';
import FacilityTaskResult from './FacilityTaskResult';
import LinearCost from './LinearCost';
import GatheringCenters from './GatheringCenters';
import {taskConstants} from 'utils/constants';

export default ({type, updated, taskResult, taskResValues, selectedResult, currentHandler}) => {
    const {LINEAR_COST, FACILITY, PIPES, ROADS, HVL, QUARRIES, GATHERING_CENTERS} = taskConstants;
    return <>
        {type === GATHERING_CENTERS && <GatheringCenters taskResult={taskResult}/>}
        {(type === 'linear')
        && <Linear
            currentHandler={currentHandler}
            taskResult={taskResult}/>}
        {(type === LINEAR_COST)
        && <LinearCost
            taskResult={taskResult}/>}
        {(type === FACILITY)
        && <FacilityTaskResult/>}
        {(type === QUARRIES)
        && <Quarries taskResult={taskResult}/>}
        {!!([...PIPES, ROADS, HVL]
            .includes(type) && !selectedResult.value)
        && <AllLinearResults
            taskResult={taskResult}/>}
        {!!([...PIPES, ROADS, HVL]
            .includes(type) && selectedResult.value)
        && <LinearResults
            updated={updated}
            taskResult={taskResult}
            taskResValues={taskResValues}
            selectedResult={selectedResult}
        />}
    </>
};
