import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {consumersIds} from "core/state/selectors/settings";

export default () => {
    const consumers = useSelector(consumersIds);
    useEffect(() => {
        if (consumers) {
            document.querySelectorAll('.first-selected').forEach(el => {
                el.classList.remove('first-selected');
            });
            consumers.forEach(id => {
                document.getElementsByClassName(id)[0].classList.add('first-selected');
            });
        }
    }, [consumers]);
};