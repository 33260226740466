import {createSelector} from 'reselect';

export const getActiveTab = ({settings: {activeTab}}) => activeTab;
export const showResults = ({settings: {showResults}}) => showResults;
export const activeTasks = ({settings: {activeTasks}}) => activeTasks;
export const getTaskSettings = ({settings: {taskSettings}}) => taskSettings;
export const getSettingsClassId = ({settings: {taskSettings}}) => taskSettings.class_id;
export const getSettingsMetaclassId = ({settings: {taskSettings}}) => taskSettings.metaclass_id;
export const getSettingsPalette = ({settings: {taskSettings}}) => taskSettings.palette;
export const getSettingsWidth = ({settings: {taskSettings: {props}}}) => {
    if (!props) return '';
    return props.width
};
export const getSettingsLength = ({settings: {taskSettings: {props}}}) => {
    if (!props) return '';
    return props.length
};
export const lastActiveTab = ({settings: {lastActiveTab}}) => lastActiveTab;

export const facilityId = ({settings: {taskSettings}}) => {
    if (!taskSettings.props) return false;
    return taskSettings.props.facility_id
};
export const facilityIds = ({settings: {taskSettings}}) => {
    if (!taskSettings.props) return false;
    return taskSettings.props.facilities_ids
};
export const facilities = ({settings: {taskSettings}, project}) => {
    if (project.taskId && project.tasksById[project.taskId]) {
        const task = project.tasksById[project.taskId][0];
        if (task.type === 'roads') {
            return task.props.facilities || [];
        }
    }
    if (!taskSettings.props) return false;
    return taskSettings.props.facilities
};
export const gatheringCentersFacilities = ({project}) => {
    if (project.taskId && project.tasksById[project.taskId]) {
        const task = project.tasksById[project.taskId][0];
        if (task.type === 'gathering_centers') {
            if (project.taskResult[0] && project.taskResult[0].props) {
                return project.taskResult[0].props
            }
            return {};
        }
    }
    return {}
};
export const linearId = ({settings: {taskSettings}}) => {
    if (!taskSettings.props) return false;
    return taskSettings.props.linear_id;
};
export const networkId = ({settings: {taskSettings}}) => {
    if (!taskSettings.props) return false;
    return taskSettings.props.network_id;
};
export const quarriesIds = createSelector(getTaskSettings, ({props}) => {
    if (!props) return false;
    return props.quarries_ids;
});
export const sourcesIds = ({settings: {taskSettings}}) => {
    if (!taskSettings.props) return false;
    return taskSettings.props.sources;
};
export const sinksIds = ({settings: {taskSettings}}) => {
    if (!taskSettings.props) return false;
    return taskSettings.props.sinks
};
export const consumersIds = ({settings: {taskSettings}}) => {
    if (!taskSettings.props) return false;
    return taskSettings.props.consumers;
};
export const lineCoords = ({settings: {taskSettings}}) => taskSettings.geom;
export const showRemoveAlert = ({settings: {showRemoveAlert}}) => showRemoveAlert;
export const removeProps = ({settings: {removeProps}}) => removeProps;

//clickType
export const getClickType = ({settings: {clickType}}) => clickType;
export const showObjectPopups = createSelector(
    getClickType, ({
                       ruler,
                       chooseSources,
                       createFacility,
                       chooseFacility,
                       drawAreaObject,
                       chooseLinearObject,
                       createLinearObject,
                       chooseGatheringCenters
                   }) => {
        return !(ruler || chooseSources || createFacility || chooseFacility || drawAreaObject || createLinearObject || chooseLinearObject || chooseGatheringCenters)
    });

export const createFacility = createSelector(getClickType, ({createFacility}) => createFacility);
export const chooseSources = createSelector(getClickType, ({chooseSources}) => chooseSources);
export const chooseSinks = createSelector(getClickType, ({chooseSinks}) => chooseSinks);
export const chooseGatheringCenters = createSelector(getClickType, ({chooseGatheringCenters}) => chooseGatheringCenters);

export const newProjectArea = createSelector(getClickType, ({newProjectArea}) => newProjectArea);
export const createArea = createSelector(getClickType, ({createArea}) => createArea);
export const showRuler = createSelector(getClickType, ({ruler}) => ruler);
export const singleObj = createSelector(getClickType, ({singleObj}) => singleObj);
export const chooseLinearObject = createSelector(getClickType, ({chooseLinearObject}) => chooseLinearObject);
export const chooseFacility = createSelector(getClickType, ({chooseFacility}) => chooseFacility);
export const chooseQuarries = createSelector(getClickType, ({chooseQuarries}) => chooseQuarries);
export const chooseConsumers = createSelector(getClickType, ({chooseConsumers}) => chooseConsumers);
export const chooseSingleFacility = createSelector(getClickType, ({chooseSingleFacility}) => chooseSingleFacility);
export const createLinearObject = createSelector(getClickType, ({createLinearObject}) => createLinearObject);
export const linearRoads = createSelector(getClickType, ({linearRoads}) => linearRoads);
export const drawAreaObject = createSelector(getClickType, ({drawAreaObject}) => drawAreaObject);

export const transformSettings = ({settings: {transformSettings}}) => transformSettings;

export const draggable = createSelector(transformSettings, ({draggable}) => draggable);
export const draw = createSelector(transformSettings, ({draw}) => draw);
export const magnet = createSelector(transformSettings, ({magnet}) => magnet);
export const repaint = createSelector(transformSettings, ({repaint}) => repaint);
export const scaling = createSelector(transformSettings, ({scaling}) => scaling);
export const rotation = createSelector(transformSettings, ({rotation}) => rotation);
export const getNewAreaPoints = ({settings: {taskSettings}}) => taskSettings.geom && taskSettings.geom.coordinates[0];
export const getPolygonArea = ({settings: {taskSettings}}) => taskSettings.geom && taskSettings.geom.area;
export const showLoader = ({settings: {showLoader}}) => showLoader;
export const showModal = ({settings: {showModal}}) => showModal;
export const modalOption = ({settings: {modalOption}}) => modalOption;
export const facilitiesFromProjectByTaskType = ({settings: {facilitiesFromProjectByTaskType}}) => facilitiesFromProjectByTaskType;
export const cardInfo = ({settings: {cardInfo}}) => cardInfo;
