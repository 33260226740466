import React from 'react';
import {useSelector} from "react-redux";
import {classesByWellfield} from 'core/state/selectors/wellfield'
import {Popup} from 'react-leaflet';
import OpenCardHandler from "./OpenCardHandler";
import _get from 'lodash.get';

export default ({properties, openCardHandler}) => {
    const getClassesByWellfield = useSelector(classesByWellfield);
    const {name, style: {color}, class_id} = properties;
    let type = _get(getClassesByWellfield, [class_id, 'name']) || 'Класс не задан';
    return <Popup>
        <div className="popup-content">
            <p>{name || 'нет имени'}</p>
            <p className="popup-class-name">
        <span
            className='popup-cirle'
            style={{backgroundColor: color}}/>
                {type}
            </p>
        </div>
        {
            openCardHandler && <OpenCardHandler
                openCardHandler={openCardHandler}
                id={properties.id}
            />
        }
    </Popup>
};