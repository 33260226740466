import {call, put, fork, takeLatest, cancel, take} from 'redux-saga/effects';
import {getRequest, createUploadFileChannel} from '../../api';
import service from 'core/service/common';
import serviceprj from 'core/service/projects';

import setAuthToken from 'utils/setAuthToken';

import {setAuth} from 'core/state/actions/auth';
import {
    setError,
    getProjects,
    setInfoLogs,
    getWellfields,
    setNewWellfieldTaskId,
    getLastProjectsAndWellfields,
    updateUploadProgress,
    cancelWellfieldChecking,
    cancelWellfieldSending
} from 'core/state/actions/common';

import {
    CANCEL_WELLFIELD_SENDING_REQUEST,
    CANCEL_WELLFIELD_CHECKING_REQUEST,
    GET_COMMON_INFO_REQUEST,
    SEND_WELLFIELD_REQUEST,
    GET_PROJECTS_REQUEST,
    GET_WELLFIELD_REQUEST
} from 'core/state/constants/common';

import toObject from 'utils/toObject';
import _get from 'lodash.get';

const createSaga = (action, url) => function* () {
    try {
        const {data: {data}} = yield call(getRequest, url);
        yield put(action('SUCCESS', toObject(data, 'id')));
    } catch (error) {
        const {response} = error;
        if (response.statusText === 'UNAUTHORIZED') {
            setAuthToken(false);
            yield put(setAuth('FAILURE'));
        }
        yield put(setError('SUCCESS', response.data.user_message));
    }
};

const createWatch = (type, saga) => function* () {
    yield takeLatest(type, saga)
};

const fetchProjects = createSaga(getProjects, service.projectsUrl);

const fetchWellfields = createSaga(getWellfields, service.wellfieldsUrl);

const fetchLastProjectsAndWellfields = function* () {
    try {
        const lastProjectsUrl = service.lastProjects;
        const {data: {data: lastProjectsArr}} = yield call(getRequest, lastProjectsUrl);
        const targetWellfields = lastProjectsArr.map(({wellfield_id}) => wellfield_id);
        const lastWellfiedlsUrl = service.lastWellfields(JSON.stringify(targetWellfields));
        const {data: {data: lastWellfieldsArr}} = yield call(getRequest, lastWellfiedlsUrl);
        const lastWellfields = toObject(lastWellfieldsArr, 'id');
        const lastProjects = toObject(
            lastProjectsArr
                .filter(el => lastWellfields[el.wellfield_id])
                .map(el => ({
                        ...el,
                        wellfieldName: _get(lastWellfields, [el.wellfield_id, 'name'])
                    })
                ),
            'id'
        );
        yield put(getLastProjectsAndWellfields('SUCCESS', {
            lastProjects,
            lastWellfields
        }))
    } catch (error) {
        console.log(error)
    }
};

const getCommonInfoSaga = function* () {
    yield fork(fetchLastProjectsAndWellfields);
    yield call(fetchWellfields);
    yield call(fetchProjects);
};

const sendNewWellfield = function* (payload) {
    try {
        const url = service.sendWellfield;
        const uploadChannel = yield call(createUploadFileChannel, url, payload);
        yield fork(uploadProgressWatcher, uploadChannel);
    } catch (err) {
        yield put(setError('SUCCESS', "Ошибка sendNewWellfield: " + err.message))
    }
};

const uploadProgressWatcher = function* (channel) {
    try {
        while (true) {
            const {progress = 0, err, id} = yield take(channel);
            if (err) {
                yield put(setError('SUCCESS', err));
                return;
            }
            if (id) {
                yield put(setNewWellfieldTaskId('SUCCESS', id));
                return;
            }
            yield put(updateUploadProgress('SUCCESS', progress));
        }
    } catch (err) {
        yield put(setError('SUCCESS', "Ошибка uploadProgressWatcher: " + err.message))
    }
}

const sendNewWellfieldSaga = function* ({payload}) {
    try {
        const req = yield fork(sendNewWellfield, payload);
        yield take(CANCEL_WELLFIELD_SENDING_REQUEST);
        yield cancel(req);
        yield put(updateUploadProgress('SUCCESS', 0));
        yield put(setInfoLogs('SUCCESS', 'Загрузка отменена'));
        yield put(cancelWellfieldSending('SUCCESS'));
    } catch (err) {
        yield put(setError('SUCCESS', "Ошибка sendNewWellfieldSaga: " + err.message));
    }
};

const cancelWellfield = function* (payload) {
    try {
        const url = serviceprj.cancelTask(payload);
        const {request} = yield call(getRequest, url);
        if (request.status === 200) {
            yield put(setInfoLogs('SUCCESS', `Проверка файла отменена`));
            yield put(cancelWellfieldChecking('SUCCESS'));
        } else put(setError('SUCCESS', "Проверка файла не может быть отменена"))
    } catch (error) {
        yield put(setError('SUCCESS', "Ошибка CancelWellfield: " + error.message))
    }
};
const CancelWellfieldCheckingSaga = function* ({payload}) {
    yield fork(cancelWellfield, payload);
};

export default [
    createWatch(GET_PROJECTS_REQUEST, fetchProjects),
    createWatch(GET_WELLFIELD_REQUEST, fetchWellfields),
    createWatch(SEND_WELLFIELD_REQUEST, sendNewWellfieldSaga),
    createWatch(CANCEL_WELLFIELD_CHECKING_REQUEST, CancelWellfieldCheckingSaga),
    createWatch(GET_COMMON_INFO_REQUEST, getCommonInfoSaga)
].map(watcher => fork(watcher))
