import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setRemoveProps, openRemoveAlert } from 'core/state/actions/settings'

import User from './User'

class UserContainer extends Component {
  logOut = () => {
    this.props.setRemoveProps('SUCCESS', {
      removeType: 'logout',
      title: 'Выйти из системы',
      questionTitle: 'Вы действительно хотите выйти'
    });
    this.props.openRemoveAlert('SUCCESS', true)
    /*const options = {
      removeType: 'logout',
      title: 'Выйти из системы',
      questionTitle: 'Вы действительно хотите выйти'};
    this.props.setShowModal('SUCCESS', {type: 'LogoutModal', option: options})*/
  };
  render() {
    return <User clickHandler={this.logOut} />
  }
}


export default connect(null, {
  setRemoveProps,
  openRemoveAlert
})(UserContainer);