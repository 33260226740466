import {connect} from 'react-redux';
import {getActiveTab} from 'core/state/selectors/settings';
import {getProjectName, getProjectId} from 'core/state/selectors/projects';
import {setRemoveProps, openRemoveAlert} from 'core/state/actions/settings';

import ExpandedTopMenuContainer from './ExpandedTopMenuContainer';

const mapStateToProps = state => ({
    projectId: getProjectId(state),
    getActiveTab: getActiveTab(state),
    projectName: getProjectName(state)
});

const mapDispatchToProps = {
    setRemoveProps,
    openRemoveAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpandedTopMenuContainer);
