import {useEffect, useCallback, memo} from 'react';
import {useSelector} from "react-redux";
import {
    linearRoads as getLinearRoads,
    chooseSinks as getChooseSinks,
    chooseSources as getChooseSources,
    chooseQuarries as getChooseQuarries,
    chooseFacility as getChooseFacility,
    chooseConsumers as getChooseConsumers,
    chooseLinearObject as getChooseLinearObject,
    chooseSingleFacility as getChooseSingleFacility,
    chooseGatheringCenters as getChooseGatheringCenters,
    facilitiesFromProjectByTaskType as getFacilitiesFromProjectByTaskType
} from 'core/state/selectors/settings';

import HandleSinks from './HandleSinks';
import HandleLinears from './HandleLinears';
import HandleSources from './HandleSources';
import HandleFacility from './HandleFacility';
import HandleFacilitieIds from './HandleFacilitieIds';
import HandlerQuarries from './HandlerQuarries';
import HandleConsumers from './HandleConsumers';
import HandleFacilities from './HandleFacilities';
import GatheringCenters from './GatheringCenters';
import ActiveNetwork from './ActiveNetwork';

const checkClasses = () => {
    if (!document.getElementById('mapUI').classList.contains('select-facilities')) {
        document.getElementById('mapUI').classList.add('select-facilities');
    }
    document.querySelectorAll('.facility-for-select')
        .forEach(el => el.classList.remove('facility-for-select'));
};

export default memo(() => {
    const
        [
            linearRoads,
            chooseSinks,
            chooseSources,
            chooseQuarries,
            chooseFacility,
            chooseConsumers,
            chooseLinearObject,
            chooseSingleFacility,
            chooseGatheringCenters,
            facilitiesFromProjectByTaskType
        ] =
            useSelector(state => [
                getLinearRoads(state),
                getChooseSinks(state),
                getChooseSources(state),
                getChooseQuarries(state),
                getChooseFacility(state),
                getChooseConsumers(state),
                getChooseLinearObject(state),
                getChooseSingleFacility(state),
                getChooseGatheringCenters(state),
                getFacilitiesFromProjectByTaskType(state),
            ]);
    HandleSinks();
    ActiveNetwork();
    HandleSources();
    HandleLinears();
    HandleFacility();
    HandlerQuarries();
    HandleConsumers();
    HandleFacilities();
    GatheringCenters();
    HandleFacilitieIds();
    const removeClasses = () => {
        document.querySelectorAll('.facility-for-select').forEach(el => el.classList.remove('facility-for-select'));
        document.getElementById('mapUI').classList.remove('select-facilities');
    };
    const addClasses = useCallback(type => {
        checkClasses();
        if (facilitiesFromProjectByTaskType) {
            const objects = facilitiesFromProjectByTaskType[type];
            if (objects) {
                objects
                    .forEach(elem => {
                        const node = document.getElementsByClassName(`facility ${elem}`)[0];
                        if (node) {
                            const nodeClassList = node.classList;
                            if (!nodeClassList.contains('facility-for-select')) {
                                nodeClassList.add('facility-for-select');
                            }
                        }
                    })
            }
        }
    }, [facilitiesFromProjectByTaskType]);
    useEffect(() => {
        if (!facilitiesFromProjectByTaskType) removeClasses();
    }, [facilitiesFromProjectByTaskType]);
    useEffect(() => {
        if (chooseSingleFacility) document.getElementById('mapUI').classList.remove('select-facilities');
    }, [chooseSingleFacility]);
    useEffect(() => {
        if (chooseLinearObject) removeClasses();
    }, [chooseLinearObject]);
    useEffect(() => {
        if (chooseQuarries) addClasses('quarries_ids');
    }, [chooseQuarries, facilitiesFromProjectByTaskType]);
    useEffect(() => {
        if (chooseSources) addClasses('sources');
    }, [chooseSources, facilitiesFromProjectByTaskType]);
    useEffect(() => {
        if (chooseConsumers) addClasses('consumers');
    }, [chooseConsumers, facilitiesFromProjectByTaskType]);
    useEffect(() => {
        if (chooseSinks) addClasses('sinks');
    }, [chooseSinks, facilitiesFromProjectByTaskType]);
    useEffect(() => {
        if (chooseGatheringCenters) addClasses('facilities_ids');
    }, [chooseGatheringCenters, facilitiesFromProjectByTaskType]);
    useEffect(() => {
        if (chooseFacility && linearRoads) removeClasses();
    }, [chooseFacility, linearRoads, facilitiesFromProjectByTaskType]);
    return null;
});
