import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {facilityIds} from 'core/state/selectors/settings';

export default () => {
    const facilities = useSelector(facilityIds);
    useEffect(() => {
        if (facilities) {
            document.querySelectorAll('.facility.first-selected').forEach(el => {
                const className = el.getAttribute('class');
                const flag = facilities.some(function (element) {
                    return className.includes(element);
                });
                if (!flag) {
                    el.classList.remove('first-selected')
                }
            });
            facilities.forEach(facility => {
                const nodeClasses = document.getElementsByClassName(`facility ${facility}`)[0].classList;
                if (!nodeClasses.contains('first-selected')) {
                    nodeClasses.add('first-selected');
                }
            })
        } else {
            document.querySelectorAll('.first-selected').forEach(el => {
                el.classList.remove('first-selected');
            })
        }
    }, [facilities])
}
