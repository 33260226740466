import React from "react";
import SetIcon from "./SetIcon";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./Styles.scss";

const OptionButton = React.memo(
  ({ type, onClick }) => {
    const onClickHandler = () => {
      if (typeof onClick === "function") {
        onClick(type);
      }
    };
    return (
      <button
        onClick={onClickHandler}
        className={classnames("sideButton", type)}
      >
        <SetIcon type={type} />
      </button>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.type === nextProps.type;
  }
);

OptionButton.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OptionButton;
