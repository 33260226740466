import React, {Component} from 'react'
import {connect} from 'react-redux'
import {errors, success, info} from 'core/state/selectors/common'
import {setError, setInfoLogs, setSuccessLogs} from 'core/state/actions/common'

import ErrorItem from './ErrorItem'
import ReactNotification from "react-notifications-component"

class ErrorContainer extends Component {
    state = {errors: []};
    notificationDOMRef = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {errors, success, info} = this.props;
        let content;
        if (errors.length) {
            content = <ErrorItem errors err={errors}/>
        }
        if (success.length) {
            content = <ErrorItem success err={success}/>
        }
        if (info.length) {
            content = <ErrorItem info err={info}/>
        }
        if (success.length || errors.length || info.length) {
            this.notificationDOMRef.current.addNotification({
                content: content,
                type: "custom",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {duration: 5000},
                dismissable: {click: true}
            });
            if (success.length) {
                this.props.setSuccessLogs('SUCCESS', [])
            }
            if (info.length) {
                this.props.setInfoLogs('SUCCESS', [])
            }
            if (errors.length) {
                this.props.setError('SUCCESS', [])
            }
        }
    }

    onNotificationRemoval = () => {
        console.log('onNotificationRemoval')
    };

    render() {
        return <ReactNotification ref={this.notificationDOMRef} onNotificationRemoval={this.onNotificationRemoval}/>
    }
}

const mapStateToProps = state => ({
    info: info(state),
    errors: errors(state),
    success: success(state)
});

const mapDispatchToProps = {
    setError,
    setInfoLogs,
    setSuccessLogs
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorContainer);
