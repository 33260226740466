import React from 'react';
import FacilityTaskResultView from './FacilityTaskResultView';

export default ({taskResult, setNewPoPosition}) => {
    const setNewPoPositionHandler = () => {
        if (taskResult) {
            console.log(taskResult, 'taskResult');
            const {id, props} = taskResult[0];
            const {optimized_facility_id, facility_id} = props;
            setNewPoPosition('REQUEST', {
                id,
                oldFacilityId: facility_id,
                facility_id: optimized_facility_id
            })
        }
    };
    return <FacilityTaskResultView taskResult={taskResult} setNewPoPosition={setNewPoPositionHandler}/>
};
