export default type => {
    const data = {
        roads: [
            {
                label: 'Объекты',
                name: 'facilities',
                chooseObjectsParams: ['linearRoads', 'chooseFacility']
            }
        ],
        gathering_centers: [
            {
                label: 'Кустовые площадки',
                name: 'facilities_ids',
                chooseObjectsParams: ['chooseGatheringCenters']
            }
        ],
        facility_placement: [
            {
                label: 'Объект',
                name: 'facility_id',
                chooseObjectsParams: ['chooseSingleFacility']
            },
            {
                label: 'Карьеры',
                name: 'quarries_ids',
                chooseObjectsParams: ['chooseQuarries']
            }
        ],
        quarries: [
            {
                label: 'Карьеры',
                name: 'quarries_ids',
                chooseObjectsParams: ['chooseQuarries']
            }
        ],
        linear_cost: [
            {
                label: 'Объект',
                name: 'linear_id',
                chooseObjectsParams: ['chooseLinearObject']
            }
        ],
        high_pressure_water_pipe: [
            {
                label: 'Стоки',
                name: 'sinks',
                chooseObjectsParams: ['chooseSinks']
            },
            {
                label: 'Исток',
                name: 'sources',
                chooseObjectsParams: ['singleObj', 'chooseSources']
            }
        ],
        high_voltage: [
            {
                name: 'sources',
                label: 'Источник',
                chooseObjectsParams: ['singleObj', 'chooseSources']
            },
            {
                name: 'consumers',
                label: 'Потребители',
                chooseObjectsParams: ['chooseConsumers']
            }
        ]
    };
    if ([
        'oil_pipe',
        'oil_gathering_line',
        'low_pressure_gas_pipe',
        'high_pressure_gas_pipe',
        'low_pressure_water_pipe'
    ].some(el => el === type)) {
        return [
            {
                label: 'Сток',
                name: 'sinks',
                chooseObjectsParams: ['singleObj', 'chooseSinks']
            },
            {
                label: 'Истоки',
                name: 'sources',
                chooseObjectsParams: ['chooseSources']
            }
        ]
    }
    return data[type]
}
