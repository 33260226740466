import React from 'react';
import ProjectList from './ProjectList';
import ScrollBar from 'components/Common/ScrollBar';
import '../projects.scss';

const OpenProjectsComponent = ({ openList, activeText, download, remove, projects, projectId, activeRow, choseProject }) => {
  return <ScrollBar>
    <ProjectList
      remove={remove}
      openList={openList}
      projects={projects}
      download={download}
      projectId={projectId}
      activeRow={activeRow}
      activeText={activeText}
      choseProject={choseProject} />
  </ScrollBar>
};

export default OpenProjectsComponent;