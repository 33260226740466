import React from 'react';
import { connect } from 'react-redux';
import { newWellfieldLogs, uploadProgress, newWellfieldTaskId, checkingCanceled, uploadingCanceled } from 'core/state/selectors/common';
import {
  setError,
  setSuccessLogs,
  sendWellfield,
  cancelWellfieldSending,
  cancelWellfieldChecking,
  setNewWellfieldTaskId,
  removeNewWellfieldLogs,
  updateUploadProgress,
  getWellfields,
  clearCancelingFlags
} from 'core/state/actions/common';
import { showModal } from 'core/state/actions/settings';


import NewWellfieldContainer from './NewWellfieldContainer';

const NewWellfieldRedux = ({ ...props }) => <NewWellfieldContainer {...props} />;

const mapDispatchToProps = {
  cancelWellfieldSending,
  cancelWellfieldChecking,
  setNewWellfieldTaskId,
  removeNewWellfieldLogs,
  setSuccessLogs,
  sendWellfield,
  setError,
  showModal,
  updateUploadProgress,
  getWellfields,
  clearCancelingFlags
};

const mapStateToProps = state => ({
  newWellfieldLogs: newWellfieldLogs(state),
  uploadProgress: uploadProgress(state),
  newWellfieldTaskId: newWellfieldTaskId(state),
  checkingCanceled: checkingCanceled(state),
  uploadingCanceled: uploadingCanceled(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(NewWellfieldRedux);