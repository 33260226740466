import React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {getProjectById} from 'core/state/actions/projects'
import {getCommonInfo} from 'core/state/actions/common'
import {setPrimaryFacilities, getFacilitiesByLayout} from 'core/state/actions/wellfield'
import {getProjectId, getProjectName} from 'core/state/selectors/projects'

const WithProjectsProps = ComposedComponent => props => {
    const handleClick = project => {
        props.setPrimaryFacilities('SUCCESS', {});
        props.getFacilitiesByLayout('SUCCESS', {});
        props.getProjectById('REQUEST', project.id);
        changeLocation(`/app/${project.id}`);
    };
    const changeLocation = url => props.history.push(url);
    return <ComposedComponent {...props} handleClick={handleClick} changeLocation={changeLocation}/>
};

const mapStateToProps = state => ({
    getProjectId: getProjectId(state),
    projectName: getProjectName(state)
});

const mapDispatchToProps = {
    getCommonInfo,
    getProjectById,
    setPrimaryFacilities,
    getFacilitiesByLayout,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    WithProjectsProps
)