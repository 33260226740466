import 'proxy-polyfill';
import 'react-app-polyfill/ie9';
import 'core-js/es/array/includes';
import 'core-js/es/array/flat';
import 'core-js/es/string/includes';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './core';
import registerServiceWorker from './registerServiceWorker';

const rootEl = document.getElementById('root');

ReactDOM.render(
    <App/>,
    rootEl
);

registerServiceWorker();

if (module.hot) {
    module.hot.accept();
}
