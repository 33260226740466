import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getFacilitiesFromProjectByTaskType} from 'core/state/actions/projects';
import {changeTaskSettings, setDefaultProps} from 'core/state/actions/settings';
import taskConfig from './taskConfig';
import classnames from 'classnames';
import _isEqual from 'lodash.isequal';

import CheckBox from '../CheckBox';
import ToggleField from './ToggleField';
import CommonField from '../../CommonField';
import CommonFields from '../CommonFields';
import BottomRow from "./BottomRow";
import RoadTypes from "./RoadTypes";
import RoadWidth from "./RoadWidth";
import BackfillingHeight from "./BackfillingHeight";
import roadCoefs from "./roadCoefs";

export const roadParams = [
    'class_id_target',
    'road_width',
    'backfilling_height',
];

const defaultValues = {
    class_id_target: '',
    use_roads_params: false,
    use_network_angles: false,
    use_quarries: false,
    use_existing_roads: false,
    backfilling_height: 1,
    road_width: 1,
    corridor_coeff_vl: 0,
    corridor_coeff_nsk: 0,
    corridor_coeff_nnp: 0,
    corridor_coeff_vnd: 0,
    corridor_coeff_vvd: 0,
    corridor_coeff_gnd: 0,
    corridor_coeff_gvd: 0,
    minimal_radius: 15,
    height_angle: 15
};

class Roads extends Component {
    state = {
        taskInfo: {props: {}},
        roadTypes: [],
        defaultValues
    };

    componentDidMount() {
        this.setPropsToState(this.props);
        const {info} = this.props;
        if (!info) {
            this.props.setDefaultProps('SUCCESS', {
                facilities: []
            });
            this.props.getFacilitiesFromProjectByTaskType('REQUEST', 'roads');
        }
    }

    componentWillUnmount() {
        this.props.changeTaskSettings('SUCCESS', {props: {}})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_isEqual(prevProps.taskInfo, this.props.taskInfo)) {
            this.setPropsToState(this.props);
        }
    }

    setPropsToState = props => {
        const {taskInfo} = props;
        if (taskInfo) {
            const {
                use_existing_roads,
                corridor_coeff_vl,
                corridor_coeff_nsk,
                corridor_coeff_nnp,
                corridor_coeff_vnd,
                corridor_coeff_vvd,
                corridor_coeff_gnd,
                corridor_coeff_gvd,
                class_id_target,
                road_width,
                backfilling_height,
            } = taskInfo.props;
            this.setState({
                taskInfo,
                defaultValues: {
                    use_existing_roads,
                    corridor_coeff_vl,
                    corridor_coeff_nsk,
                    corridor_coeff_nnp,
                    corridor_coeff_vnd,
                    corridor_coeff_vvd,
                    corridor_coeff_gnd,
                    corridor_coeff_gvd,
                    class_id_target,
                    road_width,
                    backfilling_height
                }
            })
        }
    };

    render() {
        const {info, errors = []} = this.props;
        const {defaultValues, taskInfo} = this.state;
        const {firstToggleParams, secondToggleParams} = taskConfig(defaultValues, info);
        return (
            <div className={classnames('card-container', {'task-info': info})}>
                <CommonFields
                    info={info}
                    errors={errors}
                    taskInfo={taskInfo}
                    showAmountOfSolutions
                />
                <div className="create-task-main-container">
                    <RoadTypes
                        class_id_target={defaultValues.class_id_target}
                        error={errors.includes('props.class_id_target')}
                        disabled={info}
                    />
                    <div className="create-task-row">
                        <RoadWidth
                            road_width={defaultValues.road_width}
                            error={errors.includes('props.road_width')}
                            disabled={info}
                        />
                        <BackfillingHeight
                            backfilling_height={defaultValues.backfilling_height}
                            error={errors.includes('props.backfilling_height')}
                            disabled={info}
                        />
                    </div>
                    <div className="coefs-row">
                        <h2 className="coefs-row__title">Коэффициенты прокладки в коридоре</h2>
                        <div>
                            {
                                roadCoefs.map(({coef, label}, key) =>
                                    <CommonField
                                        key={key}
                                        addedProps={{
                                            propName: `props.${coef}`,
                                            placeholder: '',
                                            type: 'number',
                                            classContainer: 'quarter',
                                            defaultValue: defaultValues[coef],
                                            label,
                                            disabled: info
                                        }}
                                    />)
                            }
                        </div>
                    </div>
                    <div className="create-task-toggle-row">
                        <ToggleField data={firstToggleParams} taskInfo={taskInfo} info/>
                        <ToggleField data={secondToggleParams} taskInfo={taskInfo} info/>
                        <div className="create-task-toggle-row__title">
                            <CheckBox
                                id="2jxaxvz"
                                disabled={info}
                                propName='props.use_existing_roads'
                                label="Использовать существующие дороги"
                                defaultValue={defaultValues.use_existing_roads}
                            />
                        </div>
                    </div>
                </div>
                {!info && <BottomRow/>}
            </div>
        )
    }
}

const mapDispatchToProps = {
    setDefaultProps,
    changeTaskSettings,
    getFacilitiesFromProjectByTaskType
};

export default connect(null, mapDispatchToProps)(Roads);
