import React from 'react';
import {Provider} from 'react-redux';
import configureStore from '../store';
import rootSaga from '../sagas';
import MainComponent from './MainComponent';
import '../App.scss';
import setAuthToken from '../utils/setAuthToken';

const store = configureStore();

store.runSaga(rootSaga);

const token = localStorage.jwtToken;

if (token) {
    setAuthToken(localStorage.jwtToken);
    store.dispatch({
        type: 'SET_AUTH_SUCCESS',
        payload: !!token
    });
}
// App component
export default () => (
    <Provider store={store}>
        <MainComponent/>
    </Provider>
);

/**
                                                        -:`
                                                      :y/`
                                                    .sh-
               `.-.`                               -ds`
             `odNNmho-`                           +No      `
             yMmhoooydh/.                        oMy       /
            `Ny.     `:sho.                   `-yMM/       s
             m`         `:ys:`             ./oo+//++/-     d
             s`            -ydyo++:-`     `.`              h`
             -:             .my:.                      `-. -o
              o            `+.                       `sNNdy.o-
              ..           .        `-::-`           /smh/sh`s.
                                  -ydmNymNh:`        `:omdso `y.             `.......``````````
                                .ohsoymoosNmh`     ./oso+`    `d.    `......``
                                  .+syddo+.       -odMMMs     `+d....```..................``
                                                    -sdo   ``..-do-...............```      ```````
                  ./                                  o+      ``yh```````.``        ````````
                  `d           ``....``..----.`      `hm.       oN         `....````
                   h/  `.......```..------..`       -y/./+/.    /N                 ``````
                `..-s/.`   `...---..`...`        ./o+`    .-    /h                       ``
          ```...`   `s/....-...` `...     `/::://:.`            s+
      ```.`       `...+y-..`  `..`         ..``                `d`
   ````       `...```../d` `..`                                .s
          ``.`` ````    ss.``                                  `-
       ````   ```     `.-m
     ```     `      `.`  m
                  ```    h
                ``       +
               `         `
 *
 */


