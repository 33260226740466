import { connect } from 'react-redux';
import { sendNetwork } from 'core/state/actions/projects';

import AllLinearResultsContainer from './AllLinearResultsContainer';

const mapDispatchToProps = {
  sendNetwork,
};

export default connect(null, mapDispatchToProps)(AllLinearResultsContainer);
