import React from 'react';
import Button from 'components/Common/Button';
import '../modal.scss';

const HelpModalView = ({ closeHandler }) => (
    <React.Fragment>
        <div className="splash-screen" />
        <div className='remove-alert card'>
            <div className="modal-container new-wellfield__help">
                <div className="main-container__top-row modal-container__top-row">
                    <h2 className="main-title">Помощь по созданию месторождения</h2>
                    <div onClick={closeHandler} className="new-project__close">
                        <div onClick={closeHandler} className="new-project__close-line close" />
                    </div>
                </div>
                <div className="modal-content">
                    <p className="new-wellfield__p">
                        Для того, чтобы месторождение было успешно создано, zip-архив должен иметь правильную структуру
                    </p>
                    <Button className="primary-button">
                        Скачать шаблон структуры
                    </Button>
                </div>
                <div className="main-container__bottom-row">
                    <Button
                        secondary
                        onClick={closeHandler}>
                        Закрыть
                    </Button>
                </div>
            </div>
        </div>
    </React.Fragment>
);

export default HelpModalView;