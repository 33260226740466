import React from "react";
import CancelConfirmationView from "./CancelConfirmationView";

const CancelConfirmationContainer = ({setShowModal,confirmCancelation}) => {

    const closeHandler = () => {
        setShowModal('SUCCESS', {type: '', option: null})
    };
    const onCancelConfirmedClick = () => {
        confirmCancelation("yes");
        closeHandler();
    }
    return <CancelConfirmationView
        closeHandler={closeHandler}
        onCancelConfirmedClick = {onCancelConfirmedClick}/>
};

export default CancelConfirmationContainer;