import {call, put, fork, takeLatest} from 'redux-saga/effects';
import {postRequest} from '../../api';
import service from 'core/service/auth';
import setAuthToken from 'utils/setAuthToken';
import {setAuth} from "core/state/actions/auth";

import {LOGIN_REQUEST} from 'core/state/constants/auth';

import {setError} from 'core/state/actions/common';

const createWatch = (type, saga) => function* () {
    yield takeLatest(type, saga)
};

const loginSaga = function* ({payload}) {
    const url = service.auth;
    try {
        const {username, password} = payload;
        const prevToken = "Basic " + btoa(username + ":" + password);
        yield call(setAuthToken, prevToken);
        const {data} = yield call(postRequest, url, payload);
        const {token} = data.data[0];
        yield call(setAuthToken, token);
        localStorage.setItem('jwtToken', token);
        yield put(setAuth('SUCCESS', !!token));
    } catch (error) {
        yield put(setError('SUCCESS', ['Неверный логин или пароль.']))
    }
};

const wathcLogin = createWatch(LOGIN_REQUEST, loginSaga);

export default [
    fork(wathcLogin)
];