import React, {Component} from 'react';
import PropTypes from 'prop-types';

import CommonButtons from './CommonButtons';
import DataFields from 'components/Common/DataFields';
import {taskConstants} from 'utils/constants';
import _get from 'lodash.get';
import {taskId} from "../../../core/state/selectors/projects";
import {connect} from "react-redux";
import {setListsToggle, setOpenedTasks} from "../../../core/state/actions/groupsSettings";
import {sendNetwork, getTaskResult} from "../../../core/state/actions/projects";
import Button from "../../../components/Common/Button";

class TaskResultsTopRow extends Component {
    componentDidMount() {
        if (this.props.taskId) {
            this.props.setOpenedTasks('SUCCESS', {
                taskId: this.props.taskId,
                currentHandler: this.props.currentHandler
            })
        } else if (_get(this.props, ['cardInfo', 'data', 'id'])) {
            this.props.setListsToggle('SUCCESS', {
                id: _get(this.props, ['cardInfo', 'data', 'id']),
                currentHandler: this.props.currentHandler
            })
        }
    }

    applyHandler = () => {
        const {id, task_name} = this.props.taskResult[0];
        const taskResult = this.props.taskResult.slice();
        taskResult[0].props.applied = true;
        this.props.getTaskResult('SUCCESS', taskResult);
        this.props.sendNetwork('REQUEST', {
            id,
            name: task_name
        })
    };

    render() {
        const {type, handleResults, resultOptions, toggleHandler, currentHandler, selectedResult, taskResult} = this.props;
        return <div className="task-complete__toggle-row">
            <CommonButtons
                type={type}
                toggleHandler={toggleHandler}
                currentHandler={currentHandler}
            />
            {
                type === taskConstants.GATHERING_CENTERS &&
                currentHandler === 'results' ?
                    taskResult && !taskResult[0].props.applied ?
                        <Button onClick={this.applyHandler}>
                            Перенести в проект
                        </Button> :
                        <h2 className='is-applied-title'>
                            Перенесено в проект
                        </h2> : <></>
            }
            {[
                taskConstants.HVL,
                taskConstants.ROADS,
                ...taskConstants.PIPES
            ].includes(type) && currentHandler !== 'logs' &&
            <div className="handle-task-results">
                <span className="handle-task-results__text">Детализация</span>
                <DataFields
                    type='option'
                    options={resultOptions}
                    onChangeHandler={handleResults}
                    placeholder='Сравнение результатов'
                    value={selectedResult ? selectedResult.value : null}
                />
            </div>}
        </div>
    }
}

TaskResultsTopRow.propTypes = {
    handleResults: PropTypes.func.isRequired,
    toggleHandler: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    taskId: taskId(state)
});

const mapDispatchToProps = {
    sendNetwork,
    getTaskResult,
    setOpenedTasks,
    setListsToggle
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskResultsTopRow);
