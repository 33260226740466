// common
export const SET_ERROR = 'SET_ERROR';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_WELLFIELD = 'GET_WELLFIELD';
export const SET_INFO_LOGS = 'SET_INFO_LOGS';
export const SEND_WELLFIELD = 'SEND_WELLFIELD';
export const GET_COMMON_INFO = 'GET_COMMON_INFO';
export const SET_SUCCESS_LOGS = 'SET_SUCCESS_LOGS';
export const SET_NEW_WELLFIELD_LOGS = 'SET_NEW_WELLFIELD_LOGS';
export const CANCEL_WELLFIELD_SENDING = 'CANCEL_WELLFIELD_SENDING';
export const CANCEL_WELLFIELD_CHECKING = 'CANCEL_WELLFIELD_CHECKING';
export const CLEAR_CANCELING_FLAGS = 'CLEAR_CANCELING_FLAGS';
export const SET_NEW_WELLFIELD_TASK_ID = 'SET_NEW_WELLFIELD_TASK_ID';
export const UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS';
export const REMOVE_NEW_WELLFIELD_LOGS = 'REMOVE_NEW_WELLFIELD_LOGS';
export const GET_LAST_PROJECTS_AND_WELLFIELDS = 'GET_LAST_PROJECTS_AND_WELLFIELDS';
// REQUEST
export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_WELLFIELD_REQUEST = 'GET_WELLFIELD_REQUEST';
export const SEND_WELLFIELD_REQUEST = 'SEND_WELLFIELD_REQUEST';
export const GET_COMMON_INFO_REQUEST = 'GET_COMMON_INFO_REQUEST';
export const CANCEL_WELLFIELD_SENDING_REQUEST = 'CANCEL_WELLFIELD_SENDING_REQUEST';
export const CANCEL_WELLFIELD_CHECKING_REQUEST = 'CANCEL_WELLFIELD_CHECKING_REQUEST';
// SUCCESS
export const SET_ERROR_SUCCESS = 'SET_ERROR_SUCCESS';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_WELLFIELD_SUCCESS = 'GET_WELLFIELD_SUCCESS';
export const SET_INFO_LOGS_SUCCESS = 'SET_INFO_LOGS_SUCCESS';
export const SET_SUCCESS_LOGS_SUCCESS = 'SET_SUCCESS_LOGS_SUCCESS';
export const SET_NEW_WELLFIELD_LOGS_SUCCESS = 'SET_NEW_WELLFIELD_LOGS_SUCCESS';
export const SET_NEW_WELLFIELD_TASK_ID_SUCCESS = 'SET_NEW_WELLFIELD_TASK_ID_SUCCESS';
export const UPDATE_UPLOAD_PROGRESS_SUCCESS = 'UPDATE_UPLOAD_PROGRESS_SUCCESS';
export const GET_LAST_PROJECTS_AND_WELLFIELDS_SUCCESS = 'GET_LAST_PROJECTS_AND_WELLFIELDS_SUCCESS';
export const REMOVE_NEW_WELLFIELD_LOGS_SUCCESS = 'REMOVE_NEW_WELLFIELD_LOGS_SUCCESS';
export const CANCEL_WELLFIELD_CHECKING_SUCCESS = 'CANCEL_WELLFIELD_CHECKING_SUCCESS';
export const CANCEL_WELLFIELD_SENDING_SUCCESS = 'CANCEL_WELLFIELD_SENDING_SUCCESS';
export const CLEAR_CANCELING_FLAGS_SUCCESS = 'CLEAR_CANCELING_FLAGS_SUCCESS';