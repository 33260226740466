import {connect} from 'react-redux';
import {getCommonInfo} from 'core/state/actions/common';
import {getProjectName} from 'core/state/selectors/projects';
import {saveSettings} from 'core/state/actions/groupsSettings';
import {activeTab, showLoader} from 'core/state/actions/settings';
import {getProjectById, clearState} from 'core/state/actions/projects';

import MainPageContainer from './MainPageContainer';

const mapDispatchToProps = {
    activeTab,
    showLoader,
    clearState,
    saveSettings,
    getCommonInfo,
    getProjectById
};

const mapStateToProps = state => ({
    projectName: getProjectName(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPageContainer);
