import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getFacilitiesFromProjectByTaskType} from 'core/state/actions/projects';
import {changeTaskSettings, setTaskSettings} from "core/state/actions/settings";
import classnames from 'classnames';

import BottomRow from "./BottomRow";
import CommonFields from '../CommonFields';

class LinearCost extends Component {
    state = {taskInfo: {props: {}}};

    componentWillUnmount() {
        this.props.setTaskSettings('SUCCESS', {props: {}})
    }

    componentDidMount() {
        const {taskInfo, info, changeTaskSettings} = this.props;
        if (info) {
            const linear_id = taskInfo.props.linear_id;
            changeTaskSettings('SUCCESS', {
                props: {
                    linear_id
                }
            });
        }
        if (!info) {
            this.props.getFacilitiesFromProjectByTaskType('REQUEST', 'linear_cost')
        }
        this.setState({
            taskInfo
        })
    }

    render() {
        const {taskInfo} = this.state;
        const {info, errors = []} = this.props;
        return (
            <div className={classnames('card-container', {'task-info': info})}>
                <CommonFields info={info} errors={errors} taskInfo={taskInfo}/>
                {!info && <BottomRow/>}
            </div>
        )
    }
}

const mapDispatchToProps = {
    setTaskSettings,
    changeTaskSettings,
    getFacilitiesFromProjectByTaskType
};

export default connect(null, mapDispatchToProps)(LinearCost)
