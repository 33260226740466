import React, { useState, useEffect } from 'react';
import _get from 'lodash.get';

import ListWrapper from '../../MainContainer/common/ListWrapper';
import WithCommonObjectsFunc from 'components/HOC/WithCommonObjectsFunc';

const ObjectsListContainer = props => {
  const [objects, setObjects] = useState({});
  useEffect(() => {
    const handleProps = ({ sortedFacilities }) => {
      if (sortedFacilities) {
        setObjects(sortedFacilities);
      }
    };
    handleProps(props);
  }, [props]);

  const showFacilities = ({ id }) => {
    props.hideFacilities('SUCCESS', id);
  };
  const lockRow = ({ id }) => {
    console.log(id, 'lock!');
  };
  const showList = id => {
    props.hideFacilitiesGroup('SUCCESS', id);
  };
  const removeItem = ({ name, id, class_id }) => {
    const { setRemoveProps, openRemoveAlert, classesByWellfield } = props;
    const { name: objectType } = _get(classesByWellfield, class_id);
    setRemoveProps('SUCCESS', {
      id,
      name: name || 'Без названия',
      objectType,
      title: 'объекта',
      typeTitle: 'объекта',
      removeType: 'object',
      questionTitle: 'объект',
    });
    openRemoveAlert('SUCCESS', true);
  };
  const removeMetaclass = id => {
    const { setRemoveProps, openRemoveAlert, classesByWellfield } = props;
    const { name: objectType } = _get(classesByWellfield, id);
    setRemoveProps('SUCCESS', {
      id,
      objectType,
      removeType: 'class',
      typeTitle: 'объекта',
      title: 'группы объектов',
      questionTitle: 'группу объектов',
    });
    openRemoveAlert('SUCCESS', true);
  };
  const chooseRow = item => {
    props.setCardInfo('SUCCESS', {
      type: 'facility',
      data: item,
    });
    props.activeTab('SUCCESS', 'objectInfoCard');
  };
  if (!props.sortedFacilities)
    return <h2 className='preload-text'>Идёт загрузка...</h2>;
  if (!Object.keys(objects).length) {
    return <h2 className='preload-text'>Список объектов пуст</h2>;
  }
  return (
    <ListWrapper
      objects={objects}
      type={props.type}
      lockRow={lockRow}
      showList={showList}
      chooseRow={chooseRow}
      removeItem={removeItem}
      updated={props.updated}
      showRow={showFacilities}
      removeMetaclass={removeMetaclass}
    />
  );
};

export default WithCommonObjectsFunc(ObjectsListContainer);
