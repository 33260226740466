import React from 'react';
import { PropTypes } from 'prop-types'
import './style.scss'
import classNames from 'classnames'
/**
 * Buttons
 */
/**
 * @param  {string} secondary
 * @param  {function} onClick
 * @param  {element} children
 * @param  {string} className
 * @param  {boolean} disabled
 */

const Button = ({
  secondary,
  onClick,
  children,
  className,
  disabled,
  ...props }) => {
  const buttonType = secondary ? 'secondary' : 'primary';
  return <button
    {...props}
    className={classNames(`${buttonType}-button`, className, { disabled })}
    onClick={onClick}
  >
    {children}
  </button>;
};

Button.propTypes = {
  /** Button theme: primary or secondary */
  secondary: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  /** Optional className to add to the button */
  className: PropTypes.string,
  /** Whether the button should be disabled or not*/
  disabled: PropTypes.bool
};

Button.defaultProps = {
  secondary: false
};



export default Button;