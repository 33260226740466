import React from "react";

export default ({createProject, openProject, saveProject, downloadProjectHandler, removeProjectHandler, openFirstPage, showModal}) => {
    return <div className="menu__container">
        <div className="menu__triangle"/>
        <ul>
            <li className="menu__row">
                <span className="menu__title" onClick={createProject}>Создать...</span>
                <span className="menu__title" onClick={openProject}>Открыть...</span>
            </li>
            <li className="menu__row">
                <span className="menu__title" onClick={saveProject}>Сохранить</span>
                <span className="menu__title">Дублировать...</span>
                <span className="menu__title" onClick={downloadProjectHandler}>Скачать</span>
                <span className="menu__title" onClick={removeProjectHandler}>Удалить</span>
                <span className="menu__title" onClick={openFirstPage}>Закрыть проект</span>
            </li>
            <li className="menu__row">
                <span onClick={showModal} className="menu__title">О проекте</span>
            </li>
        </ul>
    </div>;
}