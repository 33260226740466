import React, { Component } from 'react';

class SwitchComponent extends Component {
  state = { value: false };
  componentDidMount() {
    this.handleProps(this.props);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.handleProps(this.props);
    }
  }
  handleProps = props => {
    const { value } = props;
    this.setState({
      value,
    });
  };
  render() {
    const { containerClass } = this.props;
    const { value } = this.state;
    return (
      <div className={`switch-container ${containerClass}`}>
        <input
          id='switch'
          name='switch'
          type='checkbox'
          checked={value}
          onChange={this.props.onChange}
        />
        <label htmlFor='switch'></label>
      </div>
    );
  }
}

export default SwitchComponent;
