import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Common/Button';

const PreviewCol = ({ createNew, openProjectHandler, wellfield }) => {
    const version = process.env.API_VERSION || '2.3.3';
    return (
        <div className="projects__col projects__col_left pull-left">
            <div className="projects__title-row">
                <div className="projects__icon" />
                <h2 className="project-version">
                    v{version}
                </h2>
            </div>
            <div className="projects__bottom-row">
                {
                    wellfield ?
                        <Button onClick={createNew} className="projects__button">Создать месторождение</Button> : <React.Fragment>
                            <Button onClick={createNew} className="projects__button">Создать проект</Button>
                            <span className="projects__text">или</span>
                            <Button onClick={openProjectHandler} className="projects__button">Открыть проект</Button>
                        </React.Fragment>
                }
            </div>
        </div>
    )
};

PreviewCol.propTypes = {
  createNew: PropTypes.func.isRequired
}
export default PreviewCol;