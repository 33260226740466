import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getVisibleAreas } from 'core/state/selectors/objects';
import { projectPalette } from 'core/state/selectors/projects';

import AreasContainer from './AreasContainer';
import coloringObj from 'utils/coloringObj';
import PatternsObj from '../../utils/Patterns';

const AreasRedux = ({ areas, projectPalette }) => {
  const [stateAreas, setStateAreas] = useState([]);
  const patterns = PatternsObj();
  useEffect(() => {
    if (areas.length && Object.keys(projectPalette).length) {
      setStateAreas(
        areas.map(({ geom, id, name, class_id }) => {
          const key = projectPalette[class_id];
          const style = coloringObj(key);
          return {
            ...geom,
            properties: { id, key, name, class_id, style },
          };
        })
      );
    } else {
      setStateAreas([]);
    }
  }, [areas, projectPalette]);
  if (!stateAreas.length) return null;
  return <AreasContainer patterns={patterns} areas={stateAreas} />;
};

const mapStateToProps = state => ({
  areas: getVisibleAreas(state),
  projectPalette: projectPalette(state),
});

export default connect(mapStateToProps)(AreasRedux);
