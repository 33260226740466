import {taskConstants} from 'utils/constants';

const {ROADS, HVL, QUARRIES, LINEAR_COST, FACILITY, GATHERING_CENTERS} = taskConstants;

export default [
    {
        title: 'Расчет центров сбора',
        type: GATHERING_CENTERS,
        icon: 'gathering_centers'
    },
    {
        title: 'Расчет стоимости ЛО',
        type: LINEAR_COST,
        icon: 'linear_cost'
    },
    {
        title: 'Расчет оптимального расположения ПО',
        type: FACILITY,
        icon: 'facility'
    },
    {
        title: 'Расчет сети автодорог',
        type: ROADS,
        icon: 'roads'
    },
    {
        title: 'Расчет оптимального освоения карьеров',
        type: QUARRIES,
        icon: 'quarries'
    },
    {
        title: 'Расчет сети трубопроводов',
        type: 'linearPipes',
        icon: 'pipes'
    },
    {
        title: 'Расчет сети ВЛ',
        type: HVL,
        icon: 'hvl'
    }
];
