import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import dayjs from 'dayjs';

import WithProjectsProps from 'components/HOC/WithProjectsProps';
import ProjectsFirstPageComponent from './ProjectsFirstPageComponent';

const ProjectsFirstPageContainer = props => {
    const [projects, setProjects] = useState(null);
    useEffect(() => {
        const updateProjects = projects => {
            if (projects) setProjects(projects);
        };
        updateProjects(props.projects)
    }, [props.projects]);
    useEffect(()=>{
        props.clearState('SUCCESS');
    },[]);
    const setDate = ({last_visited}) => dayjs(last_visited).format('DD.MM.YYYY H:mm');

    const download = ({id, name}) => props.downloadProject('REQUEST', {id, name});

    const setWellField = wellfieldId => _get(props.wellfields, [wellfieldId, 'name']);

    const openProjectHandler = () => props.changeLocation(`/projects/all`);

    const createNewProject = () => props.changeLocation(`/app/new`);

    const removeHandler = item => {
        props.setRemoveProps('SUCCESS', {
            id: item.id,
            wellfield: item.wellfieldName,
            removeType: 'project',
            name: item.name || 'Без названия',
            title: 'проекта',
            questionTitle: 'проект'
        });
        props.openRemoveAlert('SUCCESS', true)
    };
    return <ProjectsFirstPageComponent
        cond={props.cond}
        setDate={setDate}
        download={download}
        remove={removeHandler}
        projects={projects}
        setWellField={setWellField}
        handleClick={props.handleClick}
        createNewProject={createNewProject}
        openProjectHandler={openProjectHandler}
    />
};

ProjectsFirstPageContainer.propTypes = {
    projects: PropTypes.object,
    wellfields: PropTypes.object,
    changeLocation: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired
};

export default WithProjectsProps(ProjectsFirstPageContainer);