import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {gatheringCentersFacilities} from "core/state/selectors/settings";

export default () => {
    const facilities = useSelector(gatheringCentersFacilities);
    useEffect(() => {
        const {gathering_centers_ids, facilities_ids} = facilities;
        document.querySelectorAll('.facility-with-tmp-color').forEach(el => {
            const arr = el.getAttribute('class').match(/tmpColor\d/ig);
            let currentTmpClass = '';
            if (arr) {
                currentTmpClass = arr[0];
            }
            el.classList.remove('facility-with-tmp-color', currentTmpClass)
        });
        if (gathering_centers_ids && facilities_ids) {
            gathering_centers_ids.forEach((id, ind) => {
                const elem = document.getElementsByClassName(`facility ${id}`)[0];
                if (elem) {
                    elem.classList.add('facility-with-tmp-color', `tmpColor${ind}`)
                }
            });
            facilities_ids.forEach((ids, ind) => {
                ids.forEach(id => {
                    const elem = document.getElementsByClassName(`facility ${id}`)[0];
                    if (elem) {
                        elem.classList.add('facility-with-tmp-color', `tmpColor${ind}`)
                    }
                })
            });
        }
    }, [facilities])
};
