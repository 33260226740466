import {connect} from 'react-redux';
import LinearHandlersContainer from './LinearHandlersContainer';
import {createLinearObject, draggable, draw, magnet, repaint} from 'core/state/selectors/settings';
import {setTransformSettings} from 'core/state/actions/settings';

const mapDispatchToProps = {
    setTransformSettings
};

const mapStateToProps = state => ({
    draw: draw(state),
    magnet: magnet(state),
    repaint: repaint(state),
    draggable: draggable(state),
    createLinearObject: createLinearObject(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(LinearHandlersContainer);