import { action } from 'utils';

import {
  SHOW_MODAL,
  TASK_TYPE,
  ACTIVE_TAB,
  CLICK_TYPE,
  SHOW_LOADER,
  CREATE_TASK,
  TASK_STARTED,
  SET_CARD_INFO,
  CREATE_OBJECT,
  SHOW_HELP_MODAL,
  ADD_TASK_POINTS,
  SET_REMOVE_PROPS,
  SET_DEFAULT_PROPS,
  OPEN_REMOVE_ALERT,
  SET_TASK_SETTINGS,
  REMOVE_CLICK_TYPE,
  SHOW_TASK_RESULTS,
  SET_TASK_FACILITIES,
  SET_DATA_FOR_SENDING,
  CHANGE_TASK_SETTINGS,
  SET_ACTIVE_FACILITIES,
  SET_TRANSFORM_SETTINGS,
  SHOW_CANCEL_CONFIRMATION_MODAL
} from '../constants/settings';


export const setDataForSending = action(SET_DATA_FOR_SENDING);
export const setCardInfo = action(SET_CARD_INFO);
export const showModal = action(SHOW_MODAL);
export const taskType = action(TASK_TYPE);
export const clickType = action(CLICK_TYPE);
export const activeTab = action(ACTIVE_TAB);
export const createTask = action(CREATE_TASK);
export const showLoader = action(SHOW_LOADER);
export const taskStarted = action(TASK_STARTED);
export const createObject = action(CREATE_OBJECT);
export const showHelpModal = action(SHOW_HELP_MODAL);
export const addTaskPoints = action(ADD_TASK_POINTS);
export const setRemoveProps = action(SET_REMOVE_PROPS);
export const setDefaultProps = action(SET_DEFAULT_PROPS);
export const openRemoveAlert = action(OPEN_REMOVE_ALERT);
export const setTaskSettings = action(SET_TASK_SETTINGS);
export const showTaskResults = action(SHOW_TASK_RESULTS);
export const removeClickType = action(REMOVE_CLICK_TYPE);
export const setTaskFacilities = action(SET_TASK_FACILITIES);
export const changeTaskSettings = action(CHANGE_TASK_SETTINGS);
export const setActiveFacilities = action(SET_ACTIVE_FACILITIES);
export const setTransformSettings = action(SET_TRANSFORM_SETTINGS);
export const showCancelConfirmationModal = action(SHOW_CANCEL_CONFIRMATION_MODAL);
