import React, { Component } from 'react'

export default class LinearResultsComponent extends Component {
  render() {
    const { resProps } = this.props
    if (!resProps) return null
    const keys = Object.keys(resProps.linears[0].length.details)
    return (
      <table className='result-table'>
        <thead>
          <tr className='result-table-top result-table-top__linear-cost'>
            <th rowspan="2">Название</th>
            <th rowspan="2">Общая протяженность, км</th>
            <th rowspan="2">Общая стоимость, тыс. руб.</th>
            <th className="text-center" colSpan={
              Object.keys(resProps.linears[0].length.details).length
            }>Протяженность по участкам, км</th>
            <th className="text-center" colSpan={
              Object.keys(resProps.linears[0].cost.details).length
            }>Стоимость по участкам, тыс. руб.</th>
          </tr>
          <tr className='result-table-top result-table-top__linear-cost'>
            {keys.map((key, id) => {
              return <th key={id}>
                {key}
              </th>
            })}
            {keys.map((key, id) => {
              return <th key={id}>
                {key}
              </th>
            })}
          </tr>
        </thead>
        <tbody className='result-table__body'>
          {resProps.linears.map((item, id) => {
            return <tr key={id}>
              <td>{item.name}</td>
              <td>{(item.length.total / 1000).toFixed(2) * 1}</td>
              <td>{(item.cost.total / 1000).toFixed(2) * 1}</td>
              {keys.map((key, indx) => {
                return <td key={indx}>
                  {(item.length.details[key] / 1000).toFixed(2) * 1}
                </td>
              })}
              {keys.map((key, indx) => {
                return <td key={indx}>
                  {(item.cost.details[key] / 1000).toFixed(2) * 1}
                </td>
              })}
            </tr>
          })}
        </tbody>
      </table>
    )
  }
}
